import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./TimeSheet.css";
import { format } from "date-fns";
import axios from "axios";
import { useSelector } from "react-redux";
import staticValues from "../../../../config/staticValues";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TimeSheetEntry = () => {
  const managerId = useSelector(
    (state) => state.login.userData.user.managerData.userId
  );
  // console.log(managerId);
  const { userId, name, fullName, employeeId } = useSelector(
    (state) => state.login.userData
  );
  // console.log(fullName, employeeId);
  const [startDate, setStartDate] = useState(new Date());
  const [weekDays, setWeekDays] = useState([]);
  const [presentWeekDays, setPresentWeekDays] = useState([]);

  const [selectedSpace, setSelectedSpace] = useState(null);
  const [projectName, setProjectName] = useState([]);

  const [projectDuration, setProjectDuration] = useState();
  const [existeduuid, setExisteduuid] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [enteredHours, setEnteredHours] = useState();

  const [action, setAction] = useState();

  const [datas, setDatas] = useState([]);
  const [revoke, setRevoke] = useState([]);

  const [isSaving, setIsSaving] = useState(false);
  const [projects, setProjects] = useState();
  const calculateTotalHours = async (datas) => {
    setEnteredHours(
      datas.reduce((total, entry) => total + parseInt(entry.duration), 0)
    );
  };

  // Function to generate an array of week days starting from Sunday
  const generateWeekDays = (start) => {
    const days = [];
    const currentDay = start.getDay();
    const startDate = new Date(start);
    const difference = currentDay > 0 ? 0 - currentDay : 0;

    for (let i = difference; i < 7 + difference; i++) {
      const day = new Date(startDate);
      day.setDate(startDate.getDate() + i);
      days.push(day);
    }
    // console.log(days);
    setWeekDays(days);
    return days;
  };
  // Function to generate an array of week days starting from Sunday
  const generatePresentWeekDays = async (start) => {
    const days = [];
    const currentDay = start.getDay();
    const startDate = new Date(start);
    const difference = currentDay > 0 ? 0 - currentDay : 0;

    for (let i = difference; i < 7 + difference; i++) {
      const day = new Date(startDate);
      day.setDate(startDate.getDate() + i);
      days.push(day);
    }
    // console.log(days);

    setPresentWeekDays(days);
    return days;
  };
  const projectDetails = async () => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/employeeprojectdetails/fetch/v1`,
          { userId }
        )
        .then((res) => {
          // console.log(res.data.data[0].projects);
          setProjects(res.data.data[0].projects);
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  const fetchTimesheet = async () => {
    const newWeekDays = generateWeekDays(startDate);
    // console.log(newWeekDays);
    const filterdates = newWeekDays.filter((date) => {
      return date.getDay() >= 1 && date.getDay() <= 5; // Monday to Friday
    });
    // console.log(filterdates);
    const formattedDate = filterdates.map((dateObject) => {
      return dateObject.toISOString().split("T")[0];
    });
    // console.log(formattedDate);
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/timesheetdata/v1`, {
          formattedDate,
          userId,
        })
        .then((res) => {
          // console.log(res.data.revokeData);
          setDatas(res.data.data);
          // console.log(res.data.data);
          setRevoke(res.data.revokeData);
          calculateTotalHours(res.data.data);
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  // Date selection function
  const handleDateChange = (date) => {
    // console.log(date);
    {
      date ? setStartDate(date) : new Date();
    }

    // console.log("handlechange exeuting");
  };

  useEffect(() => {
    generatePresentWeekDays(new Date());
    generateWeekDays(startDate);
    fetchTimesheet();
    projectDetails();
    // console.log("am executing");
  }, [startDate]); //,

  // Open modal when an empty space is clicked
  const handleSpaceClick = (space) => {
    setSelectedSpace(space);
    setIsModalOpen(true);
  };

  // Modal close handler
  const closeModal = () => {
    setIsModalOpen(false);
    setProjectName(null);
    setProjectDuration(null);
    setExisteduuid(null);
  };

  // Form validation schema
  const validationSchema = Yup.object().shape({
    id: Yup.string().required(),
    date: Yup.date().required("Date is required"),
    project: Yup.string().required("Project is required"),
    // duration: Yup.number().required("Duration is required"),
    duration: Yup.number()
      .typeError("Duration must be a number")
      .required("Duration is required")
      .max(24, "Duration must be less than 25")
      .positive("Duration must be a positive number"),
  });

  // // Modal submission handler
  const handleModalSubmit = async (values, { resetForm }) => {
    const modifydate = format(values.date, "yyyy-MM-dd");

    if (action === staticValues.statusSaved) {
      // console.log(modifydate);
      // setDatas((prevData) => [
      //   ...prevData,
      //   {
      //     date: modifydate,
      //     duration: values.duration,
      //     project: values.project,
      //     id: values.id,
      //   },
      // ]);
      const data1 = datas.filter((item) => item.id !== values.id);
      const data = [
        ...data1,
        {
          date: modifydate,
          duration: values.duration,
          project: values.project,
          id: values.id,
          status: values.status,
        },
      ];
      setDatas(data);
      // setSendingDatas(data);
      // console.log(data);
      calculateTotalHours(data);
      setIsModalOpen(false);
      resetForm();
    }
    if (action === staticValues.statusDelete) {
      // setDatas((prevData) => [
      //   ...prevData.filter((item) => item.id !== values.id),
      // ]);
      const data = datas.filter((item) => item.id !== values.id);
      // console.log(data);
      setDatas(data);
      // setSendingDatas(data);
      calculateTotalHours(data);
      setIsModalOpen(false);
      resetForm();
    }
  };

  const generateRandomId = async () => Math.random().toString(36).substr(2, 9);
  //=================Delete existing ProjectTimeSheet Details===================
  //================for dates comparison ======================================
  const getDateArray = async (dates) =>
    dates.map((date) => [date.getFullYear(), date.getMonth(), date.getDate()]);
  //================for dates comparison ======================================
  const clickableFunction = async (day, data) => {
    const revokeObjects = revoke.map((dateString) => new Date(dateString));
    // console.log(revoke, weekDays, revokeObjects);
    if (
      day.getDay() !== 0 &&
      day.getDay() !== 6 &&
      (JSON.stringify(await getDateArray(presentWeekDays)) ===
        JSON.stringify(await getDateArray(weekDays)) ||
        JSON.stringify(await getDateArray(revokeObjects)) ===
          JSON.stringify(await getDateArray(weekDays)))
    ) {
      if (data && data.project && data.duration && data.id) {
        setProjectDuration(data.duration);
        setProjectName(data.project);
        setExisteduuid(data.id);
        // console.log(data.id);
        handleSpaceClick(day);
        // console.log("existing data execution");
      } else {
        setExisteduuid(await generateRandomId());
        // console.log("new data execution");
      }
      handleSpaceClick(day);
    }
  };

  //=================Delete existing ProjectTimeSheet Details===================

  const saveFunc = async () => {
    setIsSaving(true);
    // console.log(datas);
    // const formattedDate = presentWeekDays.map(
    //   (item) => item.date
    // return (
    //   new Date(data.date).getDay() >= 1 && new Date(data.date).getDay() <= 5
    // ); // Monday to Friday
    // );
    // console.log(filterdates);
    // const formattedDate = filterdates.map((dateObject) => {
    //   return dateObject.toISOString().split("T")[0];
    // });
    // console.log(formattedDate);
    const filterdates = weekDays.filter((date) => {
      return date.getDay() >= 1 && date.getDay() <= 5; // Monday to Friday
    });
    // console.log(filterdates);
    const formattedDate = filterdates.map((dateObject) => {
      return dateObject.toISOString().split("T")[0];
    });
    try {
      // console.log(formattedDate, datas, userId);
      axios
        .post(`${process.env.REACT_APP_API_URL}/timesheetdataUpload/v1`, {
          formattedDate,
          userId,
          datas,
        })
        .then((res) => {
          console.log("Successfully Saved");
          // setDatas(res.data)
          setIsSaving(false);
          // alert("Successfully Saved");
          toast.success("Successfully Saved");
        });
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      console.log(error.message);
    }
  };
  const submitFunc = async () => {
    setIsSaving(true);
    const filterdates = weekDays.filter((date) => {
      return date.getDay() >= 1 && date.getDay() <= 5; // Monday to Friday
    });
    // console.log(filterdates);
    const formattedDate = filterdates.map((dateObject) => {
      return dateObject.toISOString().split("T")[0];
    });
    try {
      // axios
      //   .post(
      //     `${process.env.REACT_APP_API_URL}/timesheetdatasubmit/tomanager/v1`,
      //     {
      //       formattedDate,
      //       userId,
      //       datas,
      //       managerId,
      //       name,
      //     }
      //   )
      // console.log(
      //   formattedDate,
      //   userId,
      //   datas,
      //   managerId,
      //   fullName,
      //   employeeId
      // );
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/timesheetdatasubmit/tomanager/v1`,
          {
            formattedDate,
            userId,
            datas,
            managerId,
            fullName,
            employeeId,
          }
        )
        .then((res) => {
          console.log("Successfully Submitted");
          // setDatas(res.data)
          setIsSaving(false);
          toast.success(res.data.data);
          fetchTimesheet();
          // window.location.reload();
          // alert(res.data.data);
        });
    } catch (error) {
      setIsSaving(false);
      toast.error(error.message);
      console.log(error.message);
    }
  };

  const revokeFunc = async () => {
    setIsSaving(true);

    try {
      // axios
      //   .post(`${process.env.REACT_APP_API_URL}/revoke/v1`, {
      //     userId,
      //     name,
      //     weekDays,
      //     managerId,
      //   })
      axios
        .post(`${process.env.REACT_APP_API_URL}/revoke/v1`, {
          userId,
          fullName,
          weekDays,
          managerId,
          employeeId,
        })
        .then((res) => {
          // console.log(res);
          toast.success(res.data);
          setIsSaving(false);
        });
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
      setIsSaving(false);
    }
  };

  return (
    <div>
      {datas && revoke && projects && (
        <>
          <div className="text-center">
            <h4>TimeSheet</h4>

            <i className="m-2">
              from: {new Date(weekDays[0]).toLocaleDateString()}
            </i>
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              className="form-control custom-date-picker"
            />
            <i className="m-2">
              to: {new Date(weekDays[6]).toLocaleDateString()}
            </i>
          </div>
          <b className="">
            Total-Hours :<b className="text-success">{enteredHours} </b>
          </b>
          {
            datas.length > 0 && (
              <div className="text-end">
                <b className="text-bg-warning p-2 rounded ">Note:</b>
                <i className="text-bg-warning p-2 rounded ">
                  TimeSheet Entries {datas[0].status}
                </i>
              </div>
            )
            // )
          }
          <br />
          <div className="container ">
            <table className="table">
              <thead>
                <tr>
                  {weekDays.map((day, index) => (
                    <th
                      key={index}
                      className="text-center border-end  border-dark p-1"
                    >
                      <p>
                        {day.toLocaleString("default", { weekday: "short" })}
                      </p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {weekDays.map((day, index) => (
                    <td
                      key={index}
                      className="text-center border-end border-dark p-1"
                    >
                      <p>
                        {day.getDate()}-
                        {day.toLocaleString("default", { month: "short" })}
                      </p>
                    </td>
                  ))}
                </tr>

                <tr>
                  {weekDays.map((day, index) => {
                    // if (day) {
                    // const isWeekend = index === 0 || index === 6;
                    return (
                      <td
                        key={index}
                        className={`clickable-space border-end border-start border-dark ${
                          day.getDay() === 0 || day.getDay() === 6
                            ? "fadegrey"
                            : ""
                        }`}
                        // className="clickable-space border-end border-start border-dark"
                        // className="clickable-space  border-end border-start border-dark "
                        onClick={() => clickableFunction(day)}
                        style={{ whiteSpace: "normal" }}
                      >
                        {datas
                          .filter(
                            (data) =>
                              // new Date(data.date).toLocaleDateString() ===
                              data.date ===
                                // day.toLocaleDateString()
                                day.toISOString().split("T")[0] &&
                              // new Date(data.date)===format(day, 'yyyy-MM-dd')
                              (data.status === staticValues.statusSaved ||
                                data.status === staticValues.statusApproved ||
                                data.status === staticValues.statusSubmit ||
                                data.status === staticValues.statusRejected)
                          )
                          .map((data, dataIndex) => (
                            <div
                              key={dataIndex}
                              className="clickable-space  border-start border-success border-2 mb-3 text-center p-1"
                              onClick={() => clickableFunction(day, data)}
                            >
                              {/* <i>{data.id}</i> */}
                              {/* <br /> */}
                              <h6>{data.project}</h6>
                              <h6>{data.duration}</h6>
                            </div>
                          ))}
                        <div className="p-4 bg-light"></div>
                      </td>
                    );
                    // }
                  })}
                </tr>
              </tbody>
            </table>
            <div className="d-flex justify-content-center">
              <input
                type="button"
                disabled={isSaving}
                onClick={() => {
                  // console.log(datas);
                  revokeFunc();
                }}
                className="btn btn-outline-warning px-5 mx-1  d-block"
                // className="btn btn-outline-success mx-1"
                value="Get Access"
              />

              {datas.length === 0 ||
                (datas.length > 0 &&
                  datas[0].status === staticValues.statusSaved && (
                    <>
                      <input
                        type="button"
                        disabled={isSaving}
                        onClick={() => {
                          // console.log(datas);
                          saveFunc();
                        }}
                        className="btn btn-outline-info px-5 mx-1  d-block"
                        value="Save"
                      />
                      <input
                        type="button"
                        disabled={isSaving}
                        onClick={() => {
                          submitFunc();
                          // console.log(datas);
                        }}
                        className="btn btn-outline-success px-5 mx-1 d-block"
                        value="Submit TimeSheet"
                      />
                    </>
                  ))}
            </div>
          </div>

          {/* Bootstrap Modal for entering date, project, and duration */}
          <Modal show={isModalOpen} onHide={closeModal}>
            <Formik
              initialValues={{
                id: existeduuid,
                date: selectedSpace,
                project: projectName ? projectName : "",
                duration: projectDuration ? projectDuration : "",
                status: staticValues.statusSaved,
              }}
              validationSchema={validationSchema}
              onSubmit={handleModalSubmit}
            >
              {({ handleSubmit, resetForm }) => (
                <Form onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title>TimeSheet Details:</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group controlId="date">
                      <Form.Label>Date:</Form.Label>
                      <span className="text-danger">*</span>
                      <DatePicker
                        name="date"
                        selected={selectedSpace}
                        onChange={(date) => setSelectedSpace(date)}
                        className="form-control"
                        disabled
                      />
                      <ErrorMessage
                        component="div"
                        name="date"
                        className="text-danger"
                      />
                      <Form.Group controlId="id">
                        {/* <Form.Label>Id:</Form.Label>
                    <span className="text-danger">*</span> */}
                        <Field
                          type="text"
                          name="id"
                          className="form-control"
                          disabled
                          hidden
                        />

                        {/* <ErrorMessage
                      name="id"
                      component="div"
                      className="text-danger"></ErrorMessage> */}
                      </Form.Group>
                    </Form.Group>
                    <Form.Group controlId="project">
                      <Form.Label>Project:</Form.Label>
                      <span className="text-danger">*</span>
                      <Field as="select" name="project" className="form-select">
                        {/* Add your options here */}
                        {/* <option value="">Select a project</option> */}
                        <option value="">Select a project</option>
                        {projects.map((item, index) => (
                          <option key={index} value={item.projectName}>
                            {item.projectName}-{item.projectId}
                          </option>
                        ))}

                        {/* <option value="project1">Project 1</option>
                    <option value="project2">Project 2</option> */}
                        {/* Add more options as needed */}
                      </Field>

                      <ErrorMessage
                        name="project"
                        component="div"
                        className="text-danger"
                      ></ErrorMessage>
                    </Form.Group>
                    <Form.Group controlId="duration">
                      <Form.Label>Duration:</Form.Label>
                      <span className="text-danger">*</span>
                      <Field
                        type="tel"
                        name="duration"
                        className="form-control"
                      />

                      <ErrorMessage
                        name="duration"
                        component="div"
                        className="text-danger"
                      ></ErrorMessage>
                    </Form.Group>
                    <Form.Group controlId="status">
                      <Field
                        type="text"
                        name="status"
                        className="form-control"
                        disabled
                        hidden
                      />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => setAction(staticValues.statusSaved)}
                    >
                      Save
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => setAction(staticValues.statusDelete)}
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        </>
      )}
    </div>
  );
};

export default TimeSheetEntry;
