import React from "react";
import axios from "axios";
import * as yup from "yup";
import { Formik, Field, Form } from "formik";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUploadAlt,
  faEye,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
const EduDocs = () => {
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const userId = useSelector((state) => state.login.userData.userId);
  const [urls, setUrls] = useState();
  const [oldUrls, setOldUrls] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [api, setApi] = useState();
  const [render, setRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [buttonView1, setButtonView1] = useState(true);
  const [buttonView2, setButtonView2] = useState(true);
  // const [refresh, setRefresh] = useState();
  useEffect(() => {
    // console.log(userId);
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/educationaldocuments/v1`, {
          userId,
        })
        .then((res) => {
          // console.log(res.data.data);
          setUrls(res.data.data);
          setButtonView2(Array(res.data.data.length).fill(true));
        });
    } catch (error) {
      console.log(error.message);
    }
  }, [render, userId]);
  const initialValues = { file: null };
  const validationSchema = yup.object({});

  const onSubmit = async (actiontype) => {
    try {
      setIsLoading(true);
      // console.log(actiontype);

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("userId", userId);
      formData.append("originalName", selectedFile.name);
      formData.append("type", "educationdocuments");
      formData.append(
        "oldUrl",
        oldUrls.split("?")[0].split("devstorage/")[1].replace(/\%20/g, " ")
      );

      axios
        .post(api, formData, {
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadPercentage(percentage);
          },
        })
        .then((res) => {
          setRender(!render);
          setIsLoading(false);
          setErrorMessage(null);
          setButtonView1(true);
          setButtonView2(true);
        })
        .catch((error) => {
          console.error(
            "Error uploading file:",
            error.response?.data || error.message
          );
          setIsLoading(false);
          setErrorMessage(
            "Error occurred while uploading the file. Please try again."
          );
        });

      fileInputRef.current.value = null;
      fileInputRef1.current.value = null;
    } catch (error) {
      console.error(
        "Error uploading file:",
        error.response?.data || error.message
      );
      setIsLoading(false);
      setErrorMessage(
        "Error occurred while uploading the file. Please try again."
      );
    }
  };

  // const onSubmit = async (actiontype) => {
  //   try {
  //     setIsLoading(true);
  //     console.log(actiontype);

  //     const formData = new FormData();
  //     formData.append("file", selectedFile);
  //     formData.append("userId", userId);
  //     formData.append("originalName", selectedFile.name);
  //     formData.append("type", "educationdocuments");
  //     formData.append(
  //       "oldUrl",
  //       oldUrls.split("?")[0].split("devstorage/")[1].replace(/\%20/g, " ")
  //     );

  //     axios
  //       .post(api, formData, {
  //         onUploadProgress: (progressEvent) => {
  //           const percentage = Math.round(
  //             (progressEvent.loaded * 100) / progressEvent.total
  //           );
  //           setUploadPercentage(percentage);
  //         },
  //       })
  //       .then((res) => setRender(!render));
  //     fileInputRef.current.value = null;
  //     fileInputRef1.current.value = null;
  //     setIsLoading(false);
  //     setErrorMessage(null);
  //   } catch (error) {
  //     // console.log(error.message);
  //     console.error(
  //       "Error uploading file:",
  //       error.response?.data || error.message
  //     );
  //     setIsLoading(false);
  //     // Set the error message for display
  //     setErrorMessage(
  //       "Error occured while uploading the file. Please try again."
  //     );
  //   }
  // };

  // function validation() {
  //   (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //       if (file.type !== "application/pdf") {
  //         alert("Please select a valid PDF file.");
  //         e.target.value = ""; // Clear the file input
  //         return;
  //       }

  //       // Check if the file size is within the allowed limit (in bytes)
  //       // const maxSize = 5 * 1024 * 1024; // 5 MB
  //       const maxSize = 200 * 1024; // 200 KB
  //       if (file.size > maxSize) {
  //         alert("File size exceeds the limit (200 KB).");
  //         e.target.value = ""; // Clear the file input
  //         return;
  //       }
  //       // setSelectedFile(file);
  //       // formik.setFieldValue("file", e.currentTarget.files[0]);
  //       setSelectedFile(e.currentTarget.files[0]);
  //       // setSelectedCategory(categoriesToDisplayFileInput[index]);
  //     }
  //   };
  // }
  const validation = (index) => (e) => {
    // console.log("validation");
    const file = e.target.files[0];
    if (file) {
      if (file.type !== "application/pdf") {
        alert("Please select a valid PDF file.");
        e.target.value = ""; // Clear the file input
        return;
      }

      const maxSize = 500 * 1024; // 200 KB
      if (file.size > maxSize) {
        alert("File size exceeds the limit (200 KB).");
        e.target.value = ""; // Clear the file input
        return;
      }
      setSelectedFile(file);
      setOldUrls(urls[index]);
      setApi(`${process.env.REACT_APP_API_URL}/update/educationaldocuments/v1`);
      // setButtonView2(true);
      let updatedVisibility = [...buttonView2];
      updatedVisibility[index] = false;
      setButtonView2(updatedVisibility);
    }
  };
  const validationS = (e) => {
    // console.log("validationS");
    const file = e.target.files[0];
    if (file) {
      if (
        file.type !== "application/pdf" &&
        file.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        alert("Please select a valid PDF file.");
        e.target.value = ""; // Clear the file input
        return;
      }

      const maxSize = 500 * 1024; // 200 KB
      if (file.size > maxSize) {
        alert("File size exceeds the limit (200 KB).");
        e.target.value = ""; // Clear the file input
        return;
      }
      setSelectedFile(file);
      setOldUrls("https://devstorage/no%20Old%20?urlddfvsfjvjs%20");
      setApi(`${process.env.REACT_APP_API_URL}/uploadDocuments/v1`);
      setButtonView1(false);
    }
  };

  return (
    <div className="">
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      {isLoading && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuenow={uploadPercentage}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: `${uploadPercentage}%` }}>
            {uploadPercentage}%
          </div>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        <Form>
          <table class="table ">
            <thead>
              <h4>Education Documents:</h4>
            </thead>
            <tr>
              <td>
                <b>Links To View</b>
              </td>
              <td>
                <b className="my-2">Attach Your File</b>
              </td>
            </tr>
            {urls &&
              urls.length > 0 &&
              urls.map((url, index) => (
                // <div>
                //   <Link to={url} target="_blank">
                //     Tap-toView
                //   </Link>
                //   <Field
                //     type="file"
                //     name="file"
                //     onChange={validation(index)}
                //     innerRef={fileInputRef}
                //   />

                //   <button
                //     type="submit"
                //     onClick={(e) => {
                //       e.preventDefault();
                //       onSubmit("update");
                //     }}>
                //     Update
                //   </button>
                // </div>
                // <Card>
                //   <Card.Body>
                //     <Card.Title>
                <tr className="">
                  <td className="">
                    {/* <span>View Document</span> */}

                    <Link to={url} target="_blank">
                      {/* {`Document${index + 1}`} */}
                      {decodeURIComponent(
                        new URL(url).pathname.split("/").pop()
                      )}
                      <FontAwesomeIcon
                        icon={faEye}
                        // className="text-"

                        // style={{ marginLeft: "5px" }}
                      />
                    </Link>
                  </td>
                  <td>
                    <Field
                      className="my-1"
                      type="file"
                      name="file"
                      onChange={validation(index)}
                      innerRef={fileInputRef}
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      variant="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        onSubmit("update");
                      }}
                      // disabled={isLoading
                      // }
                      disabled={buttonView2[index]}>
                      <FontAwesomeIcon icon={faEdit} />
                      Update
                    </button>
                  </td>
                </tr>

                // </Card.Title>
                // <Card.Text>

                // </Card.Text>

                // </Card.Body>
                // </Card>
              ))}

            {/* <div>
            <div> */}

            <tr>
              {/* <div> */}
              <td>
                {/* < faCloudUploadAlt/> */}

                <b className="">
                  Add New-File
                  {/* <FontAwesomeIcon
                    icon={faCloudUploadAlt}
                    className="text-info"
                    // style={{ marginLeft: "7px" }}
                  /> */}
                </b>
              </td>
              <td>
                <Field
                  type="file"
                  name="file"
                  onChange={validationS}
                  innerRef={fileInputRef1}
                />
              </td>
              <td>
                <button
                  type="submit"
                  className="btn btn-success btn-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit("submitvalue");
                  }}
                  // disabled={isLoading }
                  disabled={buttonView1}>
                  <FontAwesomeIcon icon={faCloudUploadAlt} />
                  Upload
                </button>
              </td>
              {/* </div> */}
            </tr>

            {/* </div>
          </div> */}
          </table>
        </Form>
      </Formik>
    </div>
  );
};

export default EduDocs;
