import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import StatusModal from "./StatusModal";
const StatusRevoke = () => {
  const userId = useSelector((state) => state.login.userData.userId);
  const [employees, setEmployees] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/getallemployees/records/v1`, {
          userId,
        })
        .then((res) => {
          setEmployees(res.data.data);
          // console.log(res.data.data);
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const dataFetch = async (item) => {
    try {
      // console.log(item);
      axios
        .post(`${process.env.REACT_APP_API_URL}/getuserDetails/bymanager/v1`, {
          item,
        })
        .then((res) => {
          // console.log(res.data.data);
          setSelectedItem({ ...res.data.data[0], ...res.data.data[1] });
          // console.log({ ...res.data.data[0], ...res.data.data[1] });
        });
      setShowModal(true);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleButtonClick = async (item) => {
    await dataFetch(item);
  };

  const closeShowModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  return (
    <div className="m-4">
      <table className="table table-striped">
        <tbody>
          <tr>
            <td><h5>Name of Employees:</h5></td>
            <td><h5>Actions:</h5></td>
          </tr>
          {employees &&
            employees.map((item, index) => (
              <>
                <tr key={index}>
                  <td>
                    <p>{item.name}</p>
                  </td>
                  <td>
                    <button
                      className="btn btn-outline-primary "
                      onClick={() => handleButtonClick(item)}
                    >
                      View
                    </button>
                  </td>
                </tr>

                {item && selectedItem && (
                  <StatusModal
                    showModal={showModal}
                    closeShowModal={closeShowModal}
                    item={selectedItem}
                  />
                )}
              </>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default StatusRevoke;
