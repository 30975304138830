import { createSlice } from "@reduxjs/toolkit";

const jobDataSlice = createSlice({
  name: "jobData",
  initialState: {
    jobPostingsData: [],
    idInfo: {},
  },
  reducers: {
    setJobData: (state, action) => {
      state.jobPostingsData = action.payload;
    },
    clearJobData: (state) => {
      state.jobPostingsData = [];
    },
    setIdInfo: (state, action) => {
      state.idInfo = action.payload;
    },
    clearJobId: (state) => {
      state.jobId = "";
    },
  },
});

export const { setJobData, clearJobData, setIdInfo, clearJobId } =
  jobDataSlice.actions;
export default jobDataSlice.reducer;
