import React from "react";
import AlternatingLayout from "./Alternate";
import AlternatingLayout1 from "./Alternate1";
const Home = () => {
  return (
    <div>
      <AlternatingLayout />
      <AlternatingLayout1 />
    </div>
  );
};

export default Home;
