import React from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";
import { useState, useEffect } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProjectCreation = () => {
  const [managerData, setManagerData] = useState();
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/managerDataGet/v1`)
        .then((res) => {
          setManagerData(res.data.data);
          //   console.log(res.data.data);
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const initialValues = {
    projectName: "",
    projectId: "",
    startDate: "",
    endDate: "",
    projectManager: "",
  };

  // =======================================================================
  // let minimumDate = new Date(initialValues.startDate)
  // minimumDate.setDate(minimumDate.getDate()+1)
  let minimumDate;
if (initialValues.startDate) {
  minimumDate = new Date(initialValues.startDate);
  minimumDate.setDate(minimumDate.getDate() + 1);
} else {
  minimumDate = new Date();
}

// =========================================================================

  const validationSchema = yup.object({
    projectName: yup
      .string()
      .required("Project Name is a required field")
      .matches(
        /^[a-z]{5,50}$/,
        "Project Name must be between 5 and 50 lowercase alphabets"
      ),
    projectId: yup
      .string()
      .required("Project Id is a required field")
      .matches(
        /^[0-9]{5,50}$/,
        "ProjectId must be between 5 and 50 digits without spaces"
      ),
    startDate: yup
      .string()
      .matches(
        /^\d{4}-\d{2}-\d{2}$/,
        "Start Date must be in the format DD-MM-YYYY"
      )
      .required("Start date is required"),
    endDate: yup
      // .date()
      // .min(minimumDate)
      .string()
      .matches(
        /^\d{4}-\d{2}-\d{2}$/,
        "EndDate must be in the format DD-MM-YYYY"
      )
      .test(
      "is-greater",
      "EndDate must be greater than StartDate",
      function (value) {
        const { startDate } = this.parent;
        if (!startDate || !value) return true; // Skip validation if either date is missing
        return new Date(value) > new Date(startDate);
      }
    ),
    projectManager: yup.string().required("ProjectManager is required"),
  });
  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/createProjects`, values)
        .then((res) => {
          toast.success(res.data.data);
          setLoading(false);
          // console.log(res.data);
        });
      resetForm();
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };
  const handleClear = (resetForm) => {
    resetForm();
  };
  return (
    <div>
      {managerData && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, resetForm }) => (
            <Form className="mx-2">
              <h3 className="text-center">Create Project:</h3>
              <div className="form-group row my-2">
                <label
                  htmlFor="projectName"
                  className="col-sm-2 col-form-label"
                >
                  <h5>
                    ProjectName:<span className="text-danger">*</span>
                  </h5>
                </label>
                <div className="col-sm-10">
                  <Field
                    type="text"
                    name="projectName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="projectName"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="form-group row my-2">
                <label htmlFor="projectId" className="col-sm-2 col-form-label">
                  <h5>
                    ProjectId:<span className="text-danger">*</span>
                  </h5>
                </label>
                <div className="col-sm-10">
                  <Field
                    type="text"
                    name="projectId"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="projectId"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="form-group row my-2">
                <label htmlFor="startDate" className="col-sm-2 col-form-label">
                  <h5>
                    StartDate:<span className="text-danger">*</span>
                  </h5>
                </label>
                <div className="col-sm-10">
                  <Field
                    type="date"
                    name="startDate"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="startDate"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="form-group row my-2">
                <label htmlFor="endDate" className="col-sm-2 col-form-label">
                  <h5>
                    EndDate:<span className="text-danger">*</span>
                  </h5>
                </label>
                <div className="col-sm-10">
                  <Field type="date" name="endDate" className="form-control" />
                  <ErrorMessage
                    name="endDate"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="form-group row my-2">
                <label
                  htmlFor="projectManager"
                  className="col-sm-2 col-form-label"
                >
                  <h5>
                    ProjectManager:<span className="text-danger">*</span>
                  </h5>
                </label>
                <div className="col-sm-10">
                  <Field
                    as="select"
                    name="projectManager"
                    className="form-select"
                  >
                    <option value="" selected>
                      Select your Manager
                    </option>
                    {managerData.map((manager, index) => (
                      <option key={index} value={manager.userId}>
                        {manager.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="projectManager"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-10 offset-sm-2">
                  <input
                    type="reset"
                    value="Clear"
                    className="btn btn-danger mx-1"
                    disabled={loading}
                  />
                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-success mx-1"
                    disabled={loading}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ProjectCreation;
// import React from "react";
// import axios from "axios";

// function ProjectCreation() {
//   const testing = async () => {
//     const response = await axios.get(
//       `${process.env.REACT_APP_API_URL}/updatingWorkedHours`
//     );
//     console.log(response);
//   };
//   return (
//     <div>
//       ProjectCreation <button onClick={testing}>click me</button>
//     </div>
//   );
// }

// export default ProjectCreation;
