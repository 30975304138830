const FooterConfig = {
  //========================================================
  orgName: "WAMIKA ",
  moto: "Elevating Experiences, Redefining Possibilities Your Journey, Our Commitment",

  //============================================================

  //===============================================================================
  quickLinks: "Quick Links:",
  home: "Home",
  about: "About Us",
  contact: "Contact Us",
  achievements: "Achievements",
  //==============================================================================

  //===============================================================================
  addressHead: "Address:",
  addressLine1: "WamikaTechnologies",
  addressLine2: "Gachibowli Opposite DLf APHB colony",
  addressLine3: "ZipCode:500032 Hyderabad",

  //===============================================================================
  linkedin: "https://www.linkedin.com/in/wamika-technologies-903b18294/",
  // adding comments
  facebook: "https://www.linkedin.com/in/wamika-technologies-903b18294/",
};

export default FooterConfig;
