import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./HRDocs.css";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { Modal, Button, Form, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

const HRDocs = () => {
  const { role } = useSelector((state) => state.login.userData);
  const [docsInfo, setDocsInfo] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [newDocument, setNewDocument] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [handleUploadModal, setHandleUploadModal] = useState(false);
  const documentNameRef = useRef(null);
  const documentImageRef = useRef(null);
  const documentFileRef = useRef(null);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/policyDocsFetch/v1`)
      .then((response) => {
        setDocsInfo(response.data);
      });
  }, []);

  const handleLink = (event) => {
    event.preventDefault();
  };

  const handleUploadDocument = () => {
    const documentName = documentNameRef.current.value;
    const documentImage = documentImageRef.current.files[0];
    const documentFile = documentFileRef.current.files[0];
    // console.log(documentName, documentImage, documentFile);
    const formData = new FormData();
    formData.append("documentName", documentName);
    formData.append("documentImage", documentImage);
    formData.append("documentFile", documentFile);

    // Make POST request to the backend
    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/uploadHrDocs`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for file uploads
        },
      })
      .then((response) => {
        console.log("Upload successful", response.data);
      })
      .catch((error) => {
        console.error("Error uploading document", error);
      });
  };
  const openUploadModal = () => {
    // setSelectedRequest(request);
    // setSelectedDoc(doc);
    setHandleUploadModal(true);
  };

  const openModal = (doc) => {
    setSelectedDoc(doc);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedDoc(null);
    setNewDocument(null);
    setShowModal(false);
  };
  const closeUploadModal = () => {
    setHandleUploadModal(false);
  };

  const handleFileChange = (event) => {
    setNewDocument(event.target.files[0]);
  };

  // const handleUpload = () => {
  //   // Logic to handle upload
  // };

  const handleUpdateDocument = async () => {
    if (!newDocument) {
      alert("Please select a document to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("docId", selectedDoc._id);
    formData.append("existingDocUrl", selectedDoc.documentUrl);
    formData.append("file", newDocument);

    try {
      const response = await axios.post(
        // `${process.env.REACT_APP_API_URL}/updateDocument/v1/${selectedDoc.id}`,
        `${process.env.REACT_APP_API_URL}/v1/updateHrDocs`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Document updated successfully:", response.data);

      // Refresh document list or update state as needed
      // For example, refetch documents after updating
      axios
        .get(`${process.env.REACT_APP_API_URL}/policyDocsFetch/v1`)
        .then((response) => {
          setDocsInfo(response.data);
        })
        .catch((error) => {
          console.error("Error fetching documents:", error);
        });

      closeModal();
    } catch (error) {
      console.error("Error updating document:", error);
      alert("Error updating document. Please try again.");
    }
  };

  // const handleClick = () => {};
  return (
    <>
      {role && role.includes("Admin") && (
        <div className="upload_button">
          <button className="btn btn-primary" onClick={() => openUploadModal()}>
            Upload New Document
          </button>
        </div>
      )}
      <div className="container py-3">
        <div className="row">
          {docsInfo ? (
            <>
              {docsInfo.map((doc) => (
                <div className="col-md-4 mx-auto text-center">
                  <div className="card-body styling g-3 mb-4">
                    <img
                      src={doc.documentImageUrl}
                      alt="docImage"
                      className="resize rounded-4"
                    />
                    <p className="card-text m-2">
                      <a
                        target="_blank"
                        href={doc.documentUrl}
                        className="text-decoration-none m-2"
                        rel="noopener noreferrer"
                      >
                        {doc.documentName}
                      </a>

                      {role && role.includes("Admin") && (
                        <button
                          onClick={() => openModal(doc)}
                          style={{ border: "none" }}
                        >
                          <FontAwesomeIcon
                            icon={faCloudUploadAlt}
                            className="upload-icon text-primary"
                          />
                        </button>
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFile">
                <Form.Label>Select Document:</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleUpdateDocument}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={handleUploadModal} onHide={closeUploadModal}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form>
              <Form.Group>
                <Form.Label>
                  Document Name:
                </Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Form> */}
            <Formik>
              <Form>
                <Form.Group controlId="documentName">
                  <Form.Label>Document Name:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter document name"
                    id="documentName"
                    ref={documentNameRef}
                    // onChange={handleDocumentNameChange}
                  />
                </Form.Group>
                <Form.Group controlId="documentImage">
                  <Form.Label>Document Image:</Form.Label>
                  <Form.Control
                    type="file"
                    id="documentImage"
                    ref={documentImageRef}
                    // onChange={handleDocumentImageChange}
                  />
                </Form.Group>
                <Form.Group controlId="documentFile">
                  <Form.Label>Document File:</Form.Label>
                  <Form.Control
                    type="file"
                    id="documentFile"
                    ref={documentFileRef}
                    //  onChange={handleDocumentFileChange}
                  />
                </Form.Group>
              </Form>
            </Formik>
            <Button variant="primary" onClick={handleUploadDocument}>
              Upload
            </Button>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>{" "}
    </>
  );
};

export default HRDocs;
