import React, { useEffect, useState } from "react";
import axios from "axios";

import { useSelector } from "react-redux";
import staticValues from "../../../../config/staticValues";
import LeaveRequestModal from "./LeaveReqsDetailsModal";

const RejectedLeaves = () => {
  const [approvalData, setApprovalData] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const userId = useSelector((state) => state.login.userData.userId);
  const status = staticValues.statusRejected;
  const managerValues = { userId, status }; //for fetching requests based on managerId
  // console.log(managerValues);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/gettingLeaveRequestsBasedOnStatus`,
        managerValues
      )
      .then((response) => {
        setApprovalData(response.data);
        // console.log(response.data);
      });
  }, []);
  const openModal = (request) => {
    setSelectedRequest(request);
  };

  const closeModal = () => {
    setSelectedRequest(null);
  };
  return (
    <div className="Leaves" style={{ fontFamily: "Recharge" }}>
      <h4 className=" bg-purple text-dark text-center p-3 rounded">
        Rejected Requests - ({approvalData.length})
      </h4>
      {/* <div className="d-flex justify-content-around flex-wrap">
        {approvalData.map((request) => (
          <div
            key={request.userId}
            className="card m-4"
            style={{ width: "18rem" }}
          >
            <div className="card-body">
              <p className="d-none">
                <strong>UserId:</strong> {request.userId}
              </p>
              <p>
                <strong>Name:</strong> {request.name}
              </p>
              <p>
                <strong>EmployeeID:</strong> {request.employeeId}
              </p>
              <p>
                <strong>Total Leave Days:</strong> {request.totalLeaveDays}
              </p>
              <p>
                <strong>Leave Reason:</strong> {request.leaveReason}
              </p>
              <p>
                <strong>Start Date:</strong> {request.startDate}
              </p>
              <p>
                <strong>End Date:</strong> {request.endDate}
              </p>
              <p>
                <strong>Status:</strong> {request.status}
              </p>
              <p>
                <strong>Comments:</strong> {request.comments}
              </p>
            </div>
          </div>
        ))}
      </div> */}
      {/* <h4 className="bg-purple text-dark text-center p-3 rounded">
        Approved Requests - ({approvalData.length})
      </h4> */}
      {approvalData && approvalData.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Employee ID</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {approvalData.map((request, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{request.name}</td>
                <td>{request.employeeId}</td>
                <td>{request.startDate}</td>
                <td>{request.endDate}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => openModal(request)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <LeaveRequestModal
        selectedRequest={selectedRequest}
        handleClose={closeModal}
      />
    </div>
  );
};
export default RejectedLeaves;
