import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmpAccountDefault from "./EmpAccountDefault";
// import { Col } from "react-bootstrap";
const EmpAccountInfo = () => {
  const [finDetails, setFinDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(false);
  const userId = useSelector((state) => state.login.userData.userId);
  const dataFetch = () => {
    setData(!data);
  };
  useEffect(() => {
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/empActDetailsGet/v1`, {
          userId,
        })
        .then((response) => {
          // console.log(response.data.data);
          setFinDetails(response.data.data);
        });
    } catch (error) {
      console.log(error.message);
    }
  }, [data]);

  const updateInitialValue = (fieldName, value) => {
    setFinDetails((prevFinDetails) => ({
      ...prevFinDetails,
      [fieldName]: value,
    }));
  };

  const initialValues = {
    // userId: finDetails && finDetails.userId ? finDetails.userId : userId,
    // aadhar: finDetails && finDetails.aadhar ? finDetails.aadhar : "",
    // panCard: finDetails && finDetails.panCard ? finDetails.panCard : "",
    // pfNumber: finDetails && finDetails.pfNumber ? finDetails.pfNumber : "",
    // uan: finDetails && finDetails.uan ? finDetails.uan : "",
    // accountNumber:
    //   finDetails && finDetails.accountNumber ? finDetails.accountNumber : "",
    // ifscCode: finDetails && finDetails.ifscCode ? finDetails.ifscCode : "",
    userId: userId,
    aadhar: finDetails?.aadhar || "",
    panCard: finDetails?.panCard || "",
    pfNumber: finDetails?.pfNumber || "",
    uan: finDetails?.uan || "",
    accountNumber: finDetails?.accountNumber || "",
    ifscCode: finDetails?.ifscCode || "",
  };

  const validationSchema = Yup.object({
    aadhar: Yup.string()
      .matches(/^\d{12}$/, "must be 12 digits")
      .required("Aadhar is required"),
    panCard: Yup.string()
      .matches(/^[A-Z]{5}\d{4}[A-Z]$/, "followed this pattern AAAAA1111A")
      .required("panCard is required"),
    pfNumber: Yup.string().required(),
    uan: Yup.string().required(),
    accountNumber: Yup.string().required("Account Number is required"),
    ifscCode: Yup.string().required("IFSC Code is required"),
  });

  // const onSubmit = async (values, { resetForm }) => {
  //   setIsLoading(true);
  //   // console.log(values);
  //   try {
  //     axios
  //       .post(`${process.env.REACT_APP_API_URL}/empActDetails/v1`, values)
  //       .then((res) => {
  //         toast.success("Successfully submitted");
  //         // console.log(res);
  //       });
  //     // resetForm();
  //     setIsLoading(false);
  //     // window.location.reload();
  //   } catch (error) {
  //     toast.error(error.message);
  //     console.log(error.message);
  //     setIsLoading(false);
  //   }
  // };

  const updateDetails = async (values) => {
    setIsLoading(true);
    // console.log(values);
    try {
      await validationSchema.validate(values);
      axios
        .post(`${process.env.REACT_APP_API_URL}/empAccountUpdate/v1`, values)
        .then((res) => {
          // console.log(res.data);
          toast.success("Successfully submitted");
        });
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
      setIsLoading(false);
    }
  };

  return (
    // <Col sm={8} className="content">
    // <div className="d-flex justify-content-center align-items-center ">
    <div className="container">
      {finDetails ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          // onSubmit={onSubmit}
        >
          {() => (
            <Form className="">
              <h3 className=" text-dark">Employee Financial Credentials:</h3>
              <br />
              <div className="row mb-3">
                <label
                  htmlFor="aadhar"
                  className="col-sm-2 col-form-label fs-5 ">
                  Aadhar:<span style={{ color: "red" }}>*</span>
                </label>
                <div className="col-sm-5">
                  <Field
                    type="text"
                    name="aadhar"
                    id="aadhar"
                    className="form-control"
                    placeholder="Enter your Aadhar Num"
                    onChange={(e) =>
                      updateInitialValue("aadhar", e.target.value)
                    }
                    value={finDetails.aadhar}
                  />
                  <ErrorMessage
                    name="aadhar"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <label
                  htmlFor="panCard"
                  className="col-sm-2 col-form-label fs-5">
                  PanCard:<span style={{ color: "red" }}>*</span>
                </label>
                <div className="col-sm-5">
                  <Field
                    type="text"
                    name="panCard"
                    id="panCard"
                    className="form-control"
                    placeholder="Enter your PanCard"
                    onChange={(e) =>
                      updateInitialValue("panCard", e.target.value)
                    }
                    value={finDetails.panCard}
                  />
                  <ErrorMessage
                    name="panCard"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <label
                  htmlFor="pfNumber"
                  className="col-sm-2 col-form-label fs-5">
                  PF Number:<span className="text-danger">*</span>
                </label>
                <div className="col-sm-5">
                  <Field
                    type="text"
                    name="pfNumber"
                    id="pfNumber"
                    className="form-control"
                    placeholder="Enter Your Existed PF"
                    onChange={(e) =>
                      updateInitialValue("pfNumber", e.target.value)
                    }
                    value={finDetails.pfNumber}
                  />
                  <ErrorMessage
                    component="div"
                    className="text-danger"
                    name="pfNumber"></ErrorMessage>
                </div>
              </div>

              <div className="row mb-3">
                <label htmlFor="uan" className="col-sm-2 col-form-label fs-5">
                  UAN:<span className="text-danger">*</span>
                </label>
                <div className="col-sm-5">
                  <Field
                    type="text"
                    name="uan"
                    className="form-control"
                    placeholder="Enter Your UAN"
                    onChange={(e) => updateInitialValue("uan", e.target.value)}
                    value={finDetails.uan}
                  />
                  <ErrorMessage
                    name="uan"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <label
                  htmlFor="accountNumber"
                  className="col-sm-2 col-form-label fs-5">
                  Bank Account:<span style={{ color: "red" }}>*</span>
                </label>
                <div className="col-sm-5">
                  <Field
                    type="text"
                    name="accountNumber"
                    className="form-control"
                    placeholder="Bank Account"
                    onChange={(e) =>
                      updateInitialValue("accountNumber", e.target.value)
                    }
                    value={finDetails.accountNumber}
                  />
                  <ErrorMessage
                    name="accountNumber"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <label
                  htmlFor="ifscCode"
                  className="col-sm-2 col-form-label fs-5">
                  IFSC Code:<span style={{ color: "red" }}>*</span>
                </label>
                <div className="col-sm-5">
                  <Field
                    type="text"
                    name="ifscCode"
                    className="form-control"
                    placeholder="IFSC Code"
                    onChange={(e) =>
                      updateInitialValue("ifscCode", e.target.value)
                    }
                    value={finDetails.ifscCode}
                  />
                  <ErrorMessage
                    name="ifscCode"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row mb-3 text-end">
                <div className="col-sm-5 offset-sm-2 ">
                  {/* <button type="reset" className="btn btn-danger">
                  Clear
                </button> */}
                  {/* {!finDetails.accountNumber ? (
                    <button
                      type="submit"
                      className="btn btn-primary mx-3"
                      disabled={isLoading}>
                      Submit
                    </button>
                  ) : ( */}
                  <button
                    className="btn btn-primary mx-3"
                    onClick={(e) => {
                      e.preventDefault();
                      updateDetails(initialValues);
                    }}
                    disabled={isLoading}>
                    Update
                  </button>
                  {/* )} */}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <EmpAccountDefault fun={dataFetch} />
      )}
    </div>
  );
};

export default EmpAccountInfo;
