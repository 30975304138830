import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import staticValues from "../../../../config/staticValues";
import LeaveRequestModal from "./LeaveReqsDetailsModal";
{
  /* <LeaveRequestModal
  selectedRequest={selectedRequest}
  handleClose={closeModal}
  handleAction={handleCustomAction}  // Pass the handleCustomAction function
  handleInputChange={handleInputChange}
/> */
}

const ApprovedLeaves = () => {
  const userId = useSelector((state) => state.login.userData.userId);

  const [approvalData, setApprovalData] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const status = staticValues.statusApproved;
  const managerValues = { userId, status }; //for fetching requests based on managerId

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/gettingLeaveRequestsBasedOnStatus`,
        managerValues
      )
      .then((response) => {
        // console.log(response.data);
        setApprovalData(response.data);
      });
  }, []);

  const openModal = (request) => {
    setSelectedRequest(request);
  };

  const closeModal = () => {
    setSelectedRequest(null);
  };

  return (
    <div className="Leaves" style={{ fontFamily: "Recharge" }}>
      <h4 className="bg-purple text-dark text-center p-3 rounded">
        Approved Requests - ({approvalData.length})
      </h4>
      {approvalData && approvalData.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Employee ID</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {approvalData.map((request, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{request.name}</td>
                <td>{request.employeeId}</td>
                <td>{request.startDate}</td>
                <td>{request.endDate}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => openModal(request)}>
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Bootstrap Modal */}
      {/* <Modal show={selectedRequest !== null} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title
        
          >
            <b>Request Details</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
      
          <p>
            <b>Name</b>: {selectedRequest?.name}
          </p>
          <p>
            <b>Employee ID</b>: {selectedRequest?.employeeId}
          </p>
          <p>
            <b>Total Leave Days</b>: {selectedRequest?.totalLeaveDays}
          </p>
          <p>
            <b>Leave Reason</b>: {selectedRequest?.leaveReason}
          </p>
          <p>
            <b>Start Date</b>: {selectedRequest?.startDate}
          </p>
          <p>
            <b>End Date</b>: {selectedRequest?.endDate}
          </p>
          <p>
            <b>Status</b>: {selectedRequest?.status}
          </p>
          <p>
            <b>Comments</b>: {selectedRequest?.comments}
          </p>
        </Modal.Body>
      </Modal> */}
      <LeaveRequestModal
        selectedRequest={selectedRequest}
        handleClose={closeModal}
      />
    </div>
  );
};

export default ApprovedLeaves;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import "./style.css";

// const ApprovedLeaves = () => {
//   const userId = useSelector((state) => state.login.userData.userId);

//   const [approvalData, setApprovalData] = useState([]);
//   const [selectedRequest, setSelectedRequest] = useState(null);

//   const status = "Accepted";
//   const managerValues = { userId, status }; //for fetching requests based on managerId

//   useEffect(() => {
//     axios
//       .post(`${process.env.REACT_APP_API_URL}/pendingRequests`, managerValues)
//       .then((response) => {
//         setApprovalData(response.data);
//       });
//   }, []);

//   const openModal = (request) => {
//     setSelectedRequest(request);
//     console.log(request);
//     // Add logic to display your modal here
//   };

//   return (
//     <div className="Leaves" style={{ fontFamily: "Recharge" }}>
//       <h4 className="bg-purple text-dark text-center p-3 rounded">
//         Approved Requests - ({approvalData.length})
//       </h4>
//       <table className="table">
//         <thead>
//           <tr>
//             <th>Name</th>
//             <th>Employee ID</th>
//             <th>Start Date</th>
//             <th>End Date</th>
//             <th>Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           {approvalData.map((request) => (
//             <tr key={request.userId}>
//               <td>{request.name}</td>
//               <td>{request.employeeId}</td>
//               <td>{request.startDate}</td>
//               <td>{request.endDate}</td>
//               <td>
//                 <button
//                   className="btn btn-primary"
//                   onClick={() => openModal(request)}
//                 >
//                   View
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Add modal component here */}
//       {selectedRequest && (
//         <div className="modal">
//           {/* Modal content */}
//           <div className="modal-content">
//             {/* Display details for selected request */}
//             <p>Name: {selectedRequest.name}</p>
//             <p>Employee ID: {selectedRequest.employeeId}</p>
//             <p>Total Leave Days: {selectedRequest.totalLeaveDays}</p>
//             <p>Leave Reason: {selectedRequest.leaveReason}</p>
//             <p>Start Date: {selectedRequest.startDate}</p>
//             <p>End Date: {selectedRequest.endDate}</p>
//             <p>Status: {selectedRequest.status}</p>
//             <p>Comments: {selectedRequest.comments}</p>
//             {/* Add additional details as needed */}
//             <button onClick={() => setSelectedRequest(null)}>Close</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ApprovedLeaves;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useSelector } from "react-redux";

// const ApprovedLeaves = () => {
//   const userId = useSelector((state) => state.login.userData.userId);

//   const [approvalData, setApprovalData] = useState([]);

//   const status = "Accepted";
//   const managerValues = { userId, status }; //for fetching requests based on managerId

//   useEffect(() => {
//     axios
//       .post(`${process.env.REACT_APP_API_URL}/pendingRequests`, managerValues)
//       .then((response) => {
//         setApprovalData(response.data);
//         // console.log(response.data);
//       });
//   }, []);
//   return (
//     <div className="Leaves" style={{ fontFamily: "Recharge" }}>
//       <h4 className="bg-purple text-dark text-center p-3 rounded">
//         Approved Requests - ({approvalData.length})
//       </h4>
//       <div className="d-flex justify-content-around flex-wrap">
//         {approvalData.map((request) => (
//           <div
//             key={request.userId}
//             className="card m-3"
//             style={{ width: "18rem" }}
//           >
//             <div className="card-body">
//               <p className="card-text">
//                 <strong>Name:</strong> {request.name}
//               </p>

//               <p className="card-text">
//                 <strong>Employee ID:</strong> {request.employeeId}
//               </p>

//               <p className="card-text">
//                 <strong>Total Leave Days:</strong> {request.totalLeaveDays}
//               </p>

//               <p className="card-text">
//                 <strong>Leave Reason:</strong> {request.leaveReason}
//               </p>

//               <p className="card-text">
//                 <strong>Start Date:</strong> {request.startDate}
//               </p>

//               <p className="card-text">
//                 <strong>End Date:</strong> {request.endDate}
//               </p>

//               <p className="card-text">
//                 <strong>Status:</strong> {request.status}
//               </p>

//               <p className="card-text">
//                 <strong>Comments:</strong> {request.comments}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
// export default ApprovedLeaves;
