import React, { useEffect, useState } from "react";
import axios from "axios";
import "./empleave.css";
import { useSelector } from "react-redux";
import LeaveRequestModal from "../managerRequests/LeaveReqsDetailsModal";

const FetchLeaveApplications = () => {
  const userId = useSelector((state) => state.login.userData.userId);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [leaveAppData, setLeaveAppData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/employeeLeaveApplications`,
        { userId }
      );
      setLeaveAppData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const openModal = (request) => {
    setSelectedRequest(request);
  };

  const closeModal = () => {
    setSelectedRequest(null);
  };

  return (
    <div className="Leaves" style={{ fontFamily: "Recharge" }}>
      <h4
        className="d-flex justify-content-center align-items-center bg-purple text-dark p-3 rounded"
        style={{
          borderRadius: "5px",
        }}
      >
        Your Leave Applications - ({leaveAppData.length})
      </h4>
      {/* <div className="d-flex justify-content-around flex-wrap">
        {leaveAppData.map((request) => (
          <div
            key={request._id}
            className="card m-2 "
            style={{ width: "18rem" }}>
            <div className="card-body ">
              <p className="card-text">
                <strong>Name:</strong> {request.name}
              </p>

              <p className="card-text">
                <strong>Employee ID:</strong> {request.employeeId}
              </p>

              <p className="card-text">
                <strong>LOP:</strong> {request.lop}
              </p>

              <p className="card-text">
                <strong>No of Days:</strong> {request.totalLeaveDays}
              </p>

              <p className="card-text">
                <strong>Leave Reason:</strong> {request.leaveReason}
              </p>

              <p className="card-text">
                <strong>Start Date:</strong> {request.startDate}
              </p>

              <p className="card-text">
                <strong>End Date:</strong> {request.endDate}
              </p>

              <p className="card-text">
                <strong>Status:</strong> {request.status}
              </p>

              <p className="card-text">
                <strong>Comments:</strong> {request.comments}
              </p>
            </div>
          </div>
        ))}
      </div> */}
      {leaveAppData && leaveAppData.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Employee ID</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {leaveAppData.map((request, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{request.name}</td>
                <td>{request.employeeId}</td>
                <td>{request.startDate}</td>
                <td>{request.endDate}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => openModal(request)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <LeaveRequestModal
        selectedRequest={selectedRequest}
        handleClose={closeModal}
      />
    </div>
  );
};
export default FetchLeaveApplications;
