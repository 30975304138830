import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import "./Dashboard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faCloudUpload,
  faEdit,
  faUser,
  faFileAlt,
  faCoins,
  faBarChart,
  faExclamationTriangle,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";

const Dashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const { role, name } = useSelector((state) => state.login.userData || {});
  const employeeId = useSelector(
    (state) => state.login.userData.employeeId || {}
  );

  const userId = useSelector((state) => state.login.userData.userId);
  // console.log(employeeId);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      //   // Check if the file is an image (you can add more file types if needed)
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!allowedTypes.includes(file.type)) {
        alert("Please select a valid image file (JPEG, PNG, GIF).");
        e.target.value = ""; // Clear the file input
        return;
      }
      // Check if the file size is within the allowed limit (in bytes)
      // const maxSize = 5 * 1024 * 1024; // 5 MB
      const maxSize = 200 * 1024; // 200 KB
      if (file.size > maxSize) {
        alert("File size exceeds the limit (200 KB).");
        e.target.value = ""; // Clear the file input
        return;
      }
      setSelectedFile(file);
    }
  };

  const fetchImageUrl = async () => {
    // console.log(userId);
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/profileImage/v1`, { userId })
        .then((res) => {
          setImageUrl(res.data.data);
          // console.log(res.data.data);
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleUpload = async () => {
    // console.log(userId);
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("userId", userId);
      formData.append("category", "profileImage");

      // console.log(formData);
      await axios
        .post(`${process.env.REACT_APP_API_URL}/uploadimage/v1`, formData)
        .then((res) => {
          // console.log("successfully uploaded", res.status);
          fetchImageUrl();
          setShowModal(false);
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    {
      userId && fetchImageUrl();
    }
  }, []);

  return (
    <ul className="list-unstyled px-3">
      <li>
        <div className="d-flex justify-content-between ">
          <strong className="">{name}</strong>
          {!imageUrl ? (
            <button className="text-dark" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faCloudUpload} className="favicon-image" />
            </button>
          ) : (
            <button className="text-dark" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faEdit} className="favicon-image" />
            </button>
          )}
        </div>
      </li>

      <li>
        <div className="profile-container ">
          {imageUrl ? (
            <img
              key={imageUrl}
              src={imageUrl}
              alt="Profile"
              className="profile-image rounded-circle "
            />
          ) : (
            <FontAwesomeIcon
              icon={faUserCircle}
              className="profile-image rounded-circle "
            />
          )}
        </div>
      </li>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            id="fileInput"
          />
          {selectedFile && (
            <button className="text-dark" onClick={handleUpload}>
              Upload
            </button>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <li>
        <p className="">
          <b className="p-2">Emp-Id:</b>
          {employeeId}
        </p>
        <hr style={{ color: "yellow" }} />
      </li>

      <li>
        <NavLink to="empDetails" className="nav-link-profile py-2">
          <FontAwesomeIcon icon={faUser} className="px-1" />
          Profile
        </NavLink>
      </li>
      {/* <li>
        <NavLink to="documents" className="nav-link-profile py-2">
          <FontAwesomeIcon icon={faFileAlt} className="px-1" />
          Documents
        </NavLink>
      </li> */}
      <li>
        {/* <NavLink
          to="#"
          className="nav-link-profile py-2"
          onClick={toggleDropdown}>
          <FontAwesomeIcon icon={faCoins} className="px-1" />
          Documents
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`dropdown-icon mx-4 ${isDropdownOpen ? "open" : ""}`}
          />
        </NavLink> */}
        <NavLink
          to="#"
          className="nav-link-profile d-flex justify-content-between py-2"
          onClick={() => {
            setIsDropdownOpen(!isDropdownOpen);
            // setIsDropdownOpen1(false);
            // setIsDropdownOpen2(false);
          }}>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faCoins} className="px-1" />
            Documents
          </div>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`dropdown-icon mr-3 ${isDropdownOpen ? "open" : ""}`}
          />
        </NavLink>
        {isDropdownOpen && (
          <div className="dropdown-content bg-white py-2">
            {/* Dropdown content goes here */}
            <NavLink to="documents" className="nav-link-profile py-2">
              <FontAwesomeIcon icon={faFileAlt} className="px-1" />
              Education-Docs
            </NavLink>

            {/* Add more NavLink items as needed */}
          </div>
        )}
      </li>

      <li>
        <NavLink to="payslip" className="nav-link-profile py-2">
          <FontAwesomeIcon icon={faCoins} className="px-1" />
          Payslips
        </NavLink>
      </li>

      <li>
        <NavLink to="empfinancialInfo" className="nav-link-profile py-2">
          <FontAwesomeIcon icon={faBarChart} className="px-1" />
          Emp Finance-Info
        </NavLink>
      </li>
    </ul>
  );
};

export default Dashboard;
