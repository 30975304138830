import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as yup from "yup";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmployeeModal = ({ userId, showModal, close }) => {
  const [data, setData] = useState();
  const [masterData, setMasterData] = useState();
  const [managerData, setManagerData] = useState();
  const [rawData, setRawData] = useState();
  const [loading, setLoading] = useState(false);
  // const [showModal,setShowModal] =useState()
  useEffect(() => {
    try {
      const api1 = `${process.env.REACT_APP_API_URL}/getMasterData/v1`;
      const api2 = `${process.env.REACT_APP_API_URL}/managerDataGet/v1`;
      const api3 = `${process.env.REACT_APP_API_URL}/eachemployee/v1`;
      const api4 = `${process.env.REACT_APP_API_URL}/fetchRawData/v1`;

      Promise.all([
        axios.get(api1),
        axios.get(api2),
        axios.post(api3, { userId }),
        axios.post(api4, {}),
      ]).then((responses) => {
        const [res1, res2, res3, res4] = responses;

        setMasterData(res1.data.data);
        setManagerData(res2.data.data);
        setData(res3.data.data);
        setRawData(res4.data.data[0]);
        // console.log(res4.data.data[0]);
      });
      // axios
      //   .post(`${process.env.REACT_APP_API_URL}/eachemployee/v1`, { userId })
      //   .then((res) => {
      //     console.log(res.data.data);
      //     setData(res.data.data);
      //   });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const valueUpdation = async (fieldName, value) => {
    // console.log(fieldName, value);
    setData((prevFinDetails) => ({
      ...prevFinDetails,
      [fieldName]: value,
      // prevFinDetails[fieldName] ? prevFinDetails[fieldName] : value,
    }));
    // console.log(data);
  };

  const checkboxUpdation = async (fieldName, value, checked) => {
    // console.log(fieldName, value, checked);
    {
      checked
        ? setData((prevFinDetails) => ({
            ...prevFinDetails,
            [fieldName]: [...prevFinDetails[fieldName], value],
          }))
        : //  []  this will make to direct access of particular field instaed of using key and index
          setData((prevFinDetails) => ({
            ...prevFinDetails,
            [fieldName]: prevFinDetails[fieldName].filter(
              (item) => item !== value
            ),
          }));
    }
  };

  const initialValues = {
    firstName: data ? data.firstName : "",
    lastName: data ? data.lastName : "",
    email: data ? data.email : "",
    contact: data ? data.contact : "",
    joiningDate: data ? data.joiningDate : "",
    employeeId: data ? data.employeeId : "",
    dateOfBirth: data ? data.dateOfBirth : "",
    role: data ? data.role : [],
    address: data ? data.address : "",
    gender: data ? data.gender : "",
    maritalStatus: data ? data.maritalStatus : "",
    package: data ? data.package : "",
    reportingManager: data ? data.reportingManager : "",
    designation: data ? data.designation : "",
    workType: data ? data.workType : "",
    workLocation: data ? data.workLocation : "",
    status: data ? data.status : "",
    country: data ? data.country : "",
  };
  // const validationSchema = yup.object({
  //   firstName: yup.string().required(),
  //   lastName: yup.string().required(),
  //   email: yup.string().required(),
  //   contact: yup.string().required(),
  //   joiningDate: yup.string().required(),
  //   employeeId: yup.string().required(),
  //   dateOfBirth: yup.string().required(),
  //   role: yup
  //     .array()
  //     .of(yup.string())
  //     .min(1, "Please select at least one role")
  //     .required("Please select at least one role"),
  //   address: yup.string().required(),
  //   gender: yup.string().required(),
  //   maritalStatus: yup.string().required(),
  //   package: yup.string().required(),
  //   reportingManager: yup.string().required(),
  //   designation: yup.string().required(),
  //   workType: yup.string().required(),
  //   workLocation: yup.string().required(),
  //   status: yup.string().required(),
  //   country: yup.string().required(),
  // });
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .matches(
        /^[a-z]{5,50}$/,
        "First name must be between 5 and 50 lowercase alphabets"
      )
      .required("First name is required"),
    // .matches(/^[a-z]$/)
    // .required()
    // .min(5)
    // .max(50),
    lastName: yup
      .string()
      .matches(
        /^[a-z]{5,50}$/,
        "Last name must be between 5 and 50 lowercase alphabets"
      )
      .required("Last name is required"),
    email: yup.string().email(),
    contact: yup
      .string()
      // .length(10)
      // .required()
      .matches(/^[0-9]+$/, "Must be a numeric value")
      .matches(/^[0-9]+$/, "Contact number must be a numeric value")
      .min(10, "Must be at least 10 characters")
      .max(10, "Must be at most 10 characters"),
    // .test(
    //   "len",
    //   "Contact number must be exactly 10 digits",
    //   (val) => val && val.length === 10
    // ),

    joiningDate: yup
      .string()
      .matches(
        /^\d{4}-\d{2}-\d{2}$/,
        "Date of birth must be in the format DD-MM-YYYY"
      )
      .required("Dateofbirth is required"),
    //  yup
    // .date()
    // .min(new Date(minDate))
    // .max(new Date())
    // .required("JoiningDate is required"),
    employeeId: yup.string(),
    dateOfBirth: yup
      .string()
      .matches(
        /^\d{4}-\d{2}-\d{2}$/,
        "Date of birth must be in the format DD-MM-YYYY"
      )
      .required("Dateofbirth is required"),

    role: yup
      .array()
      .of(yup.string())
      .min(1, "Please select at least one role")
      .required("Please select at least one role"),
    address: yup
      .string()
      .matches(/^(?=.*\S).*$/, "Address must contain at least one  character")
      .required("Address is required"),
    gender: yup.string().required("Gender is required"),
    maritalStatus: yup.string().required("Maritalstatus is required"),
    package: yup
      .string()
      .required("Package is required")
      .matches(/^[0-9]+$/, "Must be a numeric value")
      .min(5, "Must be at least 5 characters")
      .max(10, "Must be at most 10 characters"),

    reportingManager: yup.string().required("Reporting manager is required"),
    designation: yup.string().required("Designation is required"),
    workType: yup.string().required("Worktype is required"),
    workLocation: yup.string().required("Work location is required"),
    status: yup.string().required("Status is required"),
    country: yup
      .string()
      .required("Country is required")
      .matches(/^[a-z]{10,20}$/, "Must be a numeric value"),
  });

  const submitFunction = async (data) => {
    setLoading(true);
    try {
      // await validateForm();
      // console.log(data);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/updateEmployee/registrationdetails/v1`,
          {
            data,
          }
        )
        .then((res) => {
          // console.log(res.data.data);
          toast.success(res.data.data);
          setLoading(false);
        });
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };

  // const onSubmit = async (values, { resetForm }) => {
  //   try {
  //     console.log(values);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div>
      {/* {data && <h1> hello, welcome to editing </h1>} */}
      {data && (
        <Modal show={showModal} onHide={close} dialogClassName="modal-lg">
          <Modal.Header closeButton>
            <Modal.Title>Employee Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              // onSubmit={onSubmit}
            >
              {({}) => (
                <Form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="firstName" className="labelfont ">
                          First Name:
                        </label>
                        <br />
                        <Field
                          type="text"
                          name="firstName"
                          className="form-control"
                          onChange={(e) =>
                            valueUpdation("firstName", e.target.value)
                          }
                          value={data.firstName}
                        ></Field>
                        <ErrorMessage
                          component="div"
                          name="firstName"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="lastName" className="labelfont ">
                          Last Name:
                        </label>
                        <br />
                        <Field
                          type="text"
                          name="lastName"
                          className="form-control"
                          onChange={(e) =>
                            valueUpdation("lastName", e.target.value)
                          }
                          value={data.lastName}
                        ></Field>
                        <ErrorMessage
                          component="div"
                          name="lastName"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="email" className="labelfont ">
                          Email:
                        </label>
                        <br />
                        <Field
                          type="text"
                          name="email"
                          className="form-control"
                          onChange={(e) =>
                            valueUpdation("email", e.target.value)
                          }
                          value={data.email}
                        ></Field>
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="contact" className="labelfont ">
                          Contact:
                        </label>
                        <br />
                        <Field
                          type="tel"
                          name="contact"
                          className="form-control"
                          onChange={(e) =>
                            valueUpdation("contact", e.target.value)
                          }
                          value={data.contact}
                        ></Field>
                        <ErrorMessage
                          component="div"
                          name="contact"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="joiningDate" className="labelfont ">
                          JoiningDate:
                        </label>
                        <br />
                        <Field
                          type="date"
                          name="joiningDate"
                          className="form-control"
                          onChange={(e) =>
                            valueUpdation("joiningDate", e.target.value)
                          }
                          value={data.joiningDate}
                        ></Field>
                        <ErrorMessage
                          component="div"
                          name="joiningDate"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="employeeId" className="labelfont ">
                          EmployeeId:
                        </label>
                        <br />
                        <Field
                          type="text"
                          name="employeeId"
                          className="form-control"
                          onChange={(e) =>
                            valueUpdation("employeeId", e.target.value)
                          }
                          value={data.employeeId}
                        ></Field>
                        <ErrorMessage
                          component="div"
                          name="employeeId"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="dateOfBirth" className="labelfont ">
                          DateOfBirth:
                        </label>
                        <br />
                        <Field
                          type="date"
                          name="dateOfBirth"
                          className="form-control"
                          onChange={(e) =>
                            valueUpdation("dateOfBirth", e.target.value)
                          }
                          value={data.dateOfBirth}
                        ></Field>
                        <ErrorMessage
                          component="div"
                          name="dateOfBirth"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="country" className="labelfont ">
                          Country:
                        </label>
                        <br />
                        <Field
                          as="select"
                          name="country"
                          className="form-select"
                          onChange={(e) =>
                            valueUpdation("country", e.target.value)
                          }
                          value={data.country}
                        >
                          <option value="" selected>
                            select country
                          </option>
                          {rawData.countries.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                              {item.code}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="country"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="workLocation" className="labelfont ">
                          WorkLocation:
                        </label>
                        <br />
                        <Field
                          as="select"
                          name="workLocation"
                          className="form-select"
                          onChange={(e) =>
                            valueUpdation("workLocation", e.target.value)
                          }
                          value={data.workLocation}
                        >
                          {rawData.workLocation.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="workLocation"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="status" className="labelfont ">
                          Status:
                        </label>
                        <br />

                        <Field
                          as="select"
                          name="status"
                          className="form-select"
                          onChange={(e) => {
                            valueUpdation("status", e.target.value);
                          }}
                          value={data.status}
                        >
                          {rawData.status.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="status"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="package" className="labelfont ">
                          Package:
                        </label>
                        <br />
                        <Field
                          type="tel"
                          name="package"
                          className="form-control"
                          onChange={(e) =>
                            valueUpdation("package", e.target.value)
                          }
                          value={data.package}
                        ></Field>
                        <ErrorMessage
                          component="div"
                          name="package"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="maritalStatus" className="labelfont ">
                          MaritalStatus:
                        </label>
                        <br />
                        <Field
                          as="select"
                          name="maritalStatus"
                          className="form-select"
                          onChange={(e) =>
                            valueUpdation("maritalStatus", e.target.value)
                          }
                          value={data.maritalStatus}
                        >
                          {rawData.maritalStatus.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="maritalStatus"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="gender" className="labelfont ">
                          Gender:
                        </label>
                        <br />
                        <Field
                          as="select"
                          name="gender"
                          className="form-select"
                          onChange={(e) =>
                            valueUpdation("gender", e.target.value)
                          }
                          value={data.gender}
                        >
                          {rawData.gender.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                          {/* <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option> */}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="gender"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="m-2">
                        <label
                          htmlFor="reportingManager"
                          className="labelfont "
                        >
                          ReportingManager:
                        </label>
                        <br />
                        <Field
                          as="select"
                          name="reportingManager"
                          className="form-select"
                          onChange={(e) =>
                            valueUpdation("reportingManager", e.target.value)
                          }
                          value={data.reportingManager}
                        >
                          {managerData.map((manager, index) => (
                            <option key={index} value={manager.userId}>
                              {manager.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="reportingManager"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="designation" className="labelfont ">
                          Designation:
                        </label>
                        <br />
                        <Field
                          as="select"
                          name="designation"
                          className="form-select"
                          onChange={(e) =>
                            valueUpdation("designation", e.target.value)
                          }
                          value={data.designation}
                        >
                          {rawData.designation.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                          {/* <option value="Trainee">Trainee</option>
                          <option value="Junior">
                            Junior Software Engineer
                          </option>
                          <option value="Senior">
                            Senior Software Engineer
                          </option> */}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="designation"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="workType" className="labelfont ">
                          WorkType:
                        </label>
                        <br />
                        <Field
                          as="select"
                          name="workType"
                          className="form-select"
                          onChange={(e) =>
                            valueUpdation("workType", e.target.value)
                          }
                          value={data.workType}
                        >
                          {rawData.workType.map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                          {/* <option value="GeneralShift">General Shift</option>
                          <option value="MorningShift">Morning Shift</option>
                          <option value="NoonShift">Noon Shift</option>
                          <option value="NightShift">Night Shift</option> */}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="workType"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="address" className="labelfont ">
                          Address:
                        </label>
                        <br />
                        <Field
                          as="textarea"
                          id="TextArea"
                          name="address"
                          // rows="6"
                          cols="25"
                          className="form-control"
                          onChange={(e) =>
                            valueUpdation("address", e.target.value)
                          }
                          value={data.address}
                        ></Field>
                        <ErrorMessage
                          component="div"
                          name="address"
                          className="text-danger"
                        ></ErrorMessage>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="m-2">
                        <label htmlFor="role" className="labelfont ">
                          Roles:
                        </label>{" "}
                        <br />
                        {masterData.map((role, index) => (
                          <div key={index}>
                            <Field
                              type="checkbox"
                              name="role"
                              onChange={(e) => {
                                checkboxUpdation(
                                  "role",
                                  role.roleId,
                                  e.target.checked
                                );
                              }}
                              value={role.roleId}
                              checked={
                                data.role && data.role.includes(role.roleId)
                              }
                            />
                            <label for="">{role.roleName}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* <button type="submit" className="btn btn-outline-info">
                    Submit
                  </button> */}
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer>
            <input
              type="submit"
              onClick={() => submitFunction(data)}
              className="btn btn-outline-info"
              value="Submit"
              disabled={loading}
            />
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default EmployeeModal;
