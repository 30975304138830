import React from "react";
import { Link } from "react-router-dom";
const AboutUsPage = () => {
  return (
    <div className="container">
      <header className="text-center mb-5">
        <h1 className="display-4">About Our Organization</h1>
      </header>

      <section>
        <h2 className="mb-4">Who We Are</h2>
        <p>
          <h4>Wamika Technologies </h4> is an IT Services and Technologies
          enterprise with prolific backend engineering teams that serves
          customers accross the globe.
        </p>
      </section>

      <section>
        <h2 className="mb-4">Our Mission</h2>
        <p>
          We deliver a wide range of IT services accross varies industries &
          continue to focus on innovative products, services and solutions to
          assist our clients.
        </p>
        <p>
          We work closely with Technology innovaters and ensure that the team
          creates Effective Software based on thorough understanding of
          underlying Technologies.
        </p>
      </section>

      <section>
        <h2 className="mb-4">Contact Us</h2>
        <p>
          If you have any questions or would like to get in touch with us,
          please feel free to contact us using the information below:{" "}
          <Link to="/content">Contact Us</Link>
        </p>
        <address>
          <strong>WAMIKA TECHNOLOGIES:</strong>
          <br />
          Opposite DLf APHB colony, APHB Colony,
          <br />
          INDIA, ZIP Code:500032 ,Hyderabad,
          <br />
          Email: info@wamikatech.com.
        </address>
      </section>
    </div>
  );
};

export default AboutUsPage;
