import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./NavBar.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserProfile from "../login/userprofile/UserDropdown";

import Login from "../login/Login";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearLoginDetails } from "../store/loginSlice";
import NavConfig from "../config/NavConfig";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const YourNavbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const { pathname } = useLocation();
  const employeeInfo = useSelector((state) => state.login.userData);
  const userId = employeeInfo.userId || "";
  const token = employeeInfo.token || "";

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(false);

  const [showModal, setShowModal] = useState(false);
  // const [logoutTimeout, setLogoutTimeout] = useState(null);
  const handleScroll = () => {
    const offset = window.scrollY;
    const shouldBeSticky = offset > 100;
    setIsSticky(shouldBeSticky);
  };
  // const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    // let timeout;
    // const resetTimeout = () => {
    //   if (logoutTimeout) clearTimeout(logoutTimeout);
    //   let timeoutId = setTimeout(() => setIsActive(false), 60000);
    //   setLogoutTimeout(timeoutId);
    // };
    // const handleActivity = () => {
    //   setIsActive(true);
    //   resetTimeout();
    // };

    // resetTimeout();
    // window.addEventListener("mousemove", handleActivity);
    // window.addEventListener("keypress", handleActivity);
    // window.addEventListener("click", handleActivity);

    // const setupListeners = () => {
    //   document.addEventListener("mousemove", handleActivity);
    //   document.addEventListener("keydown", handleActivity);
    //   document.addEventListener("click", handleActivity);
    // };
    // clearing();
    // // timerStarts();
    // setupListeners();

    if (token) {
      parallelCheck();
    }
    window.addEventListener("scroll", handleScroll);

    // console.log("logout executing 1 effect");
    return () => {
      window.removeEventListener("scroll", handleScroll);
      // window.removeEventListener("mousemove", handleActivity);
      // window.removeEventListener("keypress", handleActivity);
      // window.removeEventListener("click", handleActivity);
    };
    // return () => {

    // }
    // return () => {
    //   document.removeEventListener("mousemove", handleActivity);
    //   document.removeEventListener("keydown", handleActivity);
    //   document.removeEventListener("click", handleActivity);
    //   clearing();
    // };
  }, []);
  // useEffect(() => {
  //   if (!isActive) handleLogout();
  //   console.log("logout executing 2 effect");
  // }, [isActive]);

  // let closeTimeout;
  // function timerStarts() {
  //   const timeoutId = setTimeout(() => {
  //     console.log("timeout executing");
  //     alert("LOGOUT SUCCESSFULLY");
  //     setIsLoggedIn(false);
  //     setUserData(false);
  //     localStorage.clear();
  //     dispatch(clearLoginDetails({}));
  //     navigate("/");
  //   }, 120000);
  //   setLogoutTimeout(timeoutId);
  // }
  // timerStarts();

  // function clearing() {
  //   clearTimeout(logoutTimeout);
  // }

  const parallelCheck = async () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/parallel?userId=${userId}&token=${token}`
        )
        .then((res) => {
          if (res.data.info === "Access granted to protected endpoint") {
            setIsLoggedIn(true);
            if (
              res.data.role.includes("Admin") ||
              res.data.role.includes("b69c8a13-2d22-4e97-bf45-c8ef3566c6a8")
            ) {
              setUserData(true);
            }
          } else {
            handleLogout();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  async function handleLogout() {
    // clearing();
    console.log("execution of logout functionality");
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/logout`).then((res) => {
        if (!res.data.message) {
          toast.success("Logout Successfully");
          // alert("LOGOUT SUCCESSFULLY");
          setIsLoggedIn(false);
          setUserData(false);
          localStorage.clear();
          dispatch(clearLoginDetails({}));
          navigate("/");
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <Navbar
      variant="dark"
      className={`navbar ${isSticky ? "sticky" : ""} py-2 smoky-white-bg`}
      fixed={isSticky ? "top" : ""}
      collapseOnSelect
      expand="lg">
      <Container>
        {/* <Navbar.Brand to="/" className="col-md-3">
          <Nav.Link as={Link} to="/">
            <img
              src="https://wamikatechdev.blob.core.windows.net/devstorage/wamiklogo.png"
              alt="Logo"
              width="70"
              height="50"
              className=""
            />
            <b className="text-dark px-3 weight-increase">
              {NavConfig.orgName}
            </b>
          </Nav.Link>
        </Navbar.Brand> */}
        <Navbar.Brand to="/" className="col-md-3">
          <Nav.Link as={Link} to="/">
            <div style={{ width: "70", height: "50" }} className="d-flex">
              <img
                // src="https://wamikatechdev.blob.core.windows.net/devstorage/wamiklogo.png"
                src="https://wamikatechdev.blob.core.windows.net/devstorage/4.jpg"
                alt="Logo"
                width="100"
                height="80"
                className="d-flex align-content-center"
              />

              <p className="text-dark px-2 weight-increase d-flex flex-column mb-0">
                {/* {NavConfig.orgName} */}
                <span className="orgName1">{NavConfig.orgName1}</span>

                <span className="orgName2">{NavConfig.orgName2}</span>
              </p>
            </div>
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="custom-toggler"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="col-md-12 justify-content-between">
            <Nav.Link>
              <Link to="/" className=" link-style">
                {NavConfig.home}
              </Link>
            </Nav.Link>

            <Nav.Link>
              <Link to="/aboutus" className=" link-style">
                {NavConfig.about}
              </Link>
            </Nav.Link>

            <Nav.Link>
              <Link to="/content" className=" link-style">
                {NavConfig.contact}
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="/careers" className=" link-style">
                {NavConfig.careers}
              </Link>
            </Nav.Link>

            {isLoggedIn && userData ? (
              <Nav.Link>
                <Link to="/registration" className=" link-style">
                  {NavConfig.registration}
                </Link>
              </Nav.Link>
            ) : null}

            {isLoggedIn ? (
              <UserProfile fun={handleLogout} />
            ) : (
              <Nav.Link>
                <Link
                  className=" link-style"
                  onClick={() => {
                    setShowModal(true);
                  }}>
                  {NavConfig.login}
                </Link>
              </Nav.Link>
            )}
            <Login
              showModal={showModal}
              setShowModal={setShowModal}
              setIsLoggedIn={setIsLoggedIn}
              setUserData={setUserData}
              // timerStarts={timerStarts}
              clearing={handleLogout}
            />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default YourNavbar;
