import { Formik, Form, ErrorMessage, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { Telephone } from "react-bootstrap-icons";
import "./Signup.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Signup = () => {
  const [masterData, setMasterData] = useState();
  const [managerData, setManagerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState();
 

  //==================joiningDate VALIDATION====================

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 3);

  //==================joiningDate VALIDATION====================

  useEffect(() => {
    const api1 = `${process.env.REACT_APP_API_URL}/getMasterData/v1`;
    const api2 = `${process.env.REACT_APP_API_URL}/managerDataGet/v1`;
    const api3 = `${process.env.REACT_APP_API_URL}/fetchRawData/v1`;

    Promise.all([axios.get(api1), axios.get(api2), axios.post(api3, {})]).then(
      (responses) => {
        const [res1, res2, res3] = responses;
        // console.log(res1.data.data, res2);
        setMasterData(res1.data.data);
        setManagerData(res2.data.data);
        setRawData(res3.data.data[0]);
      }
    );
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/postData`,
        values
      );
      toast.success("Successfully Employee Registered");
      // console.log(response);
      resetForm();
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup-container py-2">
      <div className="container">
        {masterData && managerData ? (
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              contact: "",
              joiningDate: "",
              employeeId: "",
              dateOfBirth: "",
              role: [],
              address: "",
              gender: "",
              maritalStatus: "",
              password: "",
              package: "",
              reportingManager: "",
              designation: "",
              workType: "",
              workLocation: "Hyderabad",
              status: "",
              country: "",
            }}
            validationSchema={yup.object({
              firstName: yup
                .string()
                .matches(
                  /^[A-Za-z]{5,50}$/,
                  "First name must be between 5 and 50 lowercase alphabets"
                )
                .required("First name is required"),
              // .matches(/^[a-z]$/)
              // .required()
              // .min(5)
              // .max(50),
              lastName: yup
                .string()
                .matches(
                  /^[A-Za-z]{5,50}$/,
                  "Last name must be between 5 and 50 lowercase alphabets"
                )
                .required("Last name is required"),
              email: yup.string().email(),
              contact: yup
                .string()
                // .length(10)
                // .required()
                .matches(/^[0-9]+$/, "Must be a numeric value")
                .matches(/^[0-9]+$/, "Contact number must be a numeric value")
                .min(10, "Must be at least 10 characters")
                .max(10, "Must be at most 10 characters")
                .required(),
              // .test(
              //   "len",
              //   "Contact number must be exactly 10 digits",
              //   (val) => val && val.length === 10
              // ),

              joiningDate: yup
                .date()
                .min(new Date(minDate))
                .max(new Date())
                .required("JoiningDate is required"),
              employeeId: yup.string(),
              dateOfBirth: yup
                .string()
                .matches(
                  /^\d{4}-\d{2}-\d{2}$/,
                  "Date of birth must be in the format DD-MM-YYYY"
                )
                .required("Dateofbirth is required"),

              role: yup
                .array()
                .of(yup.string())
                .min(1, "Please select at least one role")
                .required("Please select at least one role"),
              address: yup
                .string()
                .matches(
                  /^(?=.*\S).*$/,
                  "Address must contain at least one  character"
                )
                .required("Address is required"),
              gender: yup.string().required("Gender is required"),
              maritalStatus: yup.string().required("Maritalstatus is required"),
              package: yup
                .string()
                .required("Package is required")
                .matches(/^[0-9]+$/, "Must be a numeric value")
                .min(5, "Must be at least 5 characters")
                .max(10, "Must be at most 10 characters"),

              reportingManager: yup
                .string()
                .required("Reporting manager is required"),
              designation: yup.string().required("Designation is required"),
              workType: yup.string().required("Worktype is required"),
              workLocation: yup.string().required("Work location is required"),
              status: yup.string().required("Status is required"),
              country: yup.string().required("Country is required"),
            })}
            onSubmit={handleSubmit}>
            {({ values }) => (
              <div className="signup-form">
                <Form autoComplete="on">
                  <h2 className="text-center py-4 heading">
                    REGISTRATION FORM:
                  </h2>
                  <div className="row  ">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="firstName" className="labelfont">
                          First Name<span style={{ color: "red" }}>* </span>:
                        </label>
                        <Field
                          type="text"
                          name="firstName"
                          className="form-control"
                          placeholder="enter your first name"
                        />
                        <ErrorMessage
                          name="firstName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="lastName" className="labelfont">
                          Last Name<span style={{ color: "red" }}>* </span>:
                        </label>
                        <Field
                          type="text"
                          name="lastName"
                          className="form-control"
                          placeholder="enter your last name"
                        />
                        <ErrorMessage
                          name="lastName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="contact" className="labelfont">
                          Contact<span style={{ color: "red" }}>* </span>:
                        </label>
                        <Telephone />
                        <Field
                          type="tel"
                          name="contact"
                          className="form-control"
                          placeholder="enter your contact number"
                        />
                        <ErrorMessage
                          name="contact"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="joiningDate" className="labelfont">
                          JoiningDate<span style={{ color: "red" }}>* </span>:
                        </label>

                        <Field
                          type="date"
                          name="joiningDate"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="joiningDate"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="dateOfBirth" className="labelfont">
                          DateOfBirth<span style={{ color: "red" }}>* </span>:
                        </label>

                        <Field
                          type="date"
                          name="dateOfBirth"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="dateOfBirth"
                          className="text-danger"
                          component="div"
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="workLocation" className="labelfont">
                          WorkLocation<span style={{ color: "red" }}>* </span>:
                        </label>

                        <Field
                          as="select"
                          name="workLocation"
                          className="form-select">
                          <option value="">select Location</option>
                          {rawData.workLocation.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="workLocation"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="address" className="labelfont">
                          Address<span style={{ color: "red" }}>* </span>:
                        </label>

                        <Field
                          as="textarea"
                          id="TextArea"
                          name="address"
                          rows="6"
                          cols="59"
                          className="ml-2 address-signup"
                        />
                        <ErrorMessage
                          name="address"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>
                    {/* Add more fields on the left side as needed */}

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="package" className="labelfont">
                          AnnualPackage<span style={{ color: "red" }}>* </span>:
                        </label>
                        <Field
                          type="text"
                          name="package"
                          placeholder="AnnualPackage"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="package"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="reportingManager" className="labelfont">
                          Reporting-Manager
                          <span style={{ color: "red" }}>* </span>:
                        </label>
                        <Field
                          as="select"
                          name="reportingManager"
                          className="form-select">
                          <option value="" selected>
                            Select Reporting-Manager
                          </option>
                          {managerData.map((manager, index) => (
                            <option key={index} value={manager.userId}>
                              {manager.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="reportingManager"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="designation" className="labelfont">
                          Designation<span style={{ color: "red" }}>* </span>:
                        </label>
                        <Field
                          as="select"
                          name="designation"
                          className="form-select">
                          <option value="" selected>
                            select designation
                          </option>
                          {rawData.designation.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                          {/* <option value="Trainee">Trainee</option>
                          <option value="Junior">
                            Junior Software Engineer
                          </option>
                          <option value="Senior">
                            Senior Software Engineer
                          </option> */}
                        </Field>
                        <ErrorMessage
                          name="designation"
                          className="text-danger"
                          component="div"></ErrorMessage>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="workType" className="labelfont">
                          Work-Type<span style={{ color: "red" }}>* </span>:
                        </label>
                        <Field
                          as="select"
                          name="workType"
                          className="form-select ">
                          <option value="" selected>
                            select work-type
                          </option>
                          {rawData.workType.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                          {/* <option value="GeneralShift">General Shift</option>
                          <option value="MorningShift">Morning Shift</option>
                          <option value="NoonShift">Noon Shift</option>
                          <option value="NightShift">Night Shift</option> */}
                        </Field>
                        <ErrorMessage
                          name="workType"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="status" className="labelfont">
                          Employee-Status
                          <span style={{ color: "red" }}>* </span>:
                        </label>
                        <Field
                          as="select"
                          name="status"
                          className="form-select ">
                          <option value="" selected>
                            select employee-status
                          </option>
                          {rawData.status.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                          {/* <option value="Active">Active</option>
                          <option value="InActive">InActive</option> */}
                        </Field>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="text-danger"></ErrorMessage>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="country" className="labelfont">
                          Country
                          <span style={{ color: "red" }}>* </span>:
                        </label>
                        <Field
                          as="select"
                          name="country"
                          className="form-select">
                          <option value="" selected>
                            select country
                          </option>
                          {rawData.countries.map((item, index) => (
                            <option value={item.name} key={index}>
                              {item.name}-{item.code}
                            </option>
                          ))}
                        </Field>

                        <ErrorMessage
                          name="country"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="" className="labelfont">
                          Role<span style={{ color: "red" }}>* </span>:
                        </label>
                        {masterData.map((role, index) => (
                          <div
                            key={index}
                            className="form-check form-check-inline ml-2">
                            <Field
                              type="checkbox"
                              value={role.roleId}
                              name="role"
                              id={role.roleName}
                              className="form-check-input"
                              checked={values.role.includes(role.roleId)}
                            />
                            <label
                              htmlFor={role.roleName}
                              className="form-check-label ">
                              {role.roleName}
                            </label>
                          </div>
                        ))}
                        <ErrorMessage
                          name="role"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="gender" className="labelfont">
                          Gender<span style={{ color: "red" }}>* </span>:
                        </label>
                        <Field
                          as="select"
                          className="form-select"
                          name="gender">
                          <option value="">select gender</option>
                          {rawData.gender.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>

                            // <div
                            //   key={index}
                            //   className="form-check form-check-inline">
                            //   <Field
                            //     type="radio"
                            //     name="gender"
                            //     value={item}
                            //     id={item}
                            //     className="form-check-input"
                            //   />
                            //   <label htmlFor={item} className="form-check-label">
                            //     {item}
                            //   </label>
                            // </div>;
                          ))}
                        </Field>
                        {/* <div className="form-check form-check-inline">
                          <Field
                            type="radio"
                            name="gender"
                            value="Male"
                            id="Male"
                            className="form-check-input"
                          />
                          <label htmlFor="Male" className="form-check-label">
                            Male
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Field
                            type="radio"
                            name="gender"
                            value="Female"
                            id="Female"
                            className="form-check-input"
                          />
                          <label htmlFor="Female" className="form-check-label">
                            Female
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Field
                            type="radio"
                            name="gender"
                            value="Others"
                            id="Others"
                            className="form-check-input"
                          />
                          <label htmlFor="others" className="form-check-label">
                            Others
                          </label>
                        </div> */}
                        <ErrorMessage
                          name="gender"
                          className="text-danger"
                          component="div"></ErrorMessage>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="maritalStatus" className="labelfont">
                          MaritalStatus<span style={{ color: "red" }}>* </span>:
                        </label>
                        <Field
                          as="select"
                          name="maritalStatus"
                          className="form-select">
                          <option value="">select MaritalStatus</option>
                          {rawData.maritalStatus.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </Field>
                        {/* <div className="form-check form-check-inline">
                          <Field
                            type="radio"
                            name="maritalStatus"
                            value="Married"
                            id="Married"
                            className="form-check-input"
                          />
                          <label htmlFor="Married" className="form-check-label">
                            Married
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Field
                            type="radio"
                            name="maritalStatus"
                            value="UnMarried"
                            id="UnMarried"
                            className="form-check-input"
                          />
                          <label
                            htmlFor="UnMarried"
                            className="form-check-label">
                            UnMarried
                          </label>
                        </div> */}
                        <ErrorMessage
                          name="maritalStatus"
                          className="text-danger"
                          component="div"></ErrorMessage>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-3  ">
                    <input
                      type="reset"
                      value="Clear"
                      className="btn btn-primary mx-2"
                    />
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-success mx-2"
                      disabled={loading}
                    />
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        ) : (
          <h3>Loading.......!</h3>
        )}
      </div>
    </div>
  );
};

export default Signup;
