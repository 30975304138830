import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setJobData } from "./jobDataSlice";
import workSpaceImg1 from "./image1.jpg";
import workSpaceImg2 from "./image4.jpg";
import "./Careers.css";

const Careers = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [locationQuery, setLocationQuery] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getPostedJobsData/v1`
        );
        dispatch(setJobData(response.data));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const jobPostingsData = useSelector((state) => state.jobData.jobPostingsData);
  const handleSearch = () => {
    const navigatingPath = "/careers/jobs";
    const lowerCaseQuery = searchQuery.toLowerCase();
    const lowerCaseLocation = locationQuery.toLowerCase();

    if (!lowerCaseQuery && !lowerCaseLocation) {
      const filtered = jobPostingsData;
      navigate(navigatingPath, { state: { filtered } });
    } else if (lowerCaseQuery && lowerCaseLocation) {
      const filtered = jobPostingsData.filter(
        (job) =>
          (job.jobTitle.toLowerCase().includes(lowerCaseQuery) ||
            job.jobDescription.toLowerCase().includes(lowerCaseQuery) ||
            job.jobNumber == parseInt(searchQuery, 10)) &&
          (job.location
            ? job.location.toLowerCase().includes(lowerCaseLocation)
            : null)
      );
      navigate(navigatingPath, { state: { filtered } });
    } else if (lowerCaseQuery) {
      const filtered = jobPostingsData.filter((job) =>
        job
          ? job.jobTitle.toLowerCase().includes(lowerCaseQuery) ||
            job.jobDescription.toLowerCase().includes(lowerCaseQuery) ||
            job.jobNumber == parseInt(searchQuery, 10)
          : null
      );
      navigate(navigatingPath, { state: { filtered } });
    } else if (lowerCaseLocation) {
      const filtered = jobPostingsData.filter((job) =>
        job.location
          ? job.location.toLowerCase().includes(lowerCaseLocation)
          : null
      );
      navigate(navigatingPath, { state: { filtered } });
    }
  };

  return (
    <div>
      <div className="searchjob p-3">
        <div className="skeyword">
          <svg
            className="icons"
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            viewBox="0 0 512 512"
          >
            <path
              opacity="0.8"
              fill="#1E3050"
              d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
            />
          </svg>
          <input
            type="text"
            className="searchbars"
            placeholder="Search with Job ID, Title, or Description"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="slocation">
          <svg
            className="icons"
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="12"
            viewBox="0 0 384 512"
          >
            <path
              opacity="0.5"
              d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
            />
          </svg>
          <input
            type="text"
            className="searchbars"
            placeholder="Search by Location"
            value={locationQuery}
            onChange={(e) => setLocationQuery(e.target.value)}
          />
        </div>
        <button onClick={handleSearch} className=" btn btn-primary mx-1">
          Find Jobs
        </button>
      </div>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="card-body styling mb-4">
              <img src={workSpaceImg1} alt="abc" className="resize rounded-4" />
              <p className="card-text text-center fw-bold">Culture</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-body styling mb-4">
              <img src={workSpaceImg2} alt="abc" className="resize rounded-4" />
              <p className="card-text text-center fw-bold">Work Environment</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="card-body styling mb-4">
              <img src={workSpaceImg1} alt="abc" className="resize rounded-4" />
              <p className="card-text text-center fw-bold">Culture</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-body styling mb-4">
              <img src={workSpaceImg2} alt="abc" className="resize rounded-4" />
              <p className="card-text text-center fw-bold">Work Environment</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
