import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import staticValues from "../../../../config/staticValues";
import "./GetPostedJobs.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GetPostedJobs = () => {
  const navigate = useNavigate();
  const [postedJobs, setPostedJobs] = useState(null);
  const jobPostedBy = useSelector((state) => state.login.userData.userId);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/getSpecificPostedJobs/v1`,
          { jobPostedBy: jobPostedBy }
        );
        setPostedJobs(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  const handleApplyClick = async (jobId, jobNumber) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getResumesUrl/v1`,
        { jobId }
      );
      const jobData = postedJobs.find((job) => job.jobId === jobId);

      // Navigate to ApplicationsReceivedComponent with jobId, job data, and resumes
      navigate(`/applications/${jobNumber}`, {
        state: {
          jobData,
          resumes: response.data,
        },
      });
    } catch (error) {
      console.error("Error fetching resumes:", error);
    }
  };

  const updateJobStatus = async (jobId) => {
    try {
      const status = staticValues.statusClosed;
      // const response =
      await axios.post(`${process.env.REACT_APP_API_URL}/updateJobStatus/v1`, {
        jobId: jobId,
        status: status,
      });
      toast.success("Job Closed Successfully ");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-3">
      <table style={{ width: "100%" }} className="">
        {postedJobs && postedJobs.length > 0 && (
          <thead>
            <tr>
              <th>Job Number</th>
              <th>Job Title</th>
              <th>Status</th>
              <th>Resumes</th>
              <th>Close Job</th>
            </tr>
          </thead>
        )}
        <tbody>
          {postedJobs && postedJobs.length > 0 ? (
            postedJobs.map((job) => (
              <tr key={job.jobNumber} className="custom-height">
                <td>{job.jobNumber}</td>
                <td>{job.jobTitle}</td>
                <td>{job.status}</td>
                <td>
                  <button
                    onClick={() => handleApplyClick(job.jobId, job.jobNumber)}
                  >
                    Applications Received for {job.jobNumber}
                  </button>
                </td>
                <td>
                  {job.status === "Active" && (
                    <button onClick={() => updateJobStatus(job.jobId)}>
                      Close Job
                    </button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr className="text-center">
              <td colSpan="5" className="py-4">
                <h4>You have not posted any jobs yet</h4>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GetPostedJobs;
// ===================================================================================================
