import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// import Form1 from "./Form1";
// import Form2 from "./Form2";
import ExcelReportGeneration from "./ExcelGeneration";
import TrendsReportGeneration from "./TrendsGeneration";

const TabForms = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={tabIndex} onChange={handleChange} aria-label="form tabs">
        <Tab label="Excel Generation" />
        <Tab label="Trends Generation" />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <ExcelReportGeneration />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <TrendsReportGeneration />
      </TabPanel>
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default TabForms;
