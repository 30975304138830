// LeaveRequestModal.js
import React from "react";
import { Modal } from "react-bootstrap";

const LeaveRequestModal = ({ selectedRequest, handleClose }) => {
  return (
    <Modal show={selectedRequest !== null} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Request Details</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Display details for selected request */}
        <p>
          <b>Name</b>: {selectedRequest?.name}
        </p>
        <p>
          <b>Employee ID</b>: {selectedRequest?.employeeId}
        </p>
        <p>
          <b>Total Leave Days</b>: {selectedRequest?.totalLeaveDays}
        </p>
        <p>
          <b>Leave Reason</b>: {selectedRequest?.leaveReason}
        </p>
        <p>
          <b>Start Date</b>: {selectedRequest?.startDate}
        </p>
        <p>
          <b>End Date</b>: {selectedRequest?.endDate}
        </p>
        <p>
          <b>Status</b>: {selectedRequest?.status}
        </p>
        <p>
          <b>Comments</b>: {selectedRequest?.comments}
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default LeaveRequestModal;
