import React from "react";
import { Modal, Button, Table } from "react-bootstrap";

const ProjectModal = ({ project, handleCloseModal, showModal }) => {
  // console.log(project);
  const totalDuration = project.objs.reduce(
    (total, item) => total + parseInt(item.duration),
    0
  )
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Project Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <b>EmployeeName: </b> */}
        {/* <i className="text-primary">{project[0].empName}</i> <br /> */}
        <b>TotalHours: </b>
        <i className="text-muted"> {totalDuration}</i>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Project</th>
              <th>Date</th>
              <th>Duration(Hrs)</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {project.objs.map((project) => (
              <tr>
                <td>{project.project}</td>
                <td>{project.date}</td>
                <td>{project.duration} </td>
                <td>{project.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectModal;
