import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import axios from "axios";
import { useSelector } from "react-redux";
import "./PostJobForm.css";
import staticValues from "../../../../config/staticValues";

const PostJobForm = () => {
  const userId = useSelector((state) => state.login.userData.userId);
  const [skillsOptions, setSkillsOptions] = useState([]);
  useEffect(() => {
    const fetchSkillsOptions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/getSkills/v1`
        );
        setSkillsOptions(response.data);
      } catch (error) {
        console.error("Error fetching skills options:", error);
      }
    };

    fetchSkillsOptions();
  }, []);
  const handleFormReset = () => {
    formik.resetForm({
      values: {
        jobTitle: "",
        jobDescription: "",
        numberOfPositions: "",
        qualifications: "",
        responsibilities: "",
        requiredSkills: [],
        optionalSkills: [],
        experience: "",
        workType: "",
        location: "",
      },
    });
  };
  const formik = useFormik({
    initialValues: {
      jobTitle: "",
      jobDescription: "",
      numberOfPositions: "",
      qualifications: "",
      responsibilities: "",
      datePosted: new Date().toISOString().split("T")[0],
      requiredSkills: [],
      optionalSkills: [],
      experience: "",
      workType: "",
      location: "",
    },
    validationSchema: Yup.object({
      jobTitle: Yup.string()
        .trim()
        .matches(/^[A-Za-z\s]+$/, "Job Title should only contain letters")
        .min(5, "Job Title should be at least 5 characters")
        .required("Job Title is a required field"),
      jobDescription: Yup.string()
        .trim()
        .required("Job Description is a required field"),
      numberOfPositions: Yup.string()
        .matches(
          /^\d{1,3}$|^NA$/,
          'Number of Positions should be either one, two, or three digits, or "NA"'
        )
        .required("Number of Positions is a required field"),
      qualifications: Yup.string().required(
        "Qualifications is a required field"
      ),
      responsibilities: Yup.string().required(
        "Responsibilities is a required field"
      ),
      datePosted: Yup.string(),
      requiredSkills: Yup.array()
        .min(1, "Please select at least one option.")
        .required("Please select at least one option."),
      optionalSkills: Yup.array(),
      experience: Yup.string()

        .matches(
          /(^\d{1,2}$|^Fresher$|^NA$)/,
          'Number of Positions should be a number, "Fresher", or "NA"'
        )
        .test("max", "Experience cannot be more than 50", (value) => {
          if (value === "Fresher" || value === "NA") {
            return true; // No validation needed for "Fresher" or "NA"
          }
          const intValue = parseInt(value); // Convert value to integer
          return intValue <= 50; // Check if value is less than or equal to 50
        })
        .required("Experience is a required field"),
      workType: Yup.string().required("Work Type is a required field"),
      location: Yup.string().required("Location is a required field"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        values.qualifications = values.qualifications.split("*");
        values.responsibilities = values.responsibilities.split("*");
        const postData = {
          ...values,
          jobPostedBy: userId,
          status: staticValues.statusActive,
        };
        // const response =
        await axios.post(
          `${process.env.REACT_APP_API_URL}/postJobPostData/v1`,
          postData
        );
        resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const mappedSkills = skillsOptions.map((skill) => ({
    value: skill.skillId,
    label: skill.skillName,
  }));

  return (
    <form onSubmit={formik.handleSubmit} className="container label-font">
      <h2 className="text-center" style={{ color: "#c72282" }}>
        POST JOB{" "}
      </h2>
      <div className="form-group">
        <label htmlFor="jobTitle">
          Job Title<span className="required-field">*</span>:
        </label>
        <input
          type="text"
          id="jobTitle"
          name="jobTitle"
          className="form-control"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.jobTitle}
        />
        {formik.touched.jobTitle && formik.errors.jobTitle ? (
          <div style={{ color: "red" }}>{formik.errors.jobTitle}</div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="jobDescription">
          Job Description<span className="required-field">*</span>:
        </label>
        <textarea
          id="jobDescription"
          name="jobDescription"
          className="form-control"
          rows="5"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.jobDescription}
        />
        {formik.touched.jobDescription && formik.errors.jobDescription ? (
          <div style={{ color: "red" }}>{formik.errors.jobDescription}</div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="numberOfPositions">
          Number of Positions<span className="required-field">*</span>:
        </label>
        <input
          type="text"
          id="numberOfPositions"
          name="numberOfPositions"
          className="form-control"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.numberOfPositions}
        />
        {formik.touched.numberOfPositions && formik.errors.numberOfPositions ? (
          <div style={{ color: "red" }}>{formik.errors.numberOfPositions}</div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="datePosted">Date Posted:</label>
        <input
          type="date"
          id="datePosted"
          name="datePosted"
          className="form-control"
          min={new Date().toISOString().split("T")[0]}
          max={new Date().toISOString().split("T")[0]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.datePosted}
        />
        {formik.touched.datePosted && formik.errors.datePosted ? (
          <div style={{ color: "red" }}>{formik.errors.datePosted}</div>
        ) : null}
      </div>
      <div>
        <label htmlFor="requiredSkills">
          Required Skills<span className="required-field">*</span>:
        </label>
        <Select
          id="requiredSkills"
          name="requiredSkills"
          value={formik.values.requiredSkills}
          onChange={(requiredSkills) =>
            formik.setFieldValue("requiredSkills", requiredSkills)
          }
          options={mappedSkills}
          isMulti
        />
        {formik.touched.requiredSkills && formik.errors.requiredSkills && (
          <div style={{ color: "red" }}>{formik.errors.requiredSkills}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="optionalSkills">Optional Skills:</label>
        <Select
          id="optionalSkills"
          name="optionalSkills"
          value={formik.values.optionalSkills}
          onChange={(optionalSkills) =>
            formik.setFieldValue("optionalSkills", optionalSkills)
          }
          options={mappedSkills}
          isMulti
        />
      </div>

      <div className="form-group">
        <label htmlFor="qualifications">
          Qualifications<span className="required-field">*</span>:
        </label>
        <textarea
          id="qualifications"
          name="qualifications"
          className="form-control"
          rows="3"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.qualifications}
        />
        <span class="help-text">Separate each point using "*".</span>
        {formik.touched.qualifications && formik.errors.qualifications ? (
          <div style={{ color: "red" }}>{formik.errors.qualifications}</div>
        ) : null}
      </div>

      <div className="form-group">
        <label htmlFor="responsibilities">
          Responsibilities<span className="required-field">*</span>:
        </label>
        <textarea
          id="responsibilities"
          name="responsibilities"
          className="form-control"
          rows="3"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.responsibilities}
        />
        <span class="help-text">Separate each point using "*".</span>
        {formik.touched.responsibilities && formik.errors.responsibilities ? (
          <div style={{ color: "red" }}>{formik.errors.responsibilities}</div>
        ) : null}
      </div>

      <div className="form-group">
        <label htmlFor="experience">
          Experience<span className="required-field">*</span>:
        </label>
        <input
          type="text"
          id="experience"
          name="experience"
          className="form-control"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.experience}
        />
        {formik.touched.experience && formik.errors.experience ? (
          <div style={{ color: "red" }}>{formik.errors.experience}</div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="workType">
          Work Type<span className="required-field">*</span>:
        </label>
        <select
          id="workType"
          name="workType"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.workType}
        >
          <option value="">Select Work Type</option>
          <option value="on-site">On-site</option>
          <option value="off-site">Off-site</option>
          <option value="hybrid">Hybrid</option>
        </select>

        {formik.touched.workType && formik.errors.workType ? (
          <div style={{ color: "red" }}>{formik.errors.workType}</div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="location">
          Location<span className="required-field">*</span>:
        </label>
        <input
          type="text"
          id="location"
          name="location"
          className="form-control"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.location}
        />
        {formik.touched.location && formik.errors.location ? (
          <div style={{ color: "red" }}>{formik.errors.location}</div>
        ) : null}
      </div>
      <div className="form-group text-center">
        <input
          type="reset"
          value="clear"
          className="btn btn-primary m-3"
          onClick={handleFormReset}
        />
        <input type="submit" value="Submit" className="btn btn-success ml-5" />
      </div>
    </form>
  );
};

export default PostJobForm;

// import React, { useEffect, useState } from "react";
// import { Field, ErrorMessage, Form, Formik, useFormik } from "formik";
// import * as Yup from "yup";
// import Select from "react-select";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import "./PostJobForm.css";

// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const PostJobForm = () => {
//   const userId = useSelector((state) => state.login.userData.userId);
//   const [skillsOptions, setSkillsOptions] = useState([]);
//   const [requiredSkills, setRequiredSkills] = useState([]);
//   const [optionalSkills, setOptionalSkills] = useState([]);
//   const initialValues = {
//     jobTitle: "",
//     jobDescription: "",
//     qualifications: "",
//     responsibilities: "",
//     datePosted: new Date().toISOString().split("T")[0],
//     experience: "",
//     numberOfPositions: "",
//     requiredSkills: [], // Change to an array for multiple selections
//     optionalSkills: [],
//     workType: "",
//     location: "",
//   };

//   const validationSchema = Yup.object({
//     jobTitle: Yup.string().min(2, "Too Short!").required(),
//     jobDescription: Yup.string().required(),
//     numberOfPositions: Yup.string().required(),
//     qualifications: Yup.string().required(),
//     responsibilities: Yup.string().required(),
//     datePosted: Yup.date().required(),
//     // requiredSkills: Yup.array().required(),
//     requiredSkills: Yup.array({})
//       // .of(
//       //   Yup.object().shape({
//       //     value: Yup.string().required(),
//       //     label: Yup.string().required(),
//       //   })
//       // )
//       // .min(1, "At least one required skill is required")
//       .required(),
//     optionalSkills: Yup.array(),
//     // requiredSkills: Yup.array()
//     //   .min(1, "At least one required skill is required")
//     //   .required(),
//     // optionalSkills: Yup.array()
//     //   .min(1, "At least one optional skill is required")
//     //   .required(),
//     experience: Yup.string().required(),
//     workType: Yup.string().required(),
//     location: Yup.string().required(),
//   });

//   const onSubmit = async (values, { resetForm }) => {
//     console.log(values);
//     // console.log("Selected Required Skills:", requiredSkills);
//     // console.log("Selected Optional Skills:", optionalSkills);
//     const formData = {
//       requiredJobSkills: requiredSkills ? requiredSkills : [],
//       optionalJobSkills: optionalSkills ? optionalSkills : [],
//       jobPostedBy: userId,
//       status: "Active",
//     };
//     // console.log("formData:", formData);
//     // console.log("values:", values);
//     try {
//       values.qualifications = values.qualifications.split("#");
//       values.responsibilities = values.responsibilities.split("#");
//       const postData = { ...formData, ...values };

//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/postJobPostData/v1`,

//         postData
//       );
//       // console.log("API response:", response);
//       resetForm();
//       setRequiredSkills([]);
//       setOptionalSkills([]);
//       toast.success("Successfully Posted");
//     } catch (error) {
//       console.error("API error:", error);
//       toast.error(error.message);
//     }
//   };

//   useEffect(() => {
//     // Fetch skills options from the backend API
//     const fetchSkillsOptions = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/getSkills/v1`
//         );
//         console.log(response.data);
//         setSkillsOptions(response.data);
//       } catch (error) {
//         console.error("Error fetching skills options:", error);
//       }
//     };

//     fetchSkillsOptions();
//   }, []);
//   const mappedSkills =
//     skillsOptions && skillsOptions.length > 0
//       ? skillsOptions.map((skill) => ({
//           value: skill.skillId,
//           label: skill.skillName,
//         }))
//       : [];

//   const handleRequiredSelectChange = (requiredSkills) => {
//     console.log("Selected Required Skills:", requiredSkills);
//     setRequiredSkills(requiredSkills);
//   };
//   const handleOptionalSelectChange = (optionalSkills) => {
//     // console.log("Selected optional Skills:", optionalSkills);
//     setOptionalSkills(optionalSkills);
//   };

//   return (
//     <Formik
//       initialValues={initialValues}
//       validationSchema={validationSchema}
//       onSubmit={onSubmit}
//       enableReinitialize={true}
//     >
//       <Form className="container label-font">
//         <h2 className="text-center" style={{ color: "#c72282" }}>
//           POST JOB
//         </h2>

//         <div className="form-group ">
//           <label htmlFor="jobTitle">Job Title:</label>
//           <Field
//             className="form-control"
//             type="text"
//             id="jobTitle"
//             name="jobTitle"
//           />
//           <ErrorMessage
//             name="jobTitle"
//             component="div"
//             className="text-danger"
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="jobDescription">Job Description:</label>
//           <Field
//             className="form-control"
//             type="text"
//             id="jobDescription"
//             name="jobDescription"
//           />
//           <ErrorMessage
//             name="jobDescription"
//             component="div"
//             className="text-danger"
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="numberOfPositions">Number of Positions:</label>
//           <Field
//             className="form-control"
//             type="number"
//             id="numberOfPositions"
//             name="numberOfPositions"
//           />
//           <ErrorMessage
//             name="numberOfPositions"
//             component="div"
//             className="text-danger"
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="qualifications">Qualifications:</label>
//           <Field
//             as="textarea"
//             rows="6"
//             className="form-control"
//             id="qualifications"
//             name="qualifications"
//           />
//           <span class="help-text">Separate each point using "#".</span>

//           <ErrorMessage
//             name="qualifications"
//             className="text-danger"
//             component="div"
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="responsibilities">Responsibilities:</label>
//           <Field
//             as="textarea"
//             rows="6"
//             className="form-control"
//             id="responsibilities"
//             name="responsibilities"
//           />
//           <span class="help-text">Separate each point using "#".</span>
//           <ErrorMessage
//             name="responsibilities"
//             className="text-danger"
//             component="div"
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="experience">Work Experience:</label>
//           <Field
//             type="text"
//             className="form-control"
//             id="experience"
//             name="experience"
//           />
//           <ErrorMessage
//             name="experience"
//             className="text-danger"
//             component="div"
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="datePosted">Date Posted:</label>
//           <Field
//             type="date"
//             className="form-control"
//             id="datePosted"
//             name="datePosted"
//             min={new Date().toISOString().split("T")[0]}
//             max={new Date().toISOString().split("T")[0]}
//           />
//           <ErrorMessage
//             name="datePosted"
//             className="text-danger"
//             component="div"
//           />
//         </div>
//         <div>
//           <label htmlFor="selectedOptions">Select Options:</label>
//           <Select
//             id="selectedOptions"
//             name="selectedOptions"
//             value={formik.values.selectedOptions}
//             onChange={(selectedOptions) =>
//               formik.setFieldValue("selectedOptions", selectedOptions)
//             }
//             options={mappedSkills}
//             isMulti
//           />
//           {formik.touched.selectedOptions && formik.errors.selectedOptions && (
//             <div style={{ color: "red" }}>{formik.errors.selectedOptions}</div>
//           )}
//         </div>
//         <div className="form-group">
//           <label htmlFor="requiredSkills">Required Skills:</label>
//           <Select
//             id="requiredSkills"
//             options={skillsOptions.map((option) => ({
//               value: option.skillId,
//               label: option.skillName,
//             }))}
//             isMulti
//             value={requiredSkills}
//             // onChange={handleRequiredSelectChange}
//             onChange={(selectedSkills) =>
//               handleRequiredSelectChange(selectedSkills)
//             }
//           />
//           <ErrorMessage
//             name="requiredSkills"
//             className="text-danger"
//             component="div"
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="optionalSkills">optional Skills:</label>
//           <Select
//             id="optionalSkills"
//             options={skillsOptions.map((option) => ({
//               value: option.skillId,
//               label: option.skillName,
//             }))}
//             isMulti
//             value={optionalSkills}
//             onChange={handleOptionalSelectChange}
//           />
//           <ErrorMessage
//             name="optionalSkills"
//             className="text-danger"
//             component="div"
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="workType">Work Type:</label>
//           <Field
//             as="select"
//             className="form-control"
//             id="workType"
//             name="workType"
//           >
//             <option value="" selected>
//               Select Work Type
//             </option>
//             <option value="on-site">On-site</option>
//             <option value="off-site">Off-site</option>
//             <option value="hybrid">Hybrid</option>
//           </Field>
//           <ErrorMessage
//             name="workType"
//             className="text-danger"
//             component="div"
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="location">Work Location:</label>
//           <Field
//             type="text"
//             className="form-control"
//             id="location"
//             name="location"
//           />
//           <ErrorMessage
//             name="location"
//             className="text-danger"
//             component="div"
//           />
//         </div>
//         <div className="form-group text-center">
//           <input
//             type="reset"
//             value="Clear"
//             className="btn btn-primary m-3"
//             onClick={() => {
//               setOptionalSkills([]);
//               setRequiredSkills([]);
//             }}
//           />
//           <input
//             type="submit"
//             value="Submit"
//             className="btn btn-success ml-5"
//           />
//         </div>
//       </Form>
//     </Formik>
//   );
// };

// export default PostJobForm;
