
import React from "react";
import Footer from "./components/Footer/Footer";

const Layout = ({ children }) => (
  <div>
    {children}
    <Footer />
  </div>
);

export default Layout;
