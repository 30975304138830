import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Careers.css";

const JobListing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const filteredJobs = location.state ? location.state.filtered : null;
  const goBack = () => {
    navigate(-1); // This is equivalent to history.goBack()
  };
  return (
    <>
      <p onClick={goBack} className="p-2">
        <svg
          height="25px"
          id="Layer_1"
          style={{ enableBackground: "new 0 0 0 0" }}
          version="1.1"
          viewBox="0 0 512 512"
          width="25px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
        </svg>
        Back to search
      </p>

      <div>
        {filteredJobs && filteredJobs.length > 0 ? (
          <div className="p-3">
            <h4>
              Showing results 1-{filteredJobs.length} of {filteredJobs.length}{" "}
              results
            </h4>
            <hr />
            <br />
            {filteredJobs.map((job) => (
              <div key={job.jobNumber} className="joblisting">
                <h3>{job.jobTitle}</h3>
                <div>
                  <h5>Overview</h5>
                  <p>
                    {job.jobDescription.substring(0, 200)}...
                    <Link
                      to={`/careers/jobs/${job.jobNumber}`}
                      className="linkstyle"
                    >
                      See More
                    </Link>
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="fs-4 text-center">
            No jobs found modify your search and Try Again!!
          </p>
        )}
      </div>
    </>
  );
};

export default JobListing;
