import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = ({ showModal1, forgotModalClose }) => {
  const navigate = useNavigate();
  const [hello, setHello] = useState(true);
  const [loading, setLoading] = useState(false);
  const initialValues = { email: "" };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email()
      .matches(/@wamikatech\.com$/, "Email must end with @wamikatech.com")
      .required(),
  });

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);

    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/passwordReset/v1`, values)
        .then((res) => {
          setHello(false);
          resetForm();
          toast.success(res.data.message);
          setLoading(false);
          navigate("/");
        });
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };
  const close = () => {
    setHello(false);
    navigate("/");
  };

  return (
    <div>
      <Modal
        show={showModal1}
        onHide={
          //   () => {
          //   setHello(false);
          // }
          forgotModalClose
        }
        style={{ fontFamily: "Recharge" }}>
        <Modal.Header
          style={{
            backgroundImage: "linear-gradient(to right, skyblue , white)",
          }}
          closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Modal.Body>
                <Form.Group
                  controlId="email"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Form.Label>
                    <b>Email:</b>
                  </Form.Label>
                  <Field
                    type="email"
                    name="email"
                    className="form-control ml-2"
                    placeholder="Enter your mail id"
                    required
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"></ErrorMessage>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit" variant="success" disabled={loading}>
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
