import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FooterConfig from "../config/FooterConfig";
import "./Footer.css";
import { faLinkedin, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use 'auto' for instant scrolling
    });
  };
  return (
    <footer className="bg-dark text-light py-3 ">
      <div className="container">
        <div className="row">
          <div className="col">
            <ul className="list-unstyled">
              <li>
                <h4>{FooterConfig.orgName}</h4>
                <hr style={{ color: "red" }} />
              </li>
              <li>{FooterConfig.moto}</li>
              <li className="my-3">
                <Link to={FooterConfig.linkedin} className="mx-2 linkStyle">
                  <FontAwesomeIcon icon={faLinkedin} size="2x" />
                </Link>
                <Link to="#" className="mx-2 linkStyle">
                  <FontAwesomeIcon icon={faXTwitter} size="2x" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col ">
            <ul className="list-unstyled">
              <li>
                <h4>{FooterConfig.quickLinks}</h4>
                <hr style={{ color: "red" }} />
              </li>
              <li>
                <Link to="/" className="mx-3 linkStyle" onClick={scrollToTop}>
                  {FooterConfig.home}
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutus"
                  className="mx-3 linkStyle"
                  onClick={scrollToTop}>
                  {FooterConfig.about}
                </Link>
              </li>
              <li>
                <Link
                  to="/content"
                  className="mx-3 linkStyle"
                  onClick={scrollToTop}>
                  {FooterConfig.contact}
                </Link>
              </li>

              <li>
                <Link
                  to="/aboutus"
                  className="mx-3 linkStyle"
                  onClick={scrollToTop}>
                  {FooterConfig.achievements}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <ul className="list-unstyled">
              <li>
                <h4>{FooterConfig.addressHead} </h4>
                <hr style={{ color: "red" }} />
              </li>
              <li>
                <FontAwesomeIcon icon={faLocationDot} />

                <i>
                  {FooterConfig.addressLine1}
                  <br />
                  {FooterConfig.addressLine2}
                  <br />
                  {FooterConfig.addressLine3}
                </i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr style={{ color: "blue" }} />
      <div className="d-flex justify-content-center">
        ©2023 Wamika Technologies.All rights reserved
      </div>
    </footer>
  );
};

export default Footer;
