import React, { useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import "./login.css";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Reset = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useLocation();
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  // Access and parse query parameters here
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("e");
  const token = queryParams.get("t");

  const initialValues = {
    password: "",
    confirmPassword: "",
    email: "",
    token: "",
  };

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .max(20, "Password not more than 20 characters")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=]).*$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Must match password field value"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/reset/v1?E=${email}&T=${token}`,
        values
      );
      toast.success(response.data.message);
      setLoading(false);
      // alert("password resetted");
      setShowModal(false);
      navigate("/");
      resetForm();
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        style={{ fontFamily: "Recharge" }}>
        <Modal.Header
          style={{
            backgroundImage: "linear-gradient(to right, skyblue , white)",
          }}
          closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="password">
                  <Form.Label>Password:</Form.Label>
                  <div className="input-group custom-input-container ">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control custom-input"
                      placeholder="Enter Your Password"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="btn btn-outline-secondary">
                      {showPassword ? <Eye /> : <EyeSlash />}
                    </button>
                  </div>
                </Form.Group>

                <ErrorMessage
                  name="password"
                  component="div"
                  className="form-group text-danger"
                />
                <Form.Group controlId="confirmPassword">
                  <Form.Label>ConfirmPassword:</Form.Label>
                  <div className="input-group custom-input-container ">
                    <Field
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      className="form-control custom-input"
                      placeholder="Enter Your Password"
                      required
                    />
                    <button
                      type="button"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      className="btn btn-outline-secondary">
                      {showConfirmPassword ? <Eye /> : <EyeSlash />}
                    </button>
                  </div>
                </Form.Group>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="form-group text-danger"
                />
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-outline-primary">
                  Submit
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default Reset;
