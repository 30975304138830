import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { format, eachDayOfInterval, isWeekend } from "date-fns";
import { useSelector } from "react-redux";
import { Modal, Button, Alert, Badge } from "react-bootstrap";
import { BsInfoCircleFill } from "react-icons/bs";
import axios from "axios";
import "./empleave.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// const holidays = [
//   "2024-01-01",
//   "2024-01-15",
//   "2024-01-26",
//   "2024-03-25",
//   "2024-04-09",
//   "2024-04-11",
//   "2024-05-01",
//   "2024-08-15",
//   "2024-10-31",
//   "2024-12-25",
// ];

const LeaveApp = () => {
  const { userId, user } = useSelector((state) => state.login.userData || {});

  const [showLeaveInfoModal, setShowLeaveInfoModal] = useState(false);
  const [showHolidayModal, setShowHolidayModal] = useState(false);
  const [holidayCount, setHolidayCount] = useState(0);
  const [holidays, setHolidays] = useState([]);
  const [holidaysListView, setHolidaysListView] = useState([]);
  const [empLeaveData, setEmpLeaveData] = useState();
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/getEmpLeaveData`, { userId })
      .then((response1) => {
        setEmpLeaveData(response1.data);
      });
    axios
      .post(`${process.env.REACT_APP_API_URL}/getHolidaysData`, {
        currentYear,
      })
      .then((response2) => {
        setHolidays(response2.data.yearlyHolidays);
        setHolidaysListView(response2.data.holidaysList);
      });
  }, []);

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    userId: yup.string().required(),
    employeeId: yup.string().required(),
    email: yup.string().required(),
    manager: yup.string().required(),
    availableCl: yup.number().required(),
    availablePl: yup.number().required(),
    cl: yup.number().required("Casual Leave is a required field"),

    pl: yup.number().required("Paid Leave is a required field"),

    totalLeaveDays: yup.number().required(),
    leaveType: yup.string().required("Please select a leave type"),
    leaveReason: yup
      .string()
      .trim()
      .required("Leave Reason is a required field"),
    startDate: yup
      .date()
      .required("Start date is required")
      .test(
        "isNotWeekend",
        "Start date cannot be a weekend",
        (value) => !isWeekend(new Date(value))
      )
      .test(
        "isNotHoliday",
        "Start date cannot be a holiday",
        // (value) => !holidays.includes(value)
        (value) => !holidays.includes(format(new Date(value), "yyyy-MM-dd"))
      ),

    endDate: yup
      .date()
      .required("End date is required")
      .test(
        "isNotWeekend",
        "End date cannot be a weekend",
        (value) => !isWeekend(new Date(value))
      )
      .test(
        "isNotHoliday",
        "End date cannot be a holiday",
        // (value) => !holidays.includes(value)
        (value) => !holidays.includes(format(new Date(value), "yyyy-MM-dd"))
      ),

    numberOfDays: yup.number().test(
      "totalLeaveDays",
      // "Number of Days should match with Selected No Of Days",
      "Please select the correct end date by considering holidays and weekends",
      function (value) {
        const { cl, pl } = this.parent;
        const totalLeaveDays = Math.ceil(parseFloat(cl) + parseFloat(pl));

        return totalLeaveDays === value;
      }
    ),
  });

  const initialValues = {
    name: `${user.Main?.firstName || ""} ${user.Main?.lastName || ""}`,
    userId: `${user.Main?.userId || ""}`,
    employeeId: `${user.Main?.employeeId || ""}`,
    email: `${user.Main?.email || ""}`,
    manager: `${user?.managerData.name || ""}`,
    availableCl: empLeaveData ? empLeaveData.availableCl : 0,
    availablePl: empLeaveData ? empLeaveData.availablePl : 0,
    cl: 0,
    pl: 0,
    lop: 0,
    totalLeaveDays: 0,
    leaveType: "",
    leaveReason: "",
    startDate: "",
    endDate: "",
    numberOfDays: 0,
  };

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      // console.log(user.managerData.userId, user.managerData[0].userId);
      const managerData = {
        managerId: user.managerData.userId,
        managerEmail: user.managerData.email,
      };

      const postData = {
        ...values,
        ...managerData,
        userId: user.Main.userId,
        employeeId: user.Main.employeeId,
      };
      // if (values.cl > 0 || values.pl > 0) {
      const response = await axios.post(
        ` ${process.env.REACT_APP_API_URL}/leaverequestdata/v1`,
        postData
      );
      toast.success("Successfully Submitted");
      // } else {
      //   toast.info("Both CL and PL values cannot be 0");
      // }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
    resetForm();
  };
  // ==========================Logic=====================================
  const calculateHolidaysAndWeekends = async (start, end) => {
    return new Promise((resolve) => {
      const daysInBetween = eachDayOfInterval({
        start: start,
        end: end,
      });

      const holidaysInRange = daysInBetween.filter(
        (date) => holidays && holidays.includes(format(date, "yyyy-MM-dd"))
      );

      const weekendsInRange = daysInBetween.filter((date) => isWeekend(date));

      const totalHolidaysAndWeekends =
        holidaysInRange.length + weekendsInRange.length;

      setHolidayCount(totalHolidaysAndWeekends);

      resolve(totalHolidaysAndWeekends);
    });
  };

  const handleChange = (values, setFieldValue, fieldName) => {
    const { availableCl, availablePl, cl, pl } = values;
    const selectedValue = parseFloat(values[fieldName]);
    const availableLeave = fieldName === "cl" ? availableCl : availablePl;

    // const exceededLeave = selectedValue - availableLeave > 0;
    // Calculate the excess leave taken beyond the available leave
    const exceededLeave = Math.max(0, selectedValue - availableLeave);

    // Calculate total LOP by summing up excess leave from CL and PL
    const lop =
      fieldName === "cl"
        ? exceededLeave + Math.max(0, pl - availablePl)
        : Math.max(0, cl - availableCl) + exceededLeave;

    // Update LOP value in the form
    setFieldValue("lop", lop);

    if (exceededLeave) {
      const confirmation = window.confirm(
        `Selected ${fieldName.toUpperCase()} value exceeds the available leaves. Do you want to continue?`
      );

      if (!confirmation) {
        // If the user clicks "Cancel" (false), leave the value unchanged
        return;
      }
    }

    // If the user clicks "OK" or the value doesn't exceed the available leaves
    setFieldValue(fieldName, selectedValue);
    const totalLeaveDays = parseFloat(values.cl) + parseFloat(values.pl);
    setFieldValue("totalLeaveDays", totalLeaveDays);
  };

  const handleDateChange = async (
    startDate,
    endDate,
    totalLeaveDays,
    setFieldValue
  ) => {
    const leaveCount = await calculateHolidaysAndWeekends(startDate, endDate);
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (!isNaN(start) && !isNaN(end) && start <= end) {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(Math.abs((start - end) / oneDay) + 1);
      if (Math.ceil(totalLeaveDays) !== diffDays - leaveCount) {
        alert("please select correct range of dates");
      }
      setFieldValue("endDate", endDate);
      setFieldValue("numberOfDays", diffDays - leaveCount);
      // setFieldValue("startDate", startDate); // Reflect selected start date in the form
    } else {
      setFieldValue("numberOfDays", ""); // Reset the number of days if the date inputs are invalid
    }
  };
  const handleShowLeaveInfoModal = () => setShowLeaveInfoModal(true);
  const handleCloseLeaveInfoModal = () => setShowLeaveInfoModal(false);

  const handleShowHolidayList = () => setShowHolidayModal(true);
  const handleCloseHolidayList = () => setShowHolidayModal(false);

  // ==========================Logic=====================================
  return (
    <>
      {empLeaveData && holidaysListView && (
        <>
          <div className="Leaves custom leave-container">
            <h3 className="text-center leaveapp-header-custom custom-font">
              Leave Application
            </h3>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              // onReset={(values, { resetForm }) => {
              //   // resetForm();
              // }}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form className="leave-form">
                  <div className="position-relative mb-5">
                    {/* Info Label at Top Right Corner */}
                    <Badge
                      pill
                      variant="info"
                      className="position-absolute top-0 end-0 p-2 rounded"
                      onClick={handleShowLeaveInfoModal}
                      style={{ cursor: "pointer", fontSize: "0.8rem" }}
                    >
                      <BsInfoCircleFill className="me-1" />
                      Show Leave Information
                    </Badge>
                    <Badge
                      pill
                      variant="info"
                      className="position-absolute top-0 start-0 p-2 rounded"
                      onClick={handleShowHolidayList}
                      style={{ cursor: "pointer", fontSize: "0.8rem" }}
                    >
                      <BsInfoCircleFill className="me-1" />
                      Click to view Holidays info
                    </Badge>

                    {/* Leave Form Heading */}
                  </div>
                  <div className="row mb-1" hidden>
                    <div className="col-md-6">
                      <label htmlFor="name" className="labelfont">
                        Name:
                      </label>
                      <Field
                        type="text"
                        name="name"
                        readOnly
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="manager" className="labelfont">
                        Manager:
                      </label>
                      <Field
                        type="text"
                        name="manager"
                        readOnly
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="row mb-1" hidden>
                    <div className="col-md-6">
                      <label htmlFor="availableCl" className="labelfont">
                        Available CL:
                      </label>
                      <Field
                        type="number"
                        name="availableCl"
                        readOnly
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="availablePl" className="labelfont">
                        Available PL:
                      </label>
                      <Field
                        type="number"
                        name="availablePl"
                        readOnly
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="row mb-1">
                    <div className="col-md-6">
                      <label htmlFor="Cl" className="labelfont">
                        Select CL<span className="required-field">*</span>:
                      </label>
                      <Field
                        as="select"
                        name="cl"
                        // onChange={(e) =>
                        //   handleChange(
                        //     values.availableCl,
                        //     values.availablePl,
                        //     e.target.value,
                        //     values.pl,
                        //     setFieldValue
                        //   )
                        // }
                        onChange={(e) =>
                          handleChange(
                            { ...values, cl: e.target.value },
                            setFieldValue,
                            "cl"
                          )
                        }
                        className="form-control"
                      >
                        {Array.from({ length: 13 }, (_, index) => (
                          <option key={index} value={index / 2}>
                            {index / 2}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="pl" className="labelfont">
                        Select PL<span className="required-field">*</span>:
                      </label>
                      <Field
                        as="select"
                        name="pl"
                        // onChange={(e) =>
                        //   handleChange(
                        //     values.availableCl,
                        //     values.availablePl,
                        //     values.cl,
                        //     e.target.value,
                        //     setFieldValue
                        //   )
                        // }
                        onChange={(e) =>
                          handleChange(
                            { ...values, pl: e.target.value },
                            setFieldValue,
                            "pl"
                          )
                        }
                        className="form-control"
                      >
                        {Array.from({ length: 61 }, (_, index) => (
                          <option key={index} value={index / 2}>
                            {index / 2}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>

                  <div className="row mb-1" hidden>
                    <div className="col-md-6">
                      <label htmlFor="lop" className="labelfont">
                        LOP:
                      </label>
                      <Field
                        type="number"
                        name="lop"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="totalLeaveDays" className="labelfont">
                        No Of Days Selected:
                      </label>
                      <Field
                        type="number"
                        name="totalLeaveDays"
                        value={parseFloat(values.cl) + parseFloat(values.pl)}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="row mb-1">
                    <div className="col-md-6">
                      <label htmlFor="leaveType" className="labelfont">
                        Leave Type<span className="required-field">*</span>:
                      </label>
                      <Field
                        as="select"
                        name="leaveType"
                        id="leaveType"
                        className="form-control"
                      >
                        <option value="">Select Leave Type</option>
                        <option value="casualLeave">Casual Leave</option>
                        <option value="paidLeave">Paid Leave</option>
                        <option value="vacation">Vacation</option>
                        <option value="sick">Sick Leave</option>
                        <option value="personal">Personal Leave</option>
                      </Field>
                      {errors.leaveType && touched.leaveType && (
                        <div className="text-danger">{errors.leaveType}</div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="leaveReason" className="labelfont">
                        Leave Reason<span className="required-field">*</span>:
                      </label>
                      <Field
                        component="textarea"
                        name="leaveReason"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="leaveReason"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-md-6" hidden>
                      <label htmlFor="numberOfDays" className="labelfont">
                        No Of Days:
                      </label>
                      <Field
                        type="number"
                        name="numberOfDays"
                        readOnly
                        className="form-control"
                      />
                      {errors.numberOfDays && touched.numberOfDays && (
                        <div style={{ color: "red" }}>
                          {errors.numberOfDays}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mb-1">
                    <div className="col-md-6">
                      <label htmlFor="startDate" className="labelfont">
                        Start Date<span className="required-field">*</span>:
                      </label>
                      <Field
                        type="date"
                        name="startDate"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="startDate"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="endDate" className="labelfont">
                        End Date<span className="required-field">*</span>:
                      </label>
                      <Field
                        type="date"
                        name="endDate"
                        onChange={(e) =>
                          handleDateChange(
                            values.startDate,
                            e.target.value,
                            parseFloat(values.cl) + parseFloat(values.pl),
                            setFieldValue
                          )
                        }
                        className="form-control"
                      />
                      <ErrorMessage
                        name="endDate"
                        component="div"
                        className="text-danger"
                      />
                      {errors.numberOfDays && touched.numberOfDays && (
                        <div className="text-danger">{errors.numberOfDays}</div>
                      )}
                    </div>
                  </div>

                  {/* <div className="row mb-1 justify-content-center"> */}
                  <div className="text-center">
                    <button
                      type="reset"
                      className="btn btn-primary m-3"
                      disabled={loading}
                    >
                      Clear
                    </button>

                    <button
                      type="submit"
                      className="btn btn-success "
                      disabled={loading}
                    >
                      Submit
                    </button>
                  </div>
                  {/* </div> */}
                </Form>
              )}
            </Formik>
          </div>
          <div>
            <Modal show={showLeaveInfoModal} onHide={handleCloseLeaveInfoModal}>
              <Modal.Header closeButton>
                <Modal.Title>Leave Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Available CL: {empLeaveData.availableCl}</p>
                <p>Available PL: {empLeaveData.availablePl}</p>
                <p>Lop: {empLeaveData.lop}</p>
                {/* Add any other leave information here */}
              </Modal.Body>
            </Modal>
          </div>
          <div>
            <div className="col-md-4">
              <Modal show={showHolidayModal} onHide={handleCloseHolidayList}>
                <Modal.Header closeButton>
                  <Modal.Title>Holidays List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* <Embed url="https://wamikatechdev.blob.core.windows.net/devstorage/Holidays%20List%202024new.pdf" /> */}
                  {/* "https://wamikatechdev.blob.core.windows.net/devstorage/Holidays
                  List 2024new.pdf" */}
                  {holidaysListView && holidaysListView.length > 0 ? (
                    <table className="table-container">
                      <thead>
                        <th>Date</th>
                        <th>Day</th>
                        <th>Occasion</th>
                      </thead>
                      <tbody>
                        {holidaysListView.map((object) => (
                          <tr>
                            <td>{object.date}</td>
                            <td>{object.day}</td>
                            <td>{object.occasion}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No data found</p>
                  )}
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LeaveApp;
