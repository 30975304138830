import React, { useEffect, useState } from "react";
import axios from "axios";
import { Field, ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { setTrendsData } from "./reportsSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object().shape({
  reportType: Yup.string().required("Report Type is required"),
  project: Yup.string().required("Project is required"),
});

function TrendsReportGeneration() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fetchProjects`
        );
        setProjects(response.data.data);
        // console.log(response.data); // Assuming you want to log the response data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedProject && (monthlyData.length > 0 || yearlyData.length > 0)) {
      dispatch(
        setTrendsData({
          selectedProject,
          monthlyData,
          yearlyData,
          // Assuming you have a default value for reportType
          reportType: monthlyData.length > 0 ? "monthly" : "yearly",
        })
      );
      navigate("/wamikaemployees/trendReportGeneration");
    }
  }, [dispatch, navigate, selectedProject, monthlyData, yearlyData]);

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/gettingDurationsData`,
        values
      );
      if (
        response.data.success &&
        response.data.Data &&
        response.data.Data.length === 0
      ) {
        toast.info("No data found with the selected project");
      } else {
        if (values.reportType === "monthly") {
          setMonthlyData(response.data.Data[0].monthlyData);
        } else if (values.reportType === "yearly") {
          setYearlyData(response.data.Data[0].yearlyData);
        }
      }
      const newSelectedProject = {
        projectCode: values.project,
        projectName: projects.find(
          (project) => project.projectCode === values.project
        )?.projectName,
      };
      setSelectedProject(newSelectedProject);
    } catch (error) {
      console.log("Error retrieving data:", error);
    }
  };

  return (
    <div>
      {projects && (
        <Formik
          initialValues={{
            reportType: "",
            project: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ handleSubmit, handleReset }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}>
              <div className="mb-2 col-md-6">
                <label htmlFor="reportType" className="col-form-label col-md-3">
                  <b>
                    Report Type<span className="required-field">*</span> :
                  </b>
                </label>
                <Field
                  as="select"
                  name="reportType"
                  id="reportType"
                  className="form-control">
                  <option value="">Select Report Type</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </Field>
                <ErrorMessage
                  name="reportType"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>

              <div className="mb-2 col-md-6">
                <label htmlFor="project" className="col-form-label col-md-3">
                  <b>
                    Project<span className="required-field">*</span> :
                  </b>
                </label>
                <Field
                  as="select"
                  name="project"
                  id="project"
                  className="form-control">
                  <option value="">Select Project</option>
                  {projects.map((project) => (
                    <option
                      key={project.projectCode}
                      value={project.projectCode}>
                      {project.projectName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="project"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>

              <button
                type="reset"
                onClick={handleReset}
                className="m-2 btn btn-danger">
                Clear
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          )}
        </Formik>
      )}
      {/* <h1>Generate Trends</h1> */}
    </div>
  );
}

export default TrendsReportGeneration;
