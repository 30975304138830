const NavConfig = {
  orgName1: "Wamika",
  orgName2: "Technologies",
  home: "Home",
  about: "About Us",
  contact: "Contact Us",
  registration: "Registration",
  login: "Login",
  careers: "Careers",
};

export default NavConfig;
