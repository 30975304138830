import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import "./TimeSheet.css";
// import ProjectModal from "./ProjectModal";
import staticValues from "../../../../config/staticValues";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RevokeTimesheet = () => {
  const userId = useSelector((state) => state.login.userData.userId);
  //   console.log(userId);
  const [employeeDetails, setEmployeeDetails] = useState();
  //   const [timeSheetSubmitDetails, setTimeSheetSubmitDetails] = useState();
  //   const [showModal, setShowModal] = useState(false);
  async function apicall() {
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/manager/timesheetReqs/v1`, {
          userId,
        })
        .then((res) => {
          // console.log(res.data.data.revokedRequests);
          setEmployeeDetails(res.data.data.revokedRequests);
          //   setTimeSheetSubmitDetails(res.data.data.approvalRequests);
          //   console.log(res.data.data.approvalRequests);
        });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    apicall();
  }, []);

  //========================modal related funcs=============================
  //   const handleButtonClick = () => {
  //     setShowModal(true);
  //   };

  //   const handleCloseModal = () => {
  //     setShowModal(false);
  //   };
  //========================modal related funcs=============================

  const accept = async (item) => {
    try {
      // console.log(item);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/manager/revokestatusaccept/v1`,
          { item, userId }
        )
        .then((res) => {
          toast.success("Accepted");
          // window.location.reload();
          apicall();
          // console.log(res.data);
        });
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };
  const reject = async (item) => {
    try {
      // console.log(item);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/manager/revokestatusreject/v1`,
          { item, userId }
        )
        .then((res) => {
          toast.success("Rejected");
          // window.location.reload();
          apicall();

          // console.log(res.data);
        });
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };

  //   const accepted = async (item) => {
  //     try {
  //       axios
  //         .post(`${process.env.REACT_APP_API_URL}/manager/timesheetaccepted/v1`, {
  //           userId,
  //           item,
  //         })
  //         .then((res) => {
  //           toast.success("Accepted");
  //           window.location.reload();
  //           // console.log(res.data);
  //         });
  //     } catch (error) {
  //       toast.error(error.message);
  //       console.log(error.message);
  //     }
  //   };
  //   const rejected = async (item) => {
  //     try {
  //       axios
  //         .post(`${process.env.REACT_APP_API_URL}/manager/timesheetrejected/v1`, {
  //           userId,
  //           item,
  //         })
  //         .then((res) => {
  //           toast.success("Rejected");
  //           window.location.reload();
  //           // console.log(res.data);
  //         });
  //     } catch (error) {
  //       toast.error(error.message);
  //       console.log(error.message);
  //     }
  //   };
  return (
    <div className="">
      {/* <div> */}
      <h4 className="text-center">Revoke Reqs</h4>
      <hr />
      {/* <div className="container">
          <div className="row"> */}
      <Table className="table table-striped">
        <thead>
          <th>EmployeeName</th>
          <th>fromDate</th>
          <th>toDate</th>
        </thead>
        <tbody>
          {employeeDetails &&
            employeeDetails
              .filter((item) => item.status === staticValues.statusPending)
              .map((filteredItem, index) => (
                <tr key={index} className="align-bottom">
                  <td>{filteredItem.empName}</td>
                  <td>
                    {filteredItem.revokedRequestsDates[1].substring(0, 10)}
                  </td>
                  <td>
                    {filteredItem.revokedRequestsDates[5].substring(0, 10)}
                  </td>
                  <td>
                    <button
                      className="btn btn-outline-success mx-1"
                      onClick={() => accept(filteredItem)}
                    >
                      Approve
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-outline-danger mx-1"
                      onClick={() => reject(filteredItem)}
                    >
                      Reject
                    </button>
                  </td>
                </tr>
                //   <div key={index} className="card custom-card m-1 p-2 rounded">
                //     <div className="card-body">
                //       <h5 className="card-title">
                //         EmpName: {filteredItem.empName}
                //       </h5>
                //       <p className="card-text">
                //         <span className="fw-bold">From:</span>{" "}
                //         {filteredItem.revokedRequestsDates[1].substring(0, 10)}{" "}
                //         <span className="fw-bold">To:</span>{" "}
                //         {filteredItem.revokedRequestsDates[5].substring(0, 10)}
                //       </p>
                //       <div className="d-flex justify-content-center">
                //         <button
                //           className="btn btn-outline-success mx-1"
                //           onClick={() => accept(filteredItem)}>
                //           Approve
                //         </button>
                //         <button
                //           className="btn btn-outline-danger mx-1"
                //           onClick={() => reject(filteredItem)}>
                //           Reject
                //         </button>
                //       </div>
                //     </div>
                //   </div>
              ))}
        </tbody>
      </Table>
      {/* </div>
        </div> */}
      {/* </div> */}
      {/* <div>
        <h4>Approval Reqs:</h4>
        {timeSheetSubmitDetails &&
          timeSheetSubmitDetails
            .filter((item) => item.status === staticValues.statusSubmit)
            .map((filtereditems, index) => (
              <div key={index} className="custom-color m-1 p-2 rounded">
                <div className="my-3">
                  <b>EmpName:</b>
                  <i className="px-2">{filtereditems.empName}</i>
                  <br />
                  From:
                  <b>{filtereditems.dates[0]}</b> To:
                  <b>{filtereditems.dates[4]}</b>
                  <br />
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-outline-success mx-1"
                    onClick={() => accepted(filtereditems)}>
                    Approve
                  </button>
                  <button
                    className="btn btn-outline-danger mx-1"
                    onClick={() => rejected(filtereditems)}>
                    Reject
                  </button>
                  <button
                    className="btn btn-outline-primary"
                    onClick={handleButtonClick}>
                    View
                  </button>

                  <ProjectModal
                    project={filtereditems}
                    handleCloseModal={handleCloseModal}
                    showModal={showModal}
                  />
                </div>
              </div>
            ))}
      </div> */}
    </div>
  );
};

export default RevokeTimesheet;
