import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { setIdInfo } from "./jobDataSlice";

const JobDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jobPostingsData = useSelector((state) => state.jobData.jobPostingsData);
  const handleApplyClick = (jobId, jobPostedBy) => {
    dispatch(setIdInfo({ jobId: jobId, jobPostedBy: jobPostedBy }));
    navigate("/apply");
  };
  const goBack = () => {
    navigate(-1); // This is equivalent to history.goBack()
  };

  const { id } = useParams();
  const job = jobPostingsData.find(
    (job) => parseInt(job.jobNumber) === parseInt(id)
  );
  // const requiredSkills = [];
  // job.requiredJobSkills.map((skill) => requiredSkills.push(skill.label));
  const requiredSkills = job.requiredSkills?.map((skill) => skill.label);

  const optionalSkills = job.optionalSkills?.map((skill) => skill.label) || [];

  const timestamp = job.datePosted;
  const date = new Date(timestamp);

  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  // Output: Dec 20, 2023 (format)

  if (!job) {
    return <div>Job not found</div>;
  }

  return (
    <>
      <p onClick={goBack} className="p-2">
        <svg
          height="25px"
          id="Layer_1"
          style={{ enableBackground: "new 0 0 0 0" }}
          version="1.1"
          viewBox="0 0 512 512"
          width="25px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
        </svg>
        Go Back
      </p>

      <div className="job-details p-3">
        <h2>{job.jobTitle}</h2>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            viewBox="0 0 512 512"
          >
            <path
              opacity="0.8"
              d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
            />
          </svg>
          {formattedDate}
        </span>
        {"  "}
        <span>
          <svg
            className="icons"
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="12"
            viewBox="0 0 384 512"
          >
            <path
              opacity="0.8"
              d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
            />
          </svg>
          {job.location}
        </span>
        {"  "}
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            viewBox="0 0 512 512"
          >
            <path
              opacity="0.8"
              d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"
            />
          </svg>
          {job.workType}
        </span>
        <br />
        <button
          className="btn btn-primary"
          onClick={() => handleApplyClick(job.jobId, job.jobPostedBy)}
        >
          Apply
        </button>
        <div>
          <h5>Description :</h5>
          <p>{job.jobDescription}</p>
        </div>
        <p>
          <b>Skills required</b> :{" "}
          <ul>
            {requiredSkills.map((list) => (
              <li>{list}</li>
            ))}
            {optionalSkills
              ? optionalSkills.map((list) => <li>{list}(optional)</li>)
              : null}
          </ul>
        </p>
        <div>
          <h5>Qualifications :</h5>
          {job.qualifications.map((item) => (
            <p>{item}</p>
          ))}
        </div>
        <div>
          <h5>Responsibilities :</h5>
          {job.responsibilities.map((item) => (
            <p>{item}</p>
          ))}
        </div>
        <button
          className="btn btn-primary"
          onClick={() => handleApplyClick(job.jobId, job.jobPostedBy)}
        >
          Apply
        </button>
      </div>
    </>
  );
};

export default JobDetails;
