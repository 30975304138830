import "./App.css";
import "@fortawesome/fontawesome-svg-core/styles.css";

import Navbars from "./components/Header/Navbar";
import ContactUs from "./components/Content/ContactUs";
import AboutUs from "./components/Content/AboutUs";
import Home from "./components/Body/Home";
import Layout from "./AppLayout";
import Footer from "./components/Footer/Footer";

// ==========================PopUp  Setup ===================================
// import ToastContainer from "./ToastContainer";
import { ToastContainer } from "react-toastify";
// ==========================PopUp  Setup ===================================

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// =========================Routing Components =========================================
// ====================================Careers=======================================
import Careers from "./components/Content/Careers/Careers";
import JobListing from "./components/Content/Careers/JobListing";
import JobDetails from "./components/Content/Careers/JobDetails";
import JobApplicationForm from "./components/Content/Careers/JobApplicationForm";
// ====================================Careers=======================================

// =================================Login=========================================
import Signup from "./components/registration/Signup";
import ForgotPassword from "./components/login/ForgotPassword";
import Reset from "./components/login/Reset";
import ProtectedRoute from "./components/login/protectedRoute/ProtectedRoute";
// =================================Login========================================

// ====================================Leave Components==============================
import LeaveAppLayout from "./components/login/userprofile/leaveDashboard/LeaveAppLayout";
import FetchLeaveApplications from "./components/login/userprofile/leaveDashboard/employee/FetchLeaveApplications";
import LeaveApp from "./components/login/userprofile/leaveDashboard/employee/LeaveApp";
// import LeaveForm from "./components/login/userprofile/leaveDashboard/employee/NewLeaveApp";
import Approved from "./components/login/userprofile/leaveDashboard/managerRequests/Approved";
import Pending from "./components/login/userprofile/leaveDashboard/managerRequests/Pending";
import Rejected from "./components/login/userprofile/leaveDashboard/managerRequests/Rejected";
import HRDocs from "./components/login/userprofile/leaveDashboard/documents/HRDocs";
import PostJobForm from "./components/login/userprofile/leaveDashboard/postJobs/PostJobForm";
import GetPostedJobs from "./components/login/userprofile/leaveDashboard/postJobs/GetPostedJobs";
import ApplicationsReceived from "./components/login/userprofile/leaveDashboard/postJobs/ApplicationsReceived";
// import TimeSheet from "./components/login/userprofile/leaveDashboard/timesheet/TimeSheet";
import TimeSheet from "./components/login/userprofile/leaveDashboard/timesheet/TimeSheetEntry";
import ManagerDashBoard from "./components/login/userprofile/leaveDashboard/timesheet/ManagerDashBoard";
import RevokeTimesheet from "./components/login/userprofile/leaveDashboard/timesheet/RevokeTimesheet";
// import TimeSheet from "./components/login/userprofile/leaveDashboard/timesheet/Calendar2";
import ReportGeneration from "./components/login/userprofile/leaveDashboard/timesheetReport/ReportGeneration";
import TrendsGeneration from "./components/login/userprofile/leaveDashboard/timesheetReport/GeneratedTrendsReport";
import TabForms from "./components/login/userprofile/leaveDashboard/timesheetReport/TabView";
// ====================================Leave Components==============================

// ====================================Profile=======================================
import Payslip from "./components/login/userprofile/profile/payslip/Payslip";
import DashboardLayout from "./components/login/userprofile/profile/DashBoardLayout";
import EmpDetails from "./components/login/userprofile/profile/empDetails/EmpDetails";
import DocsFetch from "./components/login/userprofile/profile/empDocuments/DocsFetch";
import EmpAccountInfo from "./components/login/userprofile/profile/empAccoutInfo/EmpAccountInfo";
import StatusRevoke from "./components/login/userprofile/leaveDashboard/managerRequests/StatusRevoke";
import EmpProjectAssign from "./components/login/userprofile/leaveDashboard/projectAssign/EmpProjectAssign";
import ProjectCreation from "./components/login/userprofile/leaveDashboard/projectAssign/ProjectCreation";
import EduDocs from "./components/login/userprofile/profile/empDocuments/EduDocs";
import EmployeesProfiles from "./components/login/userprofile/leaveDashboard/Hrcomp.js/EmployeesProfiles";

// ===================================Profile==========================================

// =========================Routing Components =========================================

// =========================Redux Toolkit===============================================
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./components/store/store";
// import UploadHRDocs from "./components/login/userprofile/leaveDashboard/documents/UploadHRDocs";
// import FileUpload from "../src/components/login/userprofile/leaveDashboard/documents/UploadHRDocs";
const persistor = persistStore(store);

// =========================Redux Toolkit===============================================

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div>
            <div>
              <Navbars></Navbars>

              {/*  ==========================PopUp  Setup =================================== */}
              <ToastContainer
                position={"top-center"}
                // pauseOnHover={true}
                closeOnClick={true}
                draggable={true}
                toastStyle={{ width: 500, height: 80 }}
                closeButton={false}
                autoClose={2000}
              />

              {/*  ==========================PopUp  Setup =================================== */}
            </div>

            <div className="customstyle ">
              <Routes>
                <Route path="/" element={<Layout><Home /></Layout>} />
                <Route path="/content" element={<Layout><ContactUs /></Layout>} />
                <Route path="/aboutus" element={<Layout><AboutUs /></Layout>} />
                {/* <Route  path="/"><Layout><Home /></Layout></Route>
                <Route  path="/content"><Layout><ContactUs /></Layout></Route>
                <Route  path="/aboutus"><Layout><AboutUs /></Layout></Route> */}

                {/*<Route path="/" element={<Home />} />
                 <Route path="/content" element={<ContactUs />} />
                <Route path="/aboutus" element={<AboutUs />} /> */}
                <Route
                  path="/registration"
                  element={<ProtectedRoute Component={Signup} />}
                />
                {/* <Route path="/forgotpassword" Component={ForgotPassword} /> */}
                <Route path="/resetpassword" Component={Reset} />
                <Route
                  path="/postjob"
                  element={<ProtectedRoute Component={PostJobForm} />}
                />
                <Route
                  path="/getuserpostedjobs"
                  element={<ProtectedRoute Component={GetPostedJobs} />}
                />
                <Route
                  path="/applications/:jobId"
                  element={<ProtectedRoute Component={ApplicationsReceived} />}
                />
                <Route path="/apply" Component={JobApplicationForm} />
                <Route path="/careers" Component={Careers} />
                <Route path="/careers/jobs" element={<JobListing />} />
                <Route path="/careers/jobs/:id" element={<JobDetails />} />

                {/* ============NESTED=================== */}
                <Route
                  exact
                  path="/profiledashboard"
                  element={<ProtectedRoute Component={DashboardLayout} />}
                >
                  <Route
                    path="empDetails"
                    element={<ProtectedRoute Component={EmpDetails} />}
                  />

                  <Route
                    path="documents"
                    element={<ProtectedRoute Component={EduDocs} />}
                  />

                  <Route
                    path="empfinancialInfo"
                    element={<ProtectedRoute Component={EmpAccountInfo} />}
                  />

                  <Route
                    path="payslip"
                    element={<ProtectedRoute Component={Payslip} />}
                  />
                </Route>
                {/* ============NESTED=================== */}

                {/* ============LeaveNested============== */}
                <Route
                  exact
                  path="/wamikaemployees"
                  element={<ProtectedRoute Component={LeaveAppLayout} />}
                >
                  <Route
                    path="timesheet"
                    element={<ProtectedRoute Component={TimeSheet} />}
                  />
                  <Route
                    path="timesheetApprovals"
                    element={<ProtectedRoute Component={ManagerDashBoard} />}
                  />
                  <Route
                    path="timesheetRevokeReqs"
                    element={<ProtectedRoute Component={RevokeTimesheet} />}
                  />
                  <Route
                    exact
                    path="hrDocs"
                    element={<ProtectedRoute Component={HRDocs} />}
                  />
                  <Route
                    path="previousRequests"
                    element={
                      <ProtectedRoute Component={FetchLeaveApplications} />
                    }
                  />

                  <Route
                    path="employeeLeaveRequests"
                    element={<ProtectedRoute Component={LeaveApp} />}
                  />
                  <Route
                    path="approvedRequests"
                    element={<ProtectedRoute Component={Approved} />}
                  />
                  <Route
                    path="rejectedRequests"
                    element={<ProtectedRoute Component={Rejected} />}
                  />
                  <Route
                    path="pendingRequests"
                    element={<ProtectedRoute Component={Pending} />}
                  />
                  <Route
                    path="postjob"
                    element={<ProtectedRoute Component={PostJobForm} />}
                  />
                  <Route
                    path="getpostedjobs"
                    element={<ProtectedRoute Component={GetPostedJobs} />}
                  />
                  <Route
                    path="applications/:jobId"
                    element={
                      <ProtectedRoute Component={ApplicationsReceived} />
                    }
                  />
                  <Route
                    path="statusrevoke"
                    element={<ProtectedRoute Component={StatusRevoke} />}
                  />
                  <Route
                    path="generateReport"
                    element={<ProtectedRoute Component={TabForms} />}
                  />
                  <Route
                    path="trendReportGeneration"
                    element={<ProtectedRoute Component={TrendsGeneration} />}
                  />
                  <Route
                    path="projectassign"
                    element={<ProtectedRoute Component={EmpProjectAssign} />}
                  />
                  <Route
                    path="projectcreation"
                    element={<ProtectedRoute Component={ProjectCreation} />}
                  />
                  <Route
                    path="getemployeeslist"
                    element={<ProtectedRoute Component={EmployeesProfiles} />}
                  />
                </Route>
                {/* ============LeaveNested============== */}

                <Route exact path="/*" element={<Home />} />
              </Routes>
            </div>
            {/* <div>
              <Footer />
            </div> */}
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
