import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialValues = {
  fullName: "",
  phone: "",
  email: "",
  relevantExperience: "",
  totalExperience: "",
  currentCTC: "",
  expectedCTC: "",
  resume: null,
};
const validationSchema = Yup.object({
  fullName: Yup.string().trim().required("Full Name is required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Invalid phone number format")
    .required("Phone is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  relevantExperience: Yup.number()
    .positive("Relevant experience must be a positive number")
    .max(30, "Relevant experience cannot exceed 30") // Set your desired maximum limit
    .required("Relevant experience is required"),
  totalExperience: Yup.number()
    .positive("Relevant experience must be a positive number")

    .max(30, "Relevant experience cannot exceed 30") // Set your desired maximum limit
    .required("Relevant experience is required"),
  currentCTC: Yup.string()
    .optional()
    .matches(/^[0-9]+$/, "Must be a Numeric Value")
    .min(5, "CTC must contain atleast 5 characters")
    .max(10, "CTC must contain atmost 10 characters"),
  expectedCTC: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be a Numeric Value")
    .min(5, "CTC must contain atleast 5 characters")
    .max(10, "CTC must contain atmost 10 characters"),
  resume: Yup.mixed()
    .required("Resume file is required")
    .test(
      "fileSize",
      "File size must be less than 500 KB",
      (value) => value && value.size <= 500 * 1024 // 500 KB in bytes
    )
    .test(
      "fileType",
      "Only PDF files are allowed",
      (value) => value && value.type === "application/pdf"
    ),
});

const JobApplicationForm = () => {
  const navigate = useNavigate();
  const selectedJobId = useSelector((state) => state.jobData.idInfo.jobId);
  const selectedJobPostedBy = useSelector(
    (state) => state.jobData.idInfo.jobPostedBy
  );

  const goBack = () => {
    navigate(-1); // This is equivalent to history.goBack()
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("fullName", values.fullName);
      formData.append("phone", values.phone);
      formData.append("email", values.email);
      formData.append("relevantExperience", values.relevantExperience);
      formData.append("totalExperience", values.totalExperience);
      formData.append("currentCTC", values.currentCTC);
      formData.append("expectedCTC", values.expectedCTC);
      formData.append("resume", values.resume);
      formData.append("jobId", selectedJobId);
      formData.append("jobPostedBy", selectedJobPostedBy);

      try {
        // console.log(formData, values);
        // Send the form data to the backend
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/uploadResume/v1`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();

          resetForm();
        } else {
          console.error("Application sent failed. Please try again");
        }
        toast.success("Application Submitted Successfully");
      } catch (error) {
        toast.error(error.message);
        console.error("Error during file upload:", error);
      }
    },
  });
  const handleClear = () => {
    formik.resetForm();
  };
  return (
    <>
      <p onClick={goBack} className="p-2">
        <svg
          height="25px"
          id="Layer_1"
          style={{ enableBackground: "new 0 0 0 0" }}
          version="1.1"
          viewBox="0 0 512 512"
          width="25px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink">
          <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
        </svg>
        Go Back
      </p>

      <div className="container">
        <h2 className="text-center mb-4" style={{ color: "purple" }}>
          Application Form
        </h2>
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <div className="row mb-3">
            <div className="col-md-6 ">
              <label htmlFor="fullName" className="col-md-4 fw-bold">
                Full Name<span className="required-field">*</span> :
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.fullName && formik.errors.fullName ? (
                <div style={{ color: "red" }}>{formik.errors.fullName}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <label htmlFor="phone" className="col-md-4 fw-bold">
                Phone<span className="required-field">*</span> :
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div style={{ color: "red" }}>{formik.errors.phone}</div>
              ) : null}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="email" className="col-md-4 fw-bold">
                Email<span className="required-field">*</span> :
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div style={{ color: "red" }}>{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <label htmlFor="relevantExperience" className="col-md-4 fw-bold">
                Relevant Experience<span className="required-field">*</span> :
              </label>
              <input
                type="text"
                id="relevantExperience"
                name="relevantExperience"
                value={formik.values.relevantExperience}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.relevantExperience &&
              formik.errors.relevantExperience ? (
                <div style={{ color: "red" }}>
                  {formik.errors.relevantExperience}
                </div>
              ) : null}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="totalExperience" className="col-md-4 fw-bold">
                Total Experience<span className="required-field">*</span> :
              </label>
              <input
                type="text"
                id="totalExperience"
                name="totalExperience"
                value={formik.values.totalExperience}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.totalExperience &&
              formik.errors.totalExperience ? (
                <div style={{ color: "red" }}>
                  {formik.errors.totalExperience}
                </div>
              ) : null}
            </div>
            <div className="col-md-6">
              <label htmlFor="currentCTC" className="col-md-4 fw-bold">
                Current CTC :
              </label>
              <input
                type="text"
                id="currentCTC"
                name="currentCTC"
                value={formik.values.currentCTC}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {/* {formik.touched.currentCTC && formik.errors.currentCTC ? (
                <div style={{ color: "red" }}>{formik.errors.currentCTC}</div>
              ) : null} */}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="expectedCTC" className="col-md-4 fw-bold">
                Expected CTC<span className="required-field">*</span> :
              </label>
              <input
                type="text"
                id="expectedCTC"
                name="expectedCTC"
                value={formik.values.expectedCTC}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.expectedCTC && formik.errors.expectedCTC ? (
                <div style={{ color: "red" }}>{formik.errors.expectedCTC}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <label htmlFor="resume" className="col-md-4 fw-bold">
                Resume<span className="required-field">*</span> :
              </label>
              <input
                type="file"
                accept=".pdf"
                id="resume"
                name="resume"
                onChange={(event) =>
                  formik.setFieldValue("resume", event.currentTarget.files[0])
                }
                onBlur={formik.handleBlur}
              />
              {formik.touched.resume && formik.errors.resume ? (
                <div style={{ color: "red" }}>{formik.errors.resume}</div>
              ) : null}
            </div>
          </div>
          <div className="text-center">
            <button
              type="reset"
              className="btn btn-secondary fw-bold m-3"
              onClick={handleClear}>
              Clear
            </button>
            <button type="submit" className="btn btn-primary fw-bold">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default JobApplicationForm;
// import React, { useState } from "react";

// const FormComponent = () => {
//   const [formData, setFormData] = useState({
//     fullname: "",
//     email: "",
//     contact: "",
//     currentCTC: "",
//     expectedCTC: "",
//     relevantexperience: "",
//     totalExperience: "",
//     resume: null,
//   });
//   const [errors, setErrors] = useState({});

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleFileChange = (e) => {
//     setFormData({
//       ...formData,
//       resume: e.target.files[0],
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const validationErrors = {};
//     // Basic validation
//     if (!formData.fullname.trim()) {
//       validationErrors.fullname = "Full name is required";
//     }
//     if (!formData.email.trim()) {
//       validationErrors.email = "Email is required";
//     }
//     // Add more validation for other fields if needed

//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }

//     // Submit form data along with resume file to backend
//     const formDataToSend = new FormData();
//     Object.entries(formData).forEach(([key, value]) => {
//       formDataToSend.append(key, value);
//     });

//     fetch(`http://localhost:3001/api/submit-form`, {
//       method: "POST",
//       body: formDataToSend,
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log("Form submitted successfully:", data);
//         // Reset form state
//         setFormData({
//           fullname: "",
//           email: "",
//           contact: "",
//           currentCTC: "",
//           expectedCTC: "",
//           relevantexperience: "",
//           totalExperience: "",
//           resume: null,
//         });
//         setErrors({});
//       })
//       .catch((error) => {
//         console.error("Error submitting form:", error);
//       });
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <label>Full Name:</label>
//         <input
//           type="text"
//           name="fullname"
//           value={formData.fullname}
//           onChange={handleChange}
//         />
//         {errors.fullname && <span>{errors.fullname}</span>}
//       </div>
//       <div>
//         <label>Email:</label>
//         <input
//           type="email"
//           name="email"
//           value={formData.email}
//           onChange={handleChange}
//         />
//         {errors.email && <span>{errors.email}</span>}
//       </div>
//       <div>
//         <label>Contact:</label>
//         <input
//           type="text"
//           name="contact"
//           value={formData.contact}
//           onChange={handleChange}
//         />
//       </div>
//       <div>
//         <label>Current CTC:</label>
//         <input
//           type="text"
//           name="currentCTC"
//           value={formData.currentCTC}
//           onChange={handleChange}
//         />
//       </div>
//       <div>
//         <label>Expected CTC:</label>
//         <input
//           type="text"
//           name="expectedCTC"
//           value={formData.expectedCTC}
//           onChange={handleChange}
//         />
//       </div>
//       <div>
//         <label>Relevant Experience:</label>
//         <input
//           type="text"
//           name="relevantexperience"
//           value={formData.relevantexperience}
//           onChange={handleChange}
//         />
//       </div>
//       <div>
//         <label>Total Experience:</label>
//         <input
//           type="text"
//           name="totalExperience"
//           value={formData.totalExperience}
//           onChange={handleChange}
//         />
//       </div>
//       <div>
//         <label>Resume:</label>
//         <input type="file" onChange={handleFileChange} />
//       </div>
//       <button type="submit">Submit</button>
//     </form>
//   );
// };

// export default FormComponent;
