// import React, { useEffect, useState } from "react";
// import { Formik, Field, Form } from "formik";
// import axios from "axios";
// // import "./style.css";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// const PendingLeaves = () => {
//   const navigate = useNavigate();
//   const [approvalData, setApprovalData] = useState([]);
//   // const userId = localStorage.getItem("UserId");
//   const userId = useSelector((state) => state.login.userData.userId);
//   const { user } = useSelector((state) => state.login.userData);
//   const status = "Pending";
//   const managerValues = { userId, status }; //for fetching requests based on managerId
//   console.log(managerValues, user.email);

//   useEffect(() => {
//     axios
//       .post(`${process.env.REACT_APP_API_URL}/pendingRequests`, managerValues)
//       .then((response) => {
//         setApprovalData(response.data);
//         // console.log(response.data);
//       });
//   }, []);

//   const handleInputChange = (userId, setFieldValue) => (event) => {
//     const newValue = event.target.value;
//     // setFieldValue(userId, newValue);
//     setFieldValue("comments", newValue);
//   };

//   const reject = async (values) => {
//     // console.log(values);
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/v1/rejectLeaveRequest`,
//         {
//           userId: values.userId,
//           name: values.name,
//           email: values.email,
//           comments: values.comments,
//           status: "Rejected",
//         }
//       );
//       // console.log("Response from the backend:", response.data);
//       // navigate("/");
//       window.location.reload();
//     } catch (error) {
//       console.error("Error sending data to the backend:", error);
//     }
//   };

//   const handleAccept = async (values) => {
//     // console.log("User ID: " + userId + " - Value: " + values.comments);

//     try {
//       const managerResponse = {
//         status: "Accepted",
//       };
//       const postData = { ...values, ...managerResponse };
//       console.log(postData, values);
//       const response = await axios.post(
//         ` ${process.env.REACT_APP_API_URL}/v1/updateLeaveRequestStatus`,
//         postData
//       );
//       console.log("Response from the backend:", response.data);
//       // navigate("/");
//       window.location.reload();
//     } catch (error) {
//       console.error("Error sending data to the backend:", error);
//     }
//   };
//   const handleSubmit = async (values) => {
//     // console.log("Submitting form data:", values);
//   };

//   return (
//     <div className="Leaves" style={{ fontFamily: "Recharge" }}>
//       <h4 className="bg-purple text-dark text-center p-3 rounded">
//         Requests Pending for Approval - ({approvalData.length})
//       </h4>

//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-evenly",
//           flexWrap: "wrap",
//         }}
//       >
//         {approvalData.map((request) => (
//           <div className="leave-request" key={request.userId}>
//             <Formik
//               initialValues={{
//                 userId: request.userId,
//                 name: request.name,
//                 employeeId: request.employeeId,
//                 availableCl: request.availableCl,
//                 availablePl: request.availablePl,
//                 email: request.email,
//                 cl: request.cl,
//                 pl: request.pl,
//                 lop: request.lop,
//                 totalLeaveDays: request.totalLeaveDays,
//                 leaveReason: request.leaveReason,
//                 startDate: request.startDate,
//                 endDate: request.endDate,
//                 comments: "",
//               }}
//               onSubmit={handleSubmit}
//             >
//               {({ setFieldValue, handleSubmit, values }) => (
//                 <Form>
//                   <div hidden>
//                     <label htmlFor="userId">UserId:</label>
//                     <Field type="text" name="userId" readOnly />
//                   </div>
//                   <div className="d-flex align-items-center mb-2">
//                     <label htmlFor="name" style={{ width: "110px" }}>
//                       Name:
//                     </label>
//                     <Field type="text" name="name" readOnly />
//                   </div>
//                   <div className="d-flex align-items-center mb-2">
//                     <label htmlFor="employeeId" style={{ width: "110px" }}>
//                       EmployeeID:
//                     </label>
//                     <Field type="text" name="employeeId" readOnly />
//                   </div>

//                   <div hidden>
//                     <label htmlFor="availableCl">availableCL:</label>
//                     <Field
//                       type="number"
//                       name="availableCl"
//                       readOnly
//                       // value={request.availableCL}
//                     />
//                     <br />
//                     <label htmlFor="availablePl">availablePL:</label>
//                     <Field
//                       type="number"
//                       name="availablePl"
//                       readOnly
//                       // value={request.availablePL}
//                     />
//                     <br />
//                     <label htmlFor="cl">CL:</label>
//                     <Field type="number" name="cl" readOnly />
//                     <br />
//                     <label htmlFor="pl">PL:</label>
//                     <Field type="number" name="pl" readOnly />
//                     <br />
//                     <label htmlFor="lop">LOP:</label>
//                     <Field type="number" name="lop" readOnly />
//                   </div>
//                   <div className="d-flex align-items-center mb-2">
//                     <label htmlFor="totalLeaveDays" style={{ width: "110px" }}>
//                       Leave Days:
//                     </label>
//                     <Field type="number" name="totalLeaveDays" readOnly />
//                   </div>
//                   <div className="d-flex align-items-center mb-2">
//                     <label htmlFor="leaveReason" style={{ width: "110px" }}>
//                       Leave Reason:
//                     </label>
//                     <Field type="text" name="leaveReason" readOnly />
//                   </div>
//                   <div className="d-flex  align-items-center mb-2">
//                     <label htmlFor="startDate" style={{ width: "110px" }}>
//                       start Date:
//                     </label>
//                     <Field type="text" name="startDate" readOnly />
//                   </div>

//                   <div className="d-flex align-items-center mb-2">
//                     <label htmlFor="endDate" style={{ width: "110px" }}>
//                       end Date:
//                     </label>
//                     <Field type="text" name="endDate" readOnly />
//                   </div>

//                   <div className="d-flex align-items-center mb-2">
//                     <label htmlFor="comments" style={{ width: "110px" }}>
//                       Comments:
//                     </label>
//                     <Field
//                       component="textarea"
//                       name="comments"
//                       onChange={handleInputChange(
//                         request.userId,
//                         setFieldValue
//                       )}
//                     />
//                   </div>

//                   <div className="d-flex justify-content-center align-items-center">
//                     <button
//                       type="submit"
//                       className="btn btn-success"
//                       onClick={() => handleAccept(values)}
//                     >
//                       Accept
//                     </button>
//                     <button
//                       type="submit"
//                       className="btn btn-danger ml-5"
//                       onClick={() => reject(values)}
//                     >
//                       Reject
//                     </button>
//                   </div>

//                   {/* </div> */}
//                 </Form>
//               )}
//             </Formik>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default PendingLeaves;

import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import staticValues from "../../../../config/staticValues";

const PendingLeaves = () => {
  const navigate = useNavigate();
  const [approvalData, setApprovalData] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const userId = useSelector((state) => state.login.userData.userId);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/gettingLeaveRequestsBasedOnStatus`,
        {
          userId,
          status: staticValues.statusPending,
        }
      )
      .then((response) => {
        setApprovalData(response.data);
      });
  }, [userId]);

  const handleInputChange = (userId, setFieldValue) => (event) => {
    const newValue = event.target.value;
    setFieldValue("comments", newValue);
  };

  const handleReject = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/rejectLeaveRequest`,
        {
          id: values.id,
          userId: values.userId,
          name: values.name,
          email: values.email,
          comments: values.comments,
          status: staticValues.statusRejected,
        }
      );
      toast.success("Response submitted successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error sending data to the backend:", error);
    }
  };

  const handleAccept = async (values) => {
    try {
      const managerResponse = {
        status: staticValues.statusApproved,
      };
      const postData = { ...values, ...managerResponse };
      const response = await axios.post(
        ` ${process.env.REACT_APP_API_URL}/v1/updateLeaveRequestStatus`,
        postData
      );
      toast.success("Response submitted successfully");
      if (response) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error sending data to the backend:", error);
    }
  };

  const handleView = (request) => {
    setSelectedRequest(request);
  };

  const closeModal = () => {
    setSelectedRequest(null);
  };

  return (
    <div className="Leaves" style={{ fontFamily: "Recharge" }}>
      <h4 className="bg-purple text-dark text-center p-3 rounded">
        Requests Pending for Approval - ({approvalData.length})
      </h4>
      {approvalData && approvalData.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Employee ID</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {approvalData.map((request, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{request.name}</td>
                <td>{request.employeeId}</td>
                <td>{request.startDate}</td>
                <td>{request.endDate}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleView(request)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Modal show={!!selectedRequest} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Request Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              id: selectedRequest?._id,
              userId: selectedRequest?.userId,
              name: selectedRequest?.name,
              employeeId: selectedRequest?.employeeId,
              availableCl: selectedRequest?.availableCl,
              availablePl: selectedRequest?.availablePl,
              email: selectedRequest?.email,
              cl: selectedRequest?.cl,
              pl: selectedRequest?.pl,
              lop: selectedRequest?.lop,
              totalLeaveDays: selectedRequest?.totalLeaveDays,
              leaveReason: selectedRequest?.leaveReason,
              startDate: selectedRequest?.startDate,
              endDate: selectedRequest?.endDate,
              comments: "",
            }}
            validationSchema={Yup.object().shape({
              comments: Yup.string().required("Comments are required"),
              // Add other validations if needed
              id: Yup.string().required(),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                if (values.actionType === "accept") {
                  await handleAccept(values);
                } else if (values.actionType === "reject") {
                  await handleReject(values);
                }
              } catch (error) {
                console.error("Error submitting request:", error);
              }
            }}
          >
            {({
              handleSubmit,
              setFieldValue,
              isSubmitting,
              isValid /* other Formik props */,
            }) => (
              <Form>
                {/* Add your form fields here */}
                <Form.Group controlId="formName" hidden>
                  <Form.Label>userId:</Form.Label>
                  <Field
                    type="text"
                    name="userId"
                    className="form-control"
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="formName" className="d-flex mb-1">
                  <Form.Label className="w-75">
                    {" "}
                    <strong>Name:</strong>
                  </Form.Label>
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="formEmployeeId" className="d-flex mb-1">
                  <Form.Label className="w-75">
                    <strong>Employee Id</strong>:
                  </Form.Label>
                  <Field
                    type="text"
                    name="employeeId"
                    className="form-control"
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="formAvailableCl" hidden>
                  <Form.Label>Available CL:</Form.Label>
                  <Field
                    type="number"
                    name="availableCl"
                    className="form-control"
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="formAvailablePl" hidden>
                  <Form.Label>Available PL:</Form.Label>
                  <Field
                    type="number"
                    name="availablePl"
                    className="form-control"
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="formCl" hidden>
                  <Form.Label>CL:</Form.Label>
                  <Field
                    type="number"
                    name="cl"
                    className="form-control"
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="formPl" hidden>
                  <Form.Label>PL:</Form.Label>
                  <Field
                    type="number"
                    name="pl"
                    className="form-control"
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="formLop" hidden>
                  <Form.Label>LOP:</Form.Label>
                  <Field
                    type="number"
                    name="lop"
                    className="form-control"
                    readOnly
                  />
                </Form.Group>

                <Form.Group
                  controlId="formTotalLeaveDays"
                  className="d-flex mb-1"
                >
                  <Form.Label className="w-75">
                    <strong>Number of Leave days:</strong>
                  </Form.Label>
                  <Field
                    type="number"
                    name="totalLeaveDays"
                    className="form-control"
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="formLeaveReason" className="d-flex mb-1">
                  <Form.Label className="w-75">
                    <strong>Leave Reason:</strong>
                  </Form.Label>
                  <Field
                    type="text"
                    name="leaveReason"
                    className="form-control"
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="formStartDate" className="d-flex mb-1">
                  <Form.Label className="w-75">
                    <strong>Start Date:</strong>
                  </Form.Label>
                  <Field
                    type="text"
                    name="startDate"
                    className="form-control"
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="formEndDate" className="d-flex mb-1">
                  <Form.Label className="w-75">
                    <strong>End Date:</strong>
                  </Form.Label>
                  <Field
                    type="text"
                    name="endDate"
                    className="form-control"
                    readOnly
                  />
                </Form.Group>
                {/* Add other form fields */}
                <Form.Group controlId="formComments" className="d-flex mb-1">
                  <Form.Label className="w-75">
                    <strong>
                      Comments<span className="required-field">*</span>:
                    </strong>
                  </Form.Label>
                  <Field
                    component="textarea"
                    name="comments"
                    className="form-control"
                    onChange={handleInputChange(
                      selectedRequest?.userId,
                      setFieldValue
                    )}
                  />
                </Form.Group>
                <ErrorMessage
                  name="comments"
                  component="div"
                  className="text-danger"
                ></ErrorMessage>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    variant="success"
                    // onClick={() => handleAccept(values)}
                    onClick={() => {
                      setFieldValue("actionType", "accept");
                      handleSubmit();
                    }}
                    // disabled={isSubmitting || !isValid}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="danger"
                    className="m-2"
                    // onClick={() => reject(values)}
                    onClick={() => {
                      setFieldValue("actionType", "reject");
                      handleSubmit();
                    }}
                    // disabled={isSubmitting || !isValid}
                  >
                    Reject
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PendingLeaves;
