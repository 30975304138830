import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Col } from "react-bootstrap";
const EmpAccountDefault = ({ fun }) => {
  //   const [finDetails, setFinDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userId = useSelector((state) => state.login.userData.userId);

  const initialValues = {
    // userId: finDetails && finDetails.userId ? finDetails.userId : userId,
    // aadhar: finDetails && finDetails.aadhar ? finDetails.aadhar : "",
    // panCard: finDetails && finDetails.panCard ? finDetails.panCard : "",
    // pfNumber: finDetails && finDetails.pfNumber ? finDetails.pfNumber : "",
    // uan: finDetails && finDetails.uan ? finDetails.uan : "",
    // accountNumber:
    //   finDetails && finDetails.accountNumber ? finDetails.accountNumber : "",
    // ifscCode: finDetails && finDetails.ifscCode ? finDetails.ifscCode : "",
    userId: userId ? userId : "",
    aadhar: "",
    panCard: "",
    pfNumber: "",
    uan: "",
    accountNumber: "",
    ifscCode: "",
  };

  const validationSchema = Yup.object({
    aadhar: Yup.string()
      .matches(/^\d{12}$/)
      .required("Aadhar is required"),
    panCard: Yup.string()
      .matches(/^[A-Z]{5}\d{4}[A-Z]$/)
      .required("panCard is required"),
    pfNumber: Yup.string().required(),
    uan: Yup.string().required(),
    accountNumber: Yup.string().required("Account Number is required"),
    ifscCode: Yup.string().required("IFSC Code is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    // console.log(values, userId);
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/empAccountUpdate/v1`, values)
        .then((res) => {
          toast.success("Successfully submitted");
          // console.log(res);
        });
      resetForm();
      setIsLoading(false);
      fun();
      //   window.location.reload();
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
      setIsLoading(false);
    }
  };

  return (
    // <Col sm={8} className="content">
    // <div className="d-flex justify-content-center align-items-center ">
    <div className="container">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form className="">
            <h3 className=" text-dark">Employee Financial Credentials:</h3>
            <br />
            <div className="row mb-3">
              <label htmlFor="aadhar" className="col-sm-2 col-form-label fs-5 ">
                Aadhar:<span style={{ color: "red" }}>*</span>
              </label>
              <div className="col-sm-5" disabled>
                <Field
                  type="text"
                  name="aadhar"
                  id="aadhar"
                  className="form-control"
                  placeholder="Enter your Aadhar Num"
                />
                <ErrorMessage
                  name="aadhar"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row mb-3">
              <label htmlFor="panCard" className="col-sm-2 col-form-label fs-5">
                PanCard:<span style={{ color: "red" }}>*</span>
              </label>
              <div className="col-sm-5">
                <Field
                  type="text"
                  name="panCard"
                  id="panCard"
                  className="form-control"
                  placeholder="Enter your PanCard"
                />
                <ErrorMessage
                  name="panCard"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row mb-3">
              <label
                htmlFor="pfNumber"
                className="col-sm-2 col-form-label fs-5"
              >
                PF Number:<span className="text-danger">*</span>
              </label>
              <div className="col-sm-5">
                <Field
                  type="text"
                  name="pfNumber"
                  id="pfNumber"
                  className="form-control"
                  placeholder="Enter Your Existed PF"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="pfNumber"
                ></ErrorMessage>
              </div>
            </div>

            <div className="row mb-3">
              <label htmlFor="uan" className="col-sm-2 col-form-label fs-5">
                UAN:<span className="text-danger">*</span>
              </label>
              <div className="col-sm-5">
                <Field
                  type="text"
                  name="uan"
                  className="form-control"
                  placeholder="Enter Your UAN"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="uan"
                ></ErrorMessage>
              </div>
            </div>

            <div className="row mb-3">
              <label
                htmlFor="accountNumber"
                className="col-sm-2 col-form-label fs-5"
              >
                Bank Account:<span style={{ color: "red" }}>*</span>
              </label>
              <div className="col-sm-5">
                <Field
                  type="text"
                  name="accountNumber"
                  className="form-control"
                  placeholder="Bank Account"
                />
                <ErrorMessage
                  name="accountNumber"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row mb-3">
              <label
                htmlFor="ifscCode"
                className="col-sm-2 col-form-label fs-5"
              >
                IFSC Code:<span style={{ color: "red" }}>*</span>
              </label>
              <div className="col-sm-5">
                <Field
                  type="text"
                  name="ifscCode"
                  className="form-control"
                  placeholder="IFSC Code"
                />
                <ErrorMessage
                  name="ifscCode"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row mb-3 text-end">
              <div className="col-sm-5 offset-sm-2 ">
                <button
                  type="submit"
                  className="btn btn-primary mx-3"
                  disabled={isLoading}
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmpAccountDefault;
