import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./UserDropdown.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

function MyDropdownButton({ fun }) {
  const { name } = useSelector((state) => state.login.userData);
  return (
    <Nav>
      <NavDropdown
        title={
          <span className=" link-styles ">
            <FontAwesomeIcon icon={faUserCircle} /> Hi {name}{" "}
          </span>
        }
        id="userDropdown"
        className="dropdowns text-info service">
        <NavDropdown.Item
          as={Link}
          to="/profiledashboard"
          className="nav-dropdown-item">
          Profile
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          as={Link}
          to="/wamikaemployees"
          className="nav-dropdown-item">
          W-Employee
        </NavDropdown.Item>

        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} onClick={fun} className="nav-dropdown-item">
          Logout
        </NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
}

export default MyDropdownButton;
