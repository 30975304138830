import axios from "axios";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmpProjectAssign = () => {
  const [projectsData, setProjectsData] = useState();
  const [employees, setEmployees] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/fetchProjects`)
      .then((response) => {
        // console.log(response.data.data, response.data.data1);
        setProjectsData(response.data.data);
        setEmployees(response.data.data1);
      });
  }, []);
  const initialValues = { employeeId: "", projectName: "" };
  const validationSchema = yup.object({
    employeeId: yup.string().required("Employee ID is required"),
    // .matches(/^WT\d{3,4}$/, "Invalid Employee ID format"),
    projectName: yup.string().required("ProjectName is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    // console.log(values);
    const projectDetails = JSON.parse(values.projectName);
    const postData = {
      employeeId: values.employeeId,
      projectName: projectDetails.projectName,
      projectId: projectDetails.projectCode,
    };
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/employeeprojectdetails/v1`,
          postData
        )
        .then((res) => {
          toast.success(res.data.data);
          setLoading(false);
          // console.log(res);
          resetForm();
        });
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };
  return (
    <div>
      {projectsData && employees && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {() => (
            <Form className="mx-2">
              <h3 className="text-center">Project Assign Details</h3>
              <div className="form-group row my-2">
                <label htmlFor="employeeId" className="col-sm-2 col-form-label">
                  <h5>
                    EmployeeId:<span className="text-danger">*</span>
                  </h5>
                </label>
                <div className="col-sm-10">
                  <Field as="select" name="employeeId" className="form-select">
                    <option value="" selected>
                      Select Employee
                    </option>
                    {employees.map((item, index) => (
                      <option key={index} value={item.employeeId}>
                        {item.employeeId}-{item.email}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="employeeId"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              {projectsData && projectsData.length > 0 ? (
                <div className="form-group row my-2">
                  <label
                    htmlFor="projectName"
                    className="col-sm-2 col-form-label">
                    <h5>
                      Project:<span className="text-danger">*</span>
                    </h5>
                  </label>
                  <div className="col-sm-10">
                    <Field
                      as="select"
                      name="projectName"
                      className="form-select">
                      <option value="" selected>
                        Select Project
                      </option>
                      {projectsData.map((project) => (
                        <option
                          key={project.projectCode}
                          value={JSON.stringify(project)}>
                          {project.projectName}-{project.projectCode}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="projectName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              ) : null}

              <div className="form-group row">
                <div className="col-sm-10 offset-sm-2">
                  <input
                    type="reset"
                    value="Clear"
                    className="btn btn-danger mx-1"
                    disabled={loading}
                  />
                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-success mx-1"
                    disabled={loading}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EmpProjectAssign;
