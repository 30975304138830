import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { add } from "../store/loginSlice";
import "./login.css";
import { useSelector } from "react-redux";
import staticValues from "../config/staticValues";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./ForgotPassword";

const Login = ({
  showModal,
  setShowModal,
  setIsLoggedIn,
  setUserData,
  clearing,
}) => {
  const dispatch = useDispatch();
  const { fullName } = useSelector((state) => state.login.userData);
  const [showPassword, setShowPassword] = useState(false);
  // const [logoutTimeout, setLogoutTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userActive, setUserActive] = useState(true);
  const [showModal1, setShowModal1] = useState(false);
  useEffect(() => {
    // console.log(fullName);
    if (fullName) {
      // console.log("userActivty executing");
      let timeout;
      const handleActivity = () => {
        if (!userActive) {
          setUserActive(true);
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          // clearing();
          setUserActive(false);
          // window.location.reload();
        }, staticValues.timeout);
      };

      const events = ["mousemove", "keydown", "click", "scroll"]; // Add any other relevant events here

      events.forEach((event) => {
        document.addEventListener(event, handleActivity);
      });

      timeout = setTimeout(() => {
        clearing();
        setUserActive(false);
        window.location.reload();
      }, staticValues.timeout);

      return () => {
        events.forEach((event) => {
          document.removeEventListener(event, handleActivity);
        });
        clearTimeout(timeout);
      };
    }
  }, [userActive, fullName]);

  //=================== Validation schema===================================
  const validationSchema = yup.object({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const loginData = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        values
      );
      if (loginData.data && loginData.data.success === true) {
        dispatch(add(loginData.data));
        setIsLoggedIn(true);

        if (loginData.data.role && loginData.data.role.includes("Admin")) {
          setUserData(true);
        }
        setShowModal(false);
        resetForm();
      }
      {
        loginData.data && loginData.data.success === true
          ? toast.success(loginData.data.message)
          : toast.error(loginData.data.message);
      }
    } catch (error) {
      console.log(error.message);
      // alert("Try again login failed");
      toast.error(error.message);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  //=====================CLOSING MODAL==================================
  const handleForgotPassword = () => {
    setShowModal(false); // Close the login modal
    setShowModal1(true);
  };
  //=====================CLOSING MODAL==================================
  const forgotModalClose = () => {
    setShowModal1(false);
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ fontFamily: "Recharge" }}>
        <Modal.Header
          closeButton
          style={{
            backgroundImage: "linear-gradient(to right, skyblue, white)",
            color: "black",
          }}>
          <div className="d-flex align-items-center">
            <Modal.Title style={{ fontFamily: "Recharge" }}>
              LOGIN FORM
            </Modal.Title>
          </div>
        </Modal.Header>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {(formik) => (
            <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
              <Modal.Body>
                <Form.Group controlId="email">
                  <Form.Label>
                    <b>UserMail:</b>
                  </Form.Label>
                  <div className="custom-input-container">
                    <Field
                      type="email"
                      name="email"
                      className="form-control custom-input"
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                </Form.Group>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="form-group text-danger"
                />

                <Form.Group controlId="password">
                  <Form.Label>
                    <b>Password:</b>
                  </Form.Label>
                  <div className="input-group custom-input-container ">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control custom-input"
                      placeholder="Enter Your Password"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="btn btn-outline-secondary">
                      {showPassword ? <Eye /> : <EyeSlash />}
                    </button>
                  </div>
                </Form.Group>

                <ErrorMessage
                  name="password"
                  component="div"
                  className="form-group text-danger"
                />
                <br />
                {/* <Link
                  to="/forgotpassword"
                  onClick={handleForgotPassword}
                  className="text-decoration-none ">
                  Forgot Password?
                </Link> */}
                <Button
                  variant="link"
                  onClick={handleForgotPassword}
                  className="text-decoration-none p-0">
                  Forgot Password?
                </Button>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" type="reset" disabled={loading}>
                  Clear
                </Button>
                <Button variant="primary" type="submit" disabled={loading}>
                  Login
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      {showModal1 && (
        <ForgotPassword
          showModal1={showModal1}
          forgotModalClose={forgotModalClose}
        />
      )}
    </>
  );
};

export default Login;
