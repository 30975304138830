import React from "react";
import { useState } from "react";
import "./LeaveApp.css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCalendar,
  faFolder,
  faFileAlt,
  faCheck,
  faEnvelope,
  faClock,
  faTimes,
  faFileExcel,
  faExclamationTriangle,
  faCoins,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
const LeaveApp = () => {
  const { role } = useSelector((state) => state.login.userData);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);

  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };
  return (
    <div className="mx-2">
      <h4 className="m-auto">
        {/* {role && role.includes("Manager")
          ? "Manager-DashBoard"
          : "Employee-DashBoard"} */}
        DashBoard
      </h4>
      <hr />
      <NavLink
        to="#"
        className="nav-link-profile d-flex justify-content-between"
        onClick={() => {
          setIsDropdownOpen3(!isDropdownOpen3);
          setIsDropdownOpen1(false);
          setIsDropdownOpen2(false);
          setIsDropdownOpen(false);
        }}>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faFolder} className="px-1" />
          TimeSheet
        </div>
        <FontAwesomeIcon
          icon={faAngleDown}
          className={`dropdown-icon mr-3 ${isDropdownOpen3 ? "open" : ""}`}
        />
      </NavLink>

      {/* Dropdown content goes here */}
      {isDropdownOpen3 && (
        <div className="dropdown-content bg-white py-2">
          <NavLink to="timesheet" className="nav-link-profile">
            <FontAwesomeIcon icon={faFolder} className="px-1" />
            Timesheet-Entries
          </NavLink>
          <br />
          {role && role.includes("Manager") && (
            <div>
              <NavLink to="timesheetApprovals" className="nav-link-profile ">
                <FontAwesomeIcon icon={faCheck} className="px-1" />
                TS Approval-Reqs
              </NavLink>
              <br />
              <NavLink to="timesheetRevokeReqs" className="nav-link-profile ">
                <FontAwesomeIcon icon={faCheck} className="px-1" />
                TS Revoke-Reqs
              </NavLink>
              <br />
              <NavLink to="generateReport" className="nav-link-profile ">
                <FontAwesomeIcon icon={faFileExcel} className="px-1" />
                TimeSheet-Report
              </NavLink>
            </div>
          )}
        </div>
      )}
      <br />
      {/* =============================== */}
      {/* <NavLink to="timesheet" className="nav-link-profile">
        <FontAwesomeIcon icon={faFolder} className="px-1" />
        Timesheet
      </NavLink>
      <br /> */}
      <NavLink to="hrDocs" className="nav-link-profile">
        <FontAwesomeIcon icon={faFolder} className="px-1" />
        HR documents
      </NavLink>
      <br />

      {/* <NavLink to="employeeLeaveRequests" className="nav-link-profile">
        <FontAwesomeIcon icon={faCalendar} className="px-1" />
        Apply Leave
      </NavLink>
      <br />
      <NavLink to="previousRequests" className="nav-link-profile ">
        <FontAwesomeIcon icon={faFileAlt} className="px-1" />
        Applied Leaves
      </NavLink>
      <br /> */}

      {role && role.includes("Manager") && (
        <>
          {/* <NavLink to="generateReport" className="nav-link-profile ">
            <FontAwesomeIcon icon={faFileExcel} className="px-1" />
            TimeSheet-Report
          </NavLink>
          <br /> */}
          <NavLink to="statusrevoke" className="nav-link-profile ">
            <FontAwesomeIcon icon={faExclamationTriangle} className="px-1" />
            Reportees
          </NavLink>
          <br />

          <NavLink to="projectassign" className="nav-link-profile ">
            <FontAwesomeIcon icon={faFileExcel} className="px-1" />
            Assign-Project
          </NavLink>
          <br />
          <NavLink to="projectcreation" className="nav-link-profile ">
            <FontAwesomeIcon icon={faFileExcel} className="px-1" />
            Create-Project
          </NavLink>
        </>
      )}
      <br />

      <NavLink
        to="#"
        className="nav-link-profile d-flex justify-content-between"
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen);
          setIsDropdownOpen1(false);
          setIsDropdownOpen2(false);
          setIsDropdownOpen3(false);
        }}>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faCoins} className="px-1" />
          Leave
        </div>
        <FontAwesomeIcon
          icon={faAngleDown}
          className={`dropdown-icon mr-3 ${isDropdownOpen ? "open" : ""}`}
        />
      </NavLink>

      {isDropdownOpen && (
        <div className="dropdown-content bg-white py-2">
          {/* Dropdown content goes here */}
          <NavLink to="employeeLeaveRequests" className="nav-link-profile">
            <FontAwesomeIcon icon={faCalendar} className="px-1" />
            Apply Leave
          </NavLink>
          <br />
          <NavLink to="previousRequests" className="nav-link-profile ">
            <FontAwesomeIcon icon={faFileAlt} className="px-1" />
            Applied Leaves
          </NavLink>
          {/* Add more NavLink items as needed */}
        </div>
      )}
      <br />

      {/*   ================Role Based Links============================ */}
      {role && role.includes("Manager") && (
        <>
          <NavLink
            to="#"
            className="nav-link-profile d-flex justify-content-between"
            onClick={() => {
              setIsDropdownOpen1(!isDropdownOpen1);
              setIsDropdownOpen(false);
              setIsDropdownOpen2(false);
              setIsDropdownOpen3(false);
            }}>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faCoins} className="px-1" />
              Manager-Reqs
            </div>
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`dropdown-icon ${isDropdownOpen1 ? "open" : ""}`}
            />
          </NavLink>

          {isDropdownOpen1 && (
            <div className="dropdown-content bg-white py-2">
              <NavLink to="approvedRequests" className="nav-link-profile ">
                <FontAwesomeIcon icon={faCheck} className="px-1" />
                Approved Leave-Reqs
              </NavLink>
              <br />
              <NavLink to="pendingRequests" className="nav-link-profile ">
                <FontAwesomeIcon icon={faClock} className="px-1" />
                Pending Leave-Reqs
              </NavLink>
              <br />
              <NavLink to="rejectedRequests" className="nav-link-profile ">
                <FontAwesomeIcon icon={faTimes} className="px-1" />
                Rejected Leave-Reqs
              </NavLink>
            </div>
          )}
        </>
      )}
      <br />

      {role && role.includes("Job Manager") && (
        <>
          <NavLink
            to="#"
            className="nav-link-profile py-2 d-flex justify-content-between"
            onClick={() => {
              setIsDropdownOpen2(!isDropdownOpen2);
              setIsDropdownOpen(false);
              setIsDropdownOpen1(false);
              setIsDropdownOpen3(false);
            }}>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faCoins} className="px-1" />
              Jobs
            </div>
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`dropdown-icon ${isDropdownOpen2 ? "open" : ""}`}
            />
          </NavLink>

          {isDropdownOpen2 && (
            <div className="dropdown-content bg-white py-2">
              <NavLink to="postjob" className="nav-link-profile ">
                <FontAwesomeIcon icon={faEnvelope} className="px-1" />
                Post-Job
              </NavLink>
              <br />
              <NavLink to="getpostedjobs" className="nav-link-profile ">
                <FontAwesomeIcon icon={faFileAlt} className="px-1" />
                Get Posted-Jobs
              </NavLink>
            </div>
          )}
          <br />
          <NavLink to="getemployeeslist" className="nav-link-profile">
            <FontAwesomeIcon icon={faFileAlt} className="px-1" />
            HR-Employees
          </NavLink>
        </>
      )}
      <br />

      {/*   ================Role Based Links============================ */}
    </div>
  );
};

export default LeaveApp;
