import React from "react";
import { Link } from "react-router-dom";
import "./Cards.css";
const cardData = [
  {
    title:
      "It relies on specialized tools to quickly and effeciently test software and catch potential problems to help bring it to market faster while  maintaining an excellent user experience",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLif2rDgC9VoWc62_LRKJ006tYbl8u5NsKUQ&usqp=CAU",
  },
  {
    title:
      "It relies on specialized tools to quickly and effeciently test software and catch potential problems to help bring it to market faster while  maintaining an excellent user experience",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxBymh4InFwlsRU7AGtY40dgr8E1ZMIieXZg&usqp=CAU",
  },
  {
    title:
      "It relies on specialized tools to quickly and effeciently test software and catch potential problems to help bring it to market faster while  maintaining an excellent user experience",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS27pwpm-dE6modNP00IyL60qlltHZzw2vDWg&usqp=CAU",
  },
  {
    title:
      "It relies on specialized tools to quickly and effeciently test software and catch potential problems to help bring it to market faster while  maintaining an excellent user experience",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWscT3qdE0JdCbUWGBeTE_7KGO3A-y_YpedQ&usqp=CAU",
  },
  {
    title:
      "It relies on specialized tools to quickly and effeciently test software and catch potential problems to help bring it to market faster while  maintaining an excellent user experience",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-XJlwxcRyVSqVZI6WqR3_pTP-QXQ16KxJcQ&usqp=CAU",
  },
  {
    title:
      "It relies on specialized tools to quickly and effeciently test software and catch potential problems to help bring it to market faster while  maintaining an excellent user experience",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7P2R3_e6FY1bNrLgQ33ldHptwg8fqGDNZCg&usqp=CAU",
  },
];
const AlternatingLayout1 = () => {
  return (
    <div>
      <h2 className="d-flex justify-content-center py-3 bg-white custom-style">
        <i className="animated-heading ">SERVICES</i>
      </h2>
      <hr style={{ color: "brown" }} />
      <div className="container py-7">
        {cardData.map((item, index) => (
          <div
            key={item.id}
            className={`row ${
              index % 2 === 0 ? "flex-row-reverse" : ""
            } mb-4 align-items-center`}
          >
            <div className="col-md-6 text-center custom-card">
              <Link to="#">
                {" "}
                <img
                  src={item.imageUrl}
                  alt={`Image ${item.id}`}
                  className="img-fluid rounded"
                />
              </Link>
            </div>
            <div className="col-md-6">
              <h6 className="text-center text-dark custom-title">
                <i>{item.title}</i>
              </h6>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AlternatingLayout1;
