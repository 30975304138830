import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./PostJobForm.css";

const ApplicationsReceived = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const jobData = location.state ? location.state.jobData : null;
  const resumes = location.state ? location.state.resumes : null;

  const openFile = (resumeUrl) => {
    // Open the file in a new window or tab
    window.open(resumeUrl, "_blank");
  };
  const goBack = () => {
    navigate(-1); // This is equivalent to history.goBack()
  };

  return (
    <>
      <p onClick={goBack} className="p-2">
        <svg
          height="25px"
          id="Layer_1"
          style={{ enableBackground: "new 0 0 0 0" }}
          version="1.1"
          viewBox="0 0 512 512"
          width="25px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
        </svg>
        Back to previous page
      </p>
      <div className="styling">
        <h2 className="p-2" style={{ color: "purple" }}>
          Applications Received for Job {jobData ? jobData.jobNumber : ""}
        </h2>
        <ul className="container align-content-center">
          {resumes && resumes.length > 0 ? (
            resumes.map((object) => (
              <div className="d-flex mb-3 row col-md-3" key={object.resume}>
                <li className="">{object.fullName}-</li>
                <button
                  onClick={() => openFile(object.resume)}
                  className="btn btn-primary"
                  style={{ opacity: "0.7" }}
                >
                  View
                </button>
              </div>
            ))
          ) : (
            <p>No applications Received</p>
          )}
        </ul>
      </div>{" "}
    </>
  );
};

export default ApplicationsReceived;
