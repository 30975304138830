import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";

// import * as ExcelJS from "exceljs";
import axios from "axios";
const ExcelJS = require("exceljs");

const ReportGeneration = () => {
  const [projects, setProjects] = useState([]);
  const [resources, setResources] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState("");
  const [selectedResourceDetails, setSelectedResourceDetails] = useState();
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [loadingResources, setLoadingResources] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fetchProjects`
        );
        setProjects(response.data);
      } catch (error) {
        console.error("Error fetching projects:", error.message);
      } finally {
        setLoadingProjects(false);
      }
    };

    const fetchResources = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fetchResources`
        );
        setResources(response.data);
      } catch (error) {
        console.error("Error fetching resources:", error.message);
      } finally {
        setLoadingResources(false);
      }
    };

    fetchProjects();
    fetchResources();
  }, []);

  const formik = useFormik({
    initialValues: {
      reportType: "daily",
      reportCategory: "",
      selectedProject: "",
      selectedResource: "",
      startDate: new Date(),
      endDate: new Date(),
      selectedDate: new Date(),
      selectedMonth: new Date(),
    },
    validationSchema: Yup.object({
      reportType: Yup.string().required("Report Type is required"),
      reportCategory: Yup.string().required("Report Category is required"),
      selectedProject: Yup.string().required(),
      //  Yup.string().when(
      //   ["reportCategory"],
      //   (reportCategory, schema) => {
      //     return reportCategory && reportCategory === "project"
      //       ? schema.required("Project is required")
      //       : schema;
      //   }
      // ),
      selectedResource: Yup.string().required(),
      //  Yup.string().when(
      //   ["reportCategory"],
      //   (reportCategory, schema) => {
      //     return reportCategory && reportCategory === "resource"
      //       ? schema.required("Resource is required")
      //       : schema;
      //   }
      // ),
      startDate: Yup.date(),
      endDate: Yup.date(),
      selectedDate: Yup.date().required("Selected Date is required"),
      selectedMonth: Yup.date().required("Selected Month is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      handleFetchData(values, resetForm);
    },
  });

  const handleFetchData = async (values, resetForm) => {
    try {
      let dateArray, monthValue;

      if (values.reportType === "daily") {
        dateArray = generateDateArray(values.startDate, values.endDate);
      } else if (values.reportType === "weekly") {
        dateArray = generateWeekArray(values.selectedDate);
      } else if (values.reportType === "monthly") {
        monthValue = generateMonthArray(values.selectedMonth);
      }
      if (
        values.reportCategory === "project" &&
        values.selectedProject !== ""
      ) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/gettingProjectSpecificData`,
          {
            dateArray,
            monthValue,
            reportType: values.reportType,
            selectedProject: values.selectedProject,
          }
        );
        if (Array.isArray(response.data) && response.data.length > 0) {
          generateProjectExcelReport(response.data, values.selectedProject);
          setNoDataMessage(""); // Clear the no data message if there is data
        } else {
          setNoDataMessage("No data found for the selected project");
        }
        resetForm();
      } else if (
        values.reportCategory === "resource" &&
        values.selectedResource !== ""
      ) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/gettingResourceSpecificData`,
          {
            dateArray,
            monthValue,
            reportType: values.reportType,
            selectedResource: values.selectedResource,
          }
        );

        if (Array.isArray(response.data) && response.data.length > 0) {
          generateResourceExcelReport(
            response.data,
            selectedResourceDetails.empName,
            selectedResourceDetails.employeeId
          );
          setNoDataMessage(""); // Clear the no data message if there is data
        } else {
          setNoDataMessage("No data found for the selected resource");
        }
      } else {
        alert("Please select valid project/resource value");
      }
    } catch (error) {
      console.error("Error fetching report:", error.message);
    }
  };
  const generateResourceExcelReport = (data, employeeName, employeeId) => {
    const dates = extractUniqueDates(data);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Timesheet");

    const dataArray = getResourceDataArray(
      data,
      dates,
      employeeName,
      employeeId,
      worksheet
    );
    // Continue with the rest of your code
    const ws = XLSX.utils.aoa_to_sheet(dataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ReportSheet");
    XLSX.writeFile(wb, "report.xlsx");
  };

  const generateProjectExcelReport = (data, projectName) => {
    const dates = extractUniqueDates(data);

    // Assuming you have initialized the workbook before calling the function
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Timesheet");

    const dataArray = getProjectDataArray(data, dates, projectName, worksheet);

    // Continue with the rest of your code
    const ws = XLSX.utils.aoa_to_sheet(dataArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ReportSheet");
    XLSX.writeFile(wb, "report.xlsx");
  };

  const extractUniqueDates = (data, ascending = true) => {
    const uniqueDates = new Set();

    data.forEach((item) => {
      // Assuming your data has a 'date' property
      if (item.date) {
        uniqueDates.add(item.date);
      }
    });

    const sortedDates = Array.from(uniqueDates).sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);

      return ascending ? dateA - dateB : dateB - dateA;
    });

    return sortedDates;
  };
  const getResourceDataArray = (
    data,
    dates,
    employeeName,
    employeeId,
    worksheet
  ) => {
    const dataArray = [];

    const uniqueProjects = Array.from(
      new Set(data.map((row) => row.project))
    ).sort();
    // Define columns including dynamic date columns
    const columns = [
      { header: "Employee ID", key: "empId", width: 15 },
      { header: "Employee Name", key: "empName", width: 40 },
      { header: "Project Name", key: "projectName", width: 40 },
      ...dates.map((date) => ({ header: date, key: date, width: 15 })),
      { header: "Total Duration", key: "totalduration", width: 15 },
    ];

    // Extracting headers from the specified columns
    const headers = columns.map((column) => column.header);
    dataArray.push(headers);
    uniqueProjects.forEach((project) => {
      const projectData = data.filter((row) => row.project === project);

      const rowData = [
        employeeId,
        employeeName,
        project,
        ...dates.map((date) => {
          const matchingRows = projectData.filter((row) => row.date === date);
          const totalDuration =
            matchingRows.length > 0
              ? matchingRows
                  .map((row) =>
                    typeof row.duration === "number" ? row.duration : 0
                  )
                  .reduce((sum, duration) => sum + duration, 0)
              : "--";

          return totalDuration;
        }),
        // Total duration for the employee
        projectData
          .map((row) => (typeof row.duration === "number" ? row.duration : 0))
          .reduce((sum, duration) => sum + duration, 0),
      ];

      worksheet.addRow(rowData);
      dataArray.push(rowData);
    });

    return dataArray;
  };
  const getProjectDataArray = (data, dates, projectName, worksheet) => {
    const dataArray = [];
    const employeeMap = new Map();

    const sortedEmployees = Array.from(
      new Set(data.map((row) => row.employeeId))
    ).sort();

    // Define columns including dynamic date columns
    const columns = [
      { header: "Employee ID", key: "empId", width: 15 },
      { header: "Employee Name", key: "empName", width: 40 },
      { header: "Project Name", key: "projectName", width: 40 },
      ...dates.map((date) => ({ header: date, key: date, width: 15 })),
      { header: "Total Duration", key: "totalduration", width: 15 },
    ];

    // Extracting headers from the specified columns
    const headers = columns.map((column) => column.header);
    dataArray.push(headers);
    sortedEmployees.forEach((employeeId) => {
      const employeeData = data.filter((row) => row.employeeId === employeeId);
      const employeeName =
        employeeData.length > 0 ? employeeData[0].empName : "";

      if (!employeeMap.has(employeeId)) {
        employeeMap.set(employeeId, employeeName);

        const rowData = [
          employeeId,
          employeeName,
          projectName,
          ...dates.map((date) => {
            const matchingRows = data.filter(
              (row) => row.employeeId === employeeId && row.date === date
            );

            const totalDuration =
              matchingRows.length > 0
                ? matchingRows
                    .map((row) =>
                      typeof row.duration === "number" ? row.duration : 0
                    )
                    .reduce((sum, duration) => sum + duration, 0)
                : "--";

            return totalDuration;
          }),
          // Total duration for the employee
          employeeData
            .map((row) => (typeof row.duration === "number" ? row.duration : 0))
            .reduce((sum, duration) => sum + duration, 0),
        ];

        worksheet.addRow(rowData);
        dataArray.push(rowData);
      }
    });

    return dataArray;
  };

  const generateDateArray = (startDate, endDate) => {
    const datesArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      // Check if the current day is not Saturday (6) or Sunday (0)
      if (currentDate.getDay() !== 6 && currentDate.getDay() !== 0) {
        datesArray.push(currentDate.toISOString().split("T")[0]);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesArray;
  };
  const generateWeekArray = (selectedDate) => {
    const datesArray = [];
    let currentDate = new Date(selectedDate);

    currentDate.setDate(currentDate.getDate() - currentDate.getDay());

    for (let i = 0; i < 7; i++) {
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        datesArray.push(currentDate.toISOString().split("T")[0]);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesArray;
  };

  const generateMonthArray = (selectedMonth) => {
    const monthValue = selectedMonth.toLocaleString("en-IN", {
      month: "short",
    });

    return monthValue;
  };
  const handleResourceChange = (event) => {
    const selectedUserId = event.target.value;
    formik.handleChange(event);

    // Find the selected resource from the resources array
    const selectedResource = resources.find(
      (resource) => resource.userId === selectedUserId
    );
    setSelectedResourceDetails(selectedResource);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-2">
        <label>Report Type:</label>
        <select
          name="reportType"
          value={formik.values.reportType}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
        {formik.touched.reportType && formik.errors.reportType ? (
          <div>{formik.errors.reportType}</div>
        ) : null}
      </div>
      <div className="mb-2">
        <label>Report Category:</label>
        <select
          name="reportCategory"
          value={formik.values.reportCategory}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}>
          <option value="" selected>
            Select
          </option>
          <option value="project">Project-based</option>
          <option value="resource">Resource-based</option>
        </select>
        {formik.touched.reportCategory && formik.errors.reportCategory ? (
          <div className="text-danger">{formik.errors.reportCategory}</div>
        ) : null}
      </div>
      {formik.values.reportCategory === "project" && (
        <div className="mb-2">
          <label>Select Project:</label>
          <select
            name="selectedProject"
            value={formik.values.selectedProject}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}>
            {/* {loadingProjects ? (
              <option>Loading...</option>
            ) : (
              <> */}
            <option value="" selected>
              select project
            </option>
            {projects.map((project) => (
              <option key={project.projectCode} value={project.projectName}>
                {project.projectName}
              </option>
              //   ))}
              // </>
            ))}
          </select>
          {formik.touched.selectedProject && formik.errors.selectedProject ? (
            <div className="text-danger">{formik.errors.selectedProject}</div>
          ) : null}
        </div>
      )}
      {formik.values.reportCategory === "resource" && (
        <div className="mb-2">
          <label>Select Resource:</label>
          <select
            name="selectedResource"
            value={formik.values.selectedResource}
            // onChange={formik.handleChange}
            onChange={handleResourceChange}
            onBlur={formik.handleBlur}>
            {/* {loadingResources ? (
              <option>Loading...</option>
            ) : (
              <> */}
            <option value="" selected>
              select Resource
            </option>
            {resources.map((resource) => (
              <option key={resource.userId} value={resource.userId}>
                {`${resource.empName}(${resource.employeeId})`}
              </option>
              //   ))}
              // </>
            ))}
          </select>

          {formik.touched.selectedResource && formik.errors.selectedResource ? (
            <div className="text-danger">{formik.errors.selectedResource}</div>
          ) : null}
        </div>
      )}
      <div className="mb-2">
        <label>Start Date:</label>
        <DatePicker
          name="startDate"
          selected={formik.values.startDate}
          onChange={(date) => formik.setFieldValue("startDate", date)}
          maxDate={new Date()}
          disabled={
            formik.values.reportType === "monthly" ||
            formik.values.reportType === "weekly"
          }
        />
        {formik.touched.startDate && formik.errors.startDate ? (
          <div>{formik.errors.startDate}</div>
        ) : null}
      </div>
      <div className="mb-2">
        <label>End Date:</label>
        <DatePicker
          name="endDate"
          selected={formik.values.endDate}
          onChange={(date) => formik.setFieldValue("endDate", date)}
          maxDate={new Date()}
          disabled={
            formik.values.reportType === "monthly" ||
            formik.values.reportType === "weekly"
          }
        />
        {formik.touched.endDate && formik.errors.endDate ? (
          <div>{formik.errors.endDate}</div>
        ) : null}
      </div>
      {formik.values.reportType === "weekly" && (
        <div className="mb-2">
          <label>Select Date for the Week:</label>
          <DatePicker
            name="selectedDate"
            selected={formik.values.selectedDate}
            onChange={(date) => formik.setFieldValue("selectedDate", date)}
            maxDate={new Date()}
          />
          {formik.touched.selectedDate && formik.errors.selectedDate ? (
            <div>{formik.errors.selectedDate}</div>
          ) : null}
        </div>
      )}
      {formik.values.reportType === "monthly" && (
        <div className="mb-2">
          <label>Select Month:</label>
          <DatePicker
            name="selectedMonth"
            selected={formik.values.selectedMonth}
            onChange={(date) => formik.setFieldValue("selectedMonth", date)}
            showMonthYearPicker
            dateFormat="MM/yyyy"
            maxDate={new Date()}
          />
          {formik.touched.selectedMonth && formik.errors.selectedMonth ? (
            <div>{formik.errors.selectedMonth}</div>
          ) : null}
        </div>
      )}
      <button type="submit" className="btn btn-primary mx-2">
        Generate Report
      </button>
      <button type="reset" className="btn btn-danger mx-2">
        Clear
      </button>
      {noDataMessage && <p>{noDataMessage}</p>}
    </form>
  );
};

export default ReportGeneration;
