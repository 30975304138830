import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedRoute(props) {
  const userId = useSelector((state) => state.login.userData.userId);
  const navigate = useNavigate();
  const { Component } = props;
  useEffect(() => {
    if (!userId) {
      return navigate("/");
    }
  }, []);
  return userId ? <Component /> : null;
}

export default ProtectedRoute;
