import React from "react";
import "./Cards.css";
import { Link } from "react-router-dom";
const cardData = [
  {
    title:
      "It relies on specialized tools to quickly and effeciently test software and catch potential problems to help bring it to market faster while  maintaining an excellent user experience",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsJHupiZSlBfA8XL0pJqSWaW3un59xghNq1Q&usqp=CAU",
  },
  {
    title:
      "It relies on specialized tools to quickly and effeciently test software and catch potential problems to help bring it to market faster while  maintaining an excellent user experience",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIcuTdeMk8U0Sm20l2ji3mdYEsExh4jZ6oBw&usqp=CAU",
  },
  {
    title:
      "It relies on specialized tools to quickly and effeciently test software and catch potential problems to help bring it to market faster while  maintaining an excellent user experience",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkJSEyfy0-B5RESXJKdabkkQ3Qoj4v7XvZ1A&usqp=CAU",
  },
  {
    title:
      "It relies on specialized tools to quickly and effeciently test software and catch potential problems to help bring it to market faster while  maintaining an excellent user experience",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4omSKu1nca1qtLIlcJNSo97N7FLeQFBeJ6w&usqp=CAU",
  },
  {
    title:
      "It relies on specialized tools to quickly and effeciently test software and catch potential problems to help bring it to market faster while  maintaining an excellent user experience",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpkMRZTW4jYoiGl_gaB_APonu7ZUFfzBFx8Q&usqp=CAU",
  },
  {
    title:
      "It relies on specialized tools to quickly and effeciently test software and catch potential problems to help bring it to market faster while  maintaining an excellent user experience",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkjpGe1CnBtzwwGaF5jjgy5R-27TQ6FwE1pg&usqp=CAU",
  },
];
const AlternatingLayout = () => {
  return (
    <div>
      <h2 className="d-flex justify-content-center py-3  custom-style">
        <i className="animated-heading ">TECHNOLOGIES</i>
      </h2>

      <hr style={{ color: "brown" }} />
      <div className="container py-8">
        {cardData.map((item, index) => (
          <div
            key={item.id}
            className={`row ${
              index % 2 === 0 ? "flex-row-reverse" : ""
            } mb-4 align-items-center`}
          >
            <div className="col-md-6 text-center custom-card">
              <Link to="#">
                <img
                  src={item.imageUrl}
                  alt={`Image ${item.id}`}
                  className="img-fluid rounded"
                />
              </Link>
            </div>
            <div className="col-md-6">
              <h6 className="text-center text-dark custom-title">
                <i> {item.title}</i>
              </h6>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AlternatingLayout;
