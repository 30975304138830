import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import EmployeeModal from "./EmployeeModal";

const EmployeesProfiles = () => {
  const [employees, setEmployees] = useState();
  const [jobData, setJobData] = useState();
  const [showModal, setShowModal] = useState();
  const userId = useSelector((state) => state.login.userData.userId);
  useEffect(() => {
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/getemployees/v1`, { userId })
        .then((res) => {
          // console.log(res.data);
          setEmployees(res.data.data);
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const openModal = async (userId) => {
    setJobData(userId);
    setShowModal(true);
  };

  const close = async () => {
    setJobData(null);
    setShowModal(false);
  };

  return (
    <div>
      <Table table-striped>
        <thead>
          <th className="">Name:</th>
          <th className="">EmployeeId:</th>
        </thead>
        <tbody>
          {employees &&
            employees.map((emp, index) => (
              <tr key={index} className="align-bottom">
                <td class>{emp.email}</td>
                <td>{emp.userId}</td>
                <td>
                  <button
                    className="btn btn-outline-info"
                    onClick={() => openModal(emp.userId)}
                  >
                    View
                  </button>
                  {/* <NavLink to="/wamikaemployees/empdetailsection">View</NavLink> */}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {!employees && <h4>Loading.............!</h4>}

      {jobData && showModal && (
        <EmployeeModal userId={jobData} close={close} showModal={showModal} />
      )}
    </div>
  );
};

export default EmployeesProfiles;
