import React from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import axios from "axios";
import * as yup from "yup";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./Payslip.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Payslip() {
  const userId = useSelector((state) => state.login.userData.userId);
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [minYear, setMinYear] = useState();
  const [pdfData, setPdfData] = useState(null);
  const initialValues = { userId: userId, month: "", year: "" };
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object({
    userId: yup.string().required(),
    month: yup.string().required("Month is required"),
    year: yup.string().required("Year is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    // console.log(values);
    setIsLoading(true);
    try {
      const dataPaySlip = await axios.post(
        `${process.env.REACT_APP_API_URL}/paySlipDownload/v1`,
        values
      );
      setPdfData(dataPaySlip.data);
      setIsLoading(false);
      toast.success(dataPaySlip.data.message);
    } catch (error) {
      toast.error(error.message);
      // console.error("Error fetching PDF:", error);
      setIsLoading(false);
    }
    resetForm();
  };

  //========= Display max year based on month selection====================
  const handleChange = (monthChoosed, setFieldValue) => {
    setFieldValue("month", monthChoosed);
    const monthPresent = new Date().getMonth() + 1;

    return monthChoosed >= monthPresent
      ? setMinYear(new Date().getFullYear() - 1)
      : setMinYear(new Date().getFullYear());
  };
  //========= Display max year based on month selection====================

  return (
    <div className="payslip-container ">
      {pdfData && pdfData.pdfStream ? (
        <div className="text-center ">
          <b className="mx-2">{pdfData.text}</b>
          <Link
            to={pdfData.pdfStream}
            target="_blank"
            // onClick={() => {
            //   window.open(pdfData.pdfStream, "_blank");
            // }}
          >
            <p>Tap toView</p>
          </Link>
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {({ setFieldValue }) => (
            <Form className="payslip-form">
              {/* <div className="">
                <h4 className="d-flex justify-content-center align-items-center mt-5 payslip-heading">
                  Download payslip:
                </h4>

                <div className="d-flex justify-content-center align-items-center mt-5 ">
                  <div hidden>
                    <label htmlFor="">Enter UserId:</label>
                    <Field
                      type="text"
                      name="userId"
                      placeholder="Enter your UserId"
                    />
                    <br />
                    <ErrorMessage
                      name="userId"
                      className="text-danger"
                      component="div"></ErrorMessage>
                  </div>
                  <div className="mx-3">
                    <label htmlFor="month" className="labelfont">
                      Select Month :
                    </label>
                    <Field
                      as="select"
                      name="month"
                      className="form-select"
                      onChange={(e) => {
                        handleChange(e.target.value, setFieldValue);
                      }}>
                      <option value="">Select Month</option>
                      {months.map((individualMonth, index) => (
                        <option value={individualMonth} key={index}>
                          {individualMonth}
                        </option>
                      ))}
                    </Field>
                    <br />
                    <ErrorMessage
                      name="month"
                      className="text-danger"
                      component="div"></ErrorMessage>
                  </div>

                  <div className="mx-3">
                    <label htmlFor="year" className=" labelfont">
                      Select Year :
                    </label>
                    <Field as="select" name="year" className="form-control">
                      <option value="">Select Year</option>
                      {Array.from({ length: minYear - 2022 }, (_, index) => {
                        const year = 2023 + index;
                        return (
                          <option value={year} key={index}>
                            {year}
                          </option>
                        );
                      })}
                    </Field>
                    <br />
                    <ErrorMessage
                      name="year"
                      className="text-danger"
                      component="div"></ErrorMessage>
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center ">
                  <input
                    type="reset"
                    value="Clear"
                    className="btn btn-danger mx-2"
                  />
                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary mx-2"
                    disbaled={isLoading}
                  />
                </div>
              </div> */}
              <div className="container d-flex justify-content-center mt-5 ">
                <div className="card w-50">
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      Download Payslip:
                    </h5>
                    <hr />
                    <div className="mb-3">
                      <div hidden>
                        <label htmlFor="">Enter UserId:</label>
                        <Field
                          type="text"
                          name="userId"
                          placeholder="Enter your UserId"
                        />
                        <ErrorMessage
                          name="userId"
                          className="text-danger"
                          component="div"></ErrorMessage>
                      </div>
                    </div>
                    {/* <div className="d-flex"> */}
                    <div class="mb-3">
                      <label htmlFor="month" className="labelfont">
                        Month:
                      </label>
                      <span className="text-danger">*</span>
                      <Field
                        as="select"
                        name="month"
                        className="form-select"
                        onChange={(e) => {
                          handleChange(e.target.value, setFieldValue);
                        }}>
                        <option value="">Select Month</option>
                        {months.map((individualMonth, index) => (
                          <option value={individualMonth} key={index}>
                            {individualMonth}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="month"
                        className="text-danger"
                        component="div"></ErrorMessage>
                    </div>
                    <div class="mb-3">
                      <label htmlFor="year" className=" labelfont">
                        Year:
                      </label>
                      <span className="text-danger">*</span>
                      <Field as="select" name="year" className="form-control">
                        <option value="">Select Year</option>
                        {Array.from({ length: minYear - 2022 }, (_, index) => {
                          const year = 2023 + index;
                          return (
                            <option value={year} key={index}>
                              {year}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="year"
                        className="text-danger"
                        component="div"></ErrorMessage>
                      {/* </div> */}
                    </div>
                    <div class="d-flex justify-content-end">
                      <input
                        type="reset"
                        value="Clear"
                        className="btn btn-outline-danger mx-2"
                      />
                      <input
                        type="submit"
                        value="Submit"
                        className="btn btn-outline-primary mx-2"
                        disbaled={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
              ;
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default Payslip;
{
  /* <div className="container d-flex justify-content-center mt-5">
  <div className="card">
    <div className="card-body">
      <h5 className="card-title text-center">Download Payslip</h5>
      <div className="mb-3">
        <label htmlFor="">Enter UserId:</label>
        <Field type="text" name="userId" placeholder="Enter your UserId" />
        <ErrorMessage
          name="userId"
          className="text-danger"
          component="div"></ErrorMessage>
      </div>
      <div class="mb-3">
        <label htmlFor="month" className="labelfont">
          Month
        </label>
        <Field
          as="select"
          name="month"
          className="form-select"
          onChange={(e) => {
            handleChange(e.target.value, setFieldValue);
          }}>
          <option value="">Select Month</option>
          {months.map((individualMonth, index) => (
            <option value={individualMonth} key={index}>
              {individualMonth}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name="month"
          className="text-danger"
          component="div"></ErrorMessage>
      </div>
      <div class="mb-3">
        <label htmlFor="year" className=" labelfont">
          Year:
        </label>
        <Field as="select" name="year" className="form-control">
          <option value="">Select Year</option>
          {Array.from({ length: minYear - 2022 }, (_, index) => {
            const year = 2023 + index;
            return (
              <option value={year} key={index}>
                {year}
              </option>
            );
          })}
        </Field>
        <ErrorMessage
          name="year"
          className="text-danger"
          component="div"></ErrorMessage>
      </div>
      <div class="d-grid gap-2">
        <input type="reset" value="Clear" className="btn btn-danger mx-2" />
        <input
          type="submit"
          value="Submit"
          className="btn btn-primary mx-2"
          disbaled={isLoading}
        />
      </div>
    </div>
  </div>
</div>; */
}
