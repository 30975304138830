import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useState } from "react";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import staticValues from "../../../../config/staticValues";

const StatusModal = ({ showModal, closeShowModal, item }) => {
  const [loading, setLoading] = useState(false);
  // const initialValues = { email: item ? item.email : "" };
  // const [values, setValues] = useState();

  // const validationSchema = yup.object({
  //   email: yup
  //     .string()
  //     .email("Email should be in this format .......@wamikatech.com")
  //     .required("Email is required"),
  // });

  const onSubmit = async () => {
    // setValues(item.email);
    setLoading(true);
    try {
      let apiUrl =
        item.status === staticValues.statusLocked
          ? `${process.env.REACT_APP_API_URL}/refreshEmployeeStatus/v1 `
          : `${process.env.REACT_APP_API_URL}/blockemployee/v1`;
      // console.log(apiUrl);
      const data = { userId: item.userId, email: item.email };
      // values.userId = item.userId;
      // console.log(data);
      await axios.post(apiUrl, data).then((res) => {
        // console.log(res.data.message);
        toast.success(res.data.message);
      });
      closeShowModal();
      // resetForm();
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {item && (
        <Modal show={showModal} onHide={closeShowModal}>
          <Modal.Header closeButton>
            <Modal.Title>Employee-Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}> */}
            {/* <Form className="text-center"> */}
            <div className="container">
              <div className="row">
                <div className="col">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <b>Email:</b>
                        </td>
                        <td>
                          <i>{item.email}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>EmployeeId:</b>
                        </td>
                        <td>
                          <i>{item.employeeId}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Status:</b>
                        </td>
                        <td>
                          <i>{item.status}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Daily-Limits Remained:</b>
                        </td>
                        <td>
                          <i>{item.dailyLimit}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Failed-Limits Remained:</b>
                        </td>
                        <td>
                          <i>{item.successiveFailLimits}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Assigned Projects:</b>
                        </td>
                        <td>
                          <select className="form-select" id="projects">
                            {item &&
                              item.projects.map((project) => (
                                <option key={project.projectId}>
                                  {project.projectName}-{project.projectId}
                                </option>
                              ))}
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <br />
            {item.status === staticValues.statusLocked ? (
              <button
                type="submit"
                className="btn btn-success "
                disable={loading}
                onClick={onSubmit}
              >
                Unblock
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-success "
                disable={loading}
                onClick={onSubmit}
              >
                Block
              </button>
            )}
            {/* </Form>
            </Formik> */}
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
      )}
    </div>
  );
};

export default StatusModal;
