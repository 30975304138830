import React from "react";
import { useState, useEffect } from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmpDetails = () => {
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [rawData, setRawData] = useState();
  const userId = useSelector((state) => state.login.userData.userId || {});

  useEffect(() => {
    const api1 = `${process.env.REACT_APP_API_URL}/fetchRawData/v1`;
    const api2 = `${process.env.REACT_APP_API_URL}/getempdetails/v1`;
    try {
      Promise.all([axios.post(api1, {}), axios.post(api2, { userId })])
        // axios
        //   .post(`${process.env.REACT_APP_API_URL}/getempdetails/v1`, { userId })
        .then((res) => {
          const [res1, res2] = res;
          // console.log(res);
          setEmployeeDetails(res2.data.data);
          setRawData(res1.data.data[0]);
        });
    } catch (error) {
      console.log(error.messsage);
    }
  }, []);

  const updateInitialValue = (fieldName, value) => {
    setEmployeeDetails((prevEmployeeDetails) => ({
      ...prevEmployeeDetails,
      [fieldName]: value,
    }));
  };

  const initialValues = {
    address: employeeDetails?.address || "",
    contact: employeeDetails?.contact || 0,
    dateOfBirth: employeeDetails?.dateOfBirth || "",
    designation: employeeDetails?.designation || "",
    email: employeeDetails?.email || "",
    employeeId: employeeDetails?.employeeId || "",
    firstName: employeeDetails?.firstName || "",
    gender: employeeDetails?.gender || "",
    joiningDate: employeeDetails?.joiningDate || "",
    lastName: employeeDetails?.lastName || "",
    maritalStatus: employeeDetails?.maritalStatus || "",
    status: employeeDetails?.status || "",
    workLocation: employeeDetails?.workLocation || "",
    workType: employeeDetails?.workType || "",
  };
  const validationSchema = yup.object({
    address: yup.string().required(),
    // contact: yup.string().required(),
    contact: yup
      .string()
      .required()
      .matches(/^[0-9]+$/, "Must be a Numeric Value")
      .min(10, "Must be at least 10 characters")
      .max(10, "Must be at most 10 characters"),
    maritalStatus: yup.string().required(),
  });
  const onSubmit = async (values) => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/userProfileUpdateDetails/v1`,
          values
        )
        .then((res) => {});
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateDetails = async (values) => {
    const contactSchema = yup
      .string()
      .matches(/^[0-9]+$/, "Must be a Numeric Value")
      .min(10, "Must be at least 10 characters")
      .max(10, "Must be at most 10 characters")
     const addressSchema = yup.string().required()
     const maritalSchema = yup.string().required()

    try {
      await contactSchema.validate(values.contact);
      await addressSchema.validate(values.address);
      await maritalSchema.validate(values.maritalStatus);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/userProfileUpdateDetails/v1`,
          values
        )
        .then((res) => {
          // console.log(res.status);
          toast.success("Successfully submitted");
        });
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };
  return (
    <div className="">
      <h4 className="">Employee Personal Details:</h4>

      {employeeDetails && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnChange={true}>
          {() => (
            <Form>
              {/* ===========FIRST COLUMN========================== */}

              <div className="col-md-12 text-end">
                {!employeeDetails ? (
                  <button type="submit" className="btn btn-primary mx-3 ">
                    Submit
                  </button>
                ) : (
                  <button
                    className="btn btn-primary mx-3 "
                    onClick={(e) => {
                      e.preventDefault();
                      updateDetails(initialValues);
                    }}>
                    Update
                  </button>
                )}
              </div>
              <div className="row ">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="firstName" className="labelfont">
                      First Name:
                    </label>
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      disabled="disabled"
                      readOnly
                      className="form-control"
                    />
                    <ErrorMessage name="firstName" component="div" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="lastName" className="labelfont">
                      Last Name:
                    </label>
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      readOnly
                      disabled="disabled"
                      className="form-control"
                    />
                    <ErrorMessage name="lastName" component="div" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="contact" className="labelfont">
                      Contact:
                    </label>
                    <span className="text-danger fw-bold">*</span>

                    <Field
                      type="text"
                      id="contact"
                      name="contact"
                      className="form-control"
                      onChange={(e) =>
                        updateInitialValue("contact", e.target.value)
                      }
                      value={employeeDetails?.contact || ""}
                    />
                    <ErrorMessage name="contact" component="div" />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="gender" className="labelfont">
                      Gender:
                    </label>
                    <Field
                      type="text"
                      id="gender"
                      name="gender"
                      readOnly
                      disabled="disabled"
                      className="form-control"
                    />
                    <ErrorMessage name="gender" component="div" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="workLocation">Work-Location:</label>
                    <Field
                      type="text"
                      id="workLocation"
                      name="workLocation"
                      readOnly
                      disabled="disabled"
                      className="form-control"
                    />
                    <ErrorMessage name="workLocation" component="div" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="address" className="labelfont">
                      Address:
                    </label>
                    <span className="text-danger fw-bold">*</span>
                    <Field
                      as="textarea"
                      id="TextArea"
                      name="address"
                      rows="4"
                      cols="40"
                      className="form-control"
                      onChange={(e) =>
                        updateInitialValue("address", e.target.value)
                      }
                      value={employeeDetails.address}
                    />
                    <ErrorMessage name="address" component="div" />
                  </div>
                </div>
                {/* ===========FIRST COLUMN========================== */}
                {/* ===========SECOND COLUMN========================== */}

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="email" className="labelfont">
                      Email:
                    </label>
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      readOnly
                      disabled="disabled"
                      className="form-control"
                    />
                    <ErrorMessage name="email" component="div" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="employeeId" className="labelfont">
                      EmployeeId:
                    </label>
                    <Field
                      type="text"
                      id="employeeId"
                      name="employeeId"
                      readOnly
                      disabled="disabled"
                      className="form-control"
                    />
                    <ErrorMessage name="employeeId" component="div" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="dateOfBirth" className="labelfont">
                      Date-Of-Birth:
                    </label>
                    <Field
                      type="text"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      className="form-control"
                      readOnly
                      disabled="disabled"
                    />
                    <ErrorMessage name="dateOfBirth" component="div" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="joiningDate" className="labelfont">
                      Joining Date:
                    </label>
                    <Field
                      type="text"
                      id="joiningDate"
                      name="joiningDate"
                      className="form-control"
                      readOnly
                      disabled="disabled"
                    />
                    <ErrorMessage name="joiningDate" component="div" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="workType" className="labelfont">
                      Work-Type:
                    </label>
                    <Field
                      type="text"
                      id="workType"
                      name="workType"
                      readOnly
                      disabled="disabled"
                      className="form-control"
                    />
                    <ErrorMessage name="workType" component="div" />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="maritalStatus" className="labelfont">
                      MaritalStatus
                      <span className="text-danger fw-bold">* </span>:
                    </label>
                    <Field
                      as="select"
                      name="maritalStatus"
                      className="form-select"
                      value={employeeDetails.maritalStatus}
                      onChange={(e) =>
                        updateInitialValue("maritalStatus", e.target.value)
                      }>
                      <option value="">select maritalStatus</option>
                      {rawData.maritalStatus.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </Field>
                    {/* <div className="form-check form-check-inline">
                      <Field
                        type="radio"
                        name="maritalStatus"
                        value="Married"
                        id="Married"
                        className="form-check-input"
                        onChange={() =>
                          updateInitialValue("maritalStatus", "Married")
                        }
                        checked={employeeDetails?.maritalStatus === "Married"}
                      />
                      <label htmlFor="Married" className="form-check-label">
                        Married
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        type="radio"
                        name="maritalStatus"
                        value="UnMarried"
                        id="UnMarried"
                        className="form-check-input"
                        onChange={() =>
                          updateInitialValue("maritalStatus", "UnMarried")
                        }
                        checked={employeeDetails?.maritalStatus === "UnMarried"}
                      />
                      <label htmlFor="UnMarried" className="form-check-label">
                        UnMarried
                      </label>
                    </div> */}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="status" className="labelfont">
                      Employee Status:
                    </label>
                    <Field
                      type="text"
                      id="status"
                      name="status"
                      readOnly
                      disabled="disabled"
                      className="form-control"
                    />
                    <ErrorMessage name="status" component="div" />
                  </div>
                </div>

                <div className="col-md-12 text-end">
                  {!employeeDetails ? (
                    <button type="submit" className="btn btn-primary mx-3">
                      Submit
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary mx-3"
                      onClick={(e) => {
                        e.preventDefault();
                        updateDetails(initialValues);
                      }}>
                      Update
                    </button>
                  )}
                </div>
                {/* ===========SECOND COLUMN========================== */}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EmpDetails;
