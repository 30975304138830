import React from "react";
import { Outlet } from "react-router-dom";
import LeaveApp from "./LeaveApp";

const LeaveAppLayout = () => {
  return (
    <div className=" d-flex mb-1 flex-column flex-md-row">
      <div className="col-md-2  dashboard py-2 dashboard-scroll">
        <LeaveApp />
      </div>
      <div className="col-md-10  outlet py-2 content-scroll">
        <Outlet />
      </div>
    </div>
  );
};

export default LeaveAppLayout;
