import { createSlice } from "@reduxjs/toolkit";

const reportsSlice = createSlice({
  name: "trendsData",
  initialState: {
    trendsReportData: {
      selectedProject: null,
      monthlyData: [],
      yearlyData: [],
      reportType: null,
    },
  },
  reducers: {
    // setTrendsData: (state, action) => {
    //   state.trendsReportData = action.payload;
    // },
    setTrendsData: (state, action) => {
      const { selectedProject, monthlyData, yearlyData, reportType } =
        action.payload;
      state.trendsReportData = {
        selectedProject,
        monthlyData,
        yearlyData,
        reportType,
      };
    },
    clearTrendsData: (state) => {
      state.trendsReportData = [];
    },
  },
});
export const { setTrendsData, clearTrendsData } = reportsSlice.actions;
export default reportsSlice.reducer;
