import React from "react";
import "./ContactUs.css"; // Make sure to import your CSS file
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const initialValues = { name: "", email: "", contact: "", message: "" };
  const validationSchema = yup.object({
    name: yup
      .string()
      .matches(/^(?=.*\S).*$/, "Atleast one character")
      .required("Name is required"),
    email: yup
      .string()
      .email()
      .required("Email is required")
      .matches(/^\S*$/, "Spaces are not allowed"),
    contact: yup
      .string()
      .matches(/^\d{10}$/, "Must be 10 digits numeric")
      .required("Contact is required"),
    message: yup.string().required("Message is required"),
  });
  const onSubmit = async (values, { resetForm }) => {
    try {
      axios
        .post(`${process.env.REACT_APP_API_URL}/clientDetails/v1`, values)
        .then((res) => {
          toast.success("Successfully Submitted");
          // console.log(res.status, "successfully submitted");
        });
      resetForm();
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };
  return (
    <div className="contact-us-container py-2">
      <div className="container">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="contact-form">
                  <h2 className="text-center mb-2 ">Contact Us:</h2>

                  <div className="form-group">
                    <label htmlFor="name" className="labelfont">
                     Name : <span className="text-danger">*</span>
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter your name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    ></ErrorMessage>
                  </div>

                  <div className="form-group">
                    <label htmlFor="email" className="labelfont">
                      Email :<span className="text-danger">*</span>
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter your email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    ></ErrorMessage>
                  </div>
                  <div className="form-group">
                    <label htmlFor="contact" className="labelfont">
                      Contact :<span className="text-danger">*</span>
                    </label>
                    <Field
                      type="tel"
                      name="contact"
                      className="form-control"
                      id="contact"
                      placeholder="Enter your contact"
                    />
                    <ErrorMessage
                      name="contact"
                      component="div"
                      className="text-danger"
                    ></ErrorMessage>
                  </div>

                  <div className="form-group">
                    <label htmlFor="message" className="labelfont">
                      Message :<span className="text-danger">*</span>
                    </label>
                    <Field
                      as="textarea"
                      className="form-control"
                      id="message"
                      name="message"
                      rows="4"
                      placeholder="Type your message here"
                    ></Field>
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="text-danger"
                    ></ErrorMessage>
                  </div>

                  <div className="text-end">
                    <button
                      type="reset"
                      className="btn btn-outline-danger btn-block m-2"
                    >
                      Clear
                    </button>
                    <button
                      type="submit"
                      className="btn btn-outline-primary btn-block m-2"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ContactUs;
