import React from "react";
import { Outlet } from "react-router-dom";
import Dashboard from "./Dashboard";
import EmpDetails from "./empDetails/EmpDetails";
import "./Dashboard.css";
import { useLocation } from "react-router-dom";

const DashboardLayout = () => {
  const location = useLocation();

  // Access the current URL from the location object
  const currentUrl = location.pathname;
  return (
    <div className="d-flex flex-column flex-md-row">
      <div className="col-md-2  dashboard  dashboard-scroll ">
        <Dashboard />
      </div>
      <div className="col-md-10 outlet  content-scroll px-2">
        {currentUrl === "/profiledashboard" ||
        currentUrl === "/profiledashboard/" ? (
          <EmpDetails />
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
};

export default DashboardLayout;
