const staticValues = {
  statusClosed: "Closed",
  statusActive: "Active",
  statusApproved: "approved",
  statusSaved: "saved",
  statusSubmit: "submitted",
  statusPending: "pending",
  statusRejected: "rejected",
  statusDelete: "delete",
  timeout: 600000,
  //========Emp Account Locked Based on successive login attempt with wrong credentials======================
  statusLocked: "Locked",
  //========Emp Account Locked Based on successive login attempt with wrong credentials======================
};
export default staticValues;
