import { configureStore } from "@reduxjs/toolkit";
// ==================import Slices================
import loginReducer from "./loginSlice";
import jobDataReducer from "../Content/Careers/jobDataSlice";
import trendsDataReducer from "../login/userprofile/leaveDashboard/timesheetReport/reportsSlice";
// ==================import Slices================

//====================PERSIST===============================
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
//====================PERSIST===============================

//===============persistConfig====================
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  login: loginReducer,
  jobData: jobDataReducer,
  report: trendsDataReducer,
});

const persistreducer = persistReducer(persistConfig, reducer);
//===============persistConfig====================

const store = configureStore({
  //   reducer: { login: loginReducer },
  reducer: persistreducer,
});

export default store;
